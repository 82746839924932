// Libraries
import { FilterMatchMode, FilterService } from 'primereact/api';
import { useMemo, useState } from 'react';

// Endpoints
import { Case, Profile } from '../../../../shared/store/endpoints';

// Components
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

//Table templates
import { MultiSelect } from 'primereact/multiselect';
import PageTransitionWrapper from '../../../../shared/components/PageTransitionWrapper';
import {
  tableDateTemplate,
  tableRangeFilterTemplate,
} from '../../../../shared/components/table-templates/table-date-template';
import { useGetAllProfilesWithPhotoPathQuery } from '../../../../shared/store/endpoints/profile';
import { truncatedTextWithTooltipTemplate } from '../../../../shared/tableTemplates/truncatedTextWithTooltipTemplate';
import { caseProfilesBodyTemplate } from './tableTemplates/caseProfiles';
import { nameBodyTemplate } from './tableTemplates/name';
import {
  statusBodyTemplate,
  statusFilterTemplate,
} from './tableTemplates/status';
import { useNavigate } from 'react-router';
import { tableFilterSearchElement } from '../../../../shared/components/table-templates/searchFilterElement';

interface CaseTableProps {
  cases: Case[] | undefined;
}

export interface TableData extends Omit<Case, 'updatedOn'> {
  updatedOn: Date;
}

export default function CaseTable({ cases }: CaseTableProps): JSX.Element {
  const navigate = useNavigate();
  // Hooks
  FilterService.register(
    'matchArrays',
    (value: Profile[], filter: string[] | null): boolean => {
      if (value && filter) {
        return value.some((valueProfile: Profile) =>
          filter.find((f: string) => f === valueProfile.id),
        );
      }
      return true;
    },
  );

  //Setting up all the filters, using any because Prime types are not up to date
  const [filters] = useState<any>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updatedOn: { value: [null, null], matchMode: FilterMatchMode.BETWEEN },
    caseStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    caseProfiles: { value: null, matchMode: 'matchArrays' },
  });
  const matchModes = [{ value: 'matchArrays' }];

  //Add the photo path to the profiles so we can use it in the table
  const { data: orgProfiles } = useGetAllProfilesWithPhotoPathQuery(null);
  const tableData = useMemo(() => {
    return cases
      ? cases.map((caseData) => {
          return {
            ...caseData,
            caseProfiles: caseData.caseProfiles.map((caseProfile) => {
              return orgProfiles?.find(
                (profile) => profile.id === caseProfile.id,
              );
            }),
            updatedOn: new Date(caseData.updatedOn),
          };
        })
      : [];
  }, [cases, orgProfiles]);

  const onCaseProfilesChange = (e: any, options: any) => {
    options.filterCallback(e.value);
  };

  const caseProfilesFilterTemplate = (options: any) => {
    const profileOptions =
      orgProfiles?.map((profile) => ({
        label: `${profile.firstName} ${profile.lastName}`,
        value: profile.id,
      })) || [];
    return (
      <MultiSelect
        value={options.value}
        options={profileOptions}
        onChange={(e) => onCaseProfilesChange(e, options)}
        placeholder="Select Profile"
        className="p-column-filter"
        maxSelectedLabels={1}
        display="chip"
      />
    );
  };

  return (
    <PageTransitionWrapper>
      <div className="app-datatable">
        <DataTable
          value={tableData}
          responsiveLayout="scroll"
          paginator={tableData.length > 10}
          rows={5}
          sortField="lastModified"
          sortOrder={-1}
          filters={filters}
          filterDisplay="menu"
          emptyMessage="No cases found."
          globalFilterFields={['name', 'description', 'updatedOn']}
          className="p-datatable-data"
          selectionMode="single"
          onSelectionChange={(e) => navigate(`/cases/${e.value.id}`)}
        >
          <Column
            showFilterMatchModes={false}
            filterMenuStyle={{ width: '16rem' }}
            style={{ minWidth: '14rem' }}
            key="updatedOn"
            field="updatedOn"
            header="Last Modified"
            dataType="date"
            sortable
            filter
            filterElement={tableRangeFilterTemplate}
            body={(caseData) => tableDateTemplate(caseData.updatedOn)}
          />
          <Column
            showFilterMatchModes={false}
            style={{ minWidth: '12rem' }}
            key="name"
            field="name"
            header="Case Name"
            filter
            body={nameBodyTemplate}
            filterElement={(options) =>
              tableFilterSearchElement(options, 'Search by name')
            }
          />
          <Column
            showFilterMatchModes={false}
            style={{ minWidth: '12rem' }}
            key="description"
            field="description"
            header="Description"
            filter
            filterElement={(options) =>
              tableFilterSearchElement(options, 'Search by description')
            }
            body={(options) =>
              truncatedTextWithTooltipTemplate(options.description)
            }
          />
          <Column
            showFilterMatchModes={false}
            key="caseStatus"
            field="caseStatus"
            header="Status"
            filter
            filterField="caseStatus"
            body={statusBodyTemplate}
            filterElement={statusFilterTemplate}
          />

          <Column
            showFilterMatchModes={false}
            key="caseProfiles"
            field="caseProfiles"
            header="Assigned Users"
            filter
            body={caseProfilesBodyTemplate}
            filterElement={caseProfilesFilterTemplate}
            filterMatchModeOptions={matchModes}
          />
        </DataTable>
      </div>
    </PageTransitionWrapper>
  );
}
