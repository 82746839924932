import HeaderNavLink from './HeaderNavLink';
import HeaderNavMenu from './HeaderNavMenu';

export default function HeaderNav(): JSX.Element {
  return (
    <nav className="fixed z-50 h-16 w-full bg-primary-dark text-primary-lighter/80">
      <div className="container mx-auto flex h-full items-center">
        <a
          className="mr-6 flex flex-none items-center gap-x-2 px-4 text-xl font-semibold text-primary-light"
          href="/"
        >
          <img
            src="/assets/quill-logo-white.png"
            className="h-10 w-10"
            alt="quill logo"
          />
        </a>
        <div className="flex h-full w-full justify-start gap-x-4">
          <HeaderNavLink href="/cases" icon="HomeIcon">
            Cases
          </HeaderNavLink>
          <HeaderNavLink href="/activity" icon="ClockIcon">
            Activity
          </HeaderNavLink>
        </div>
        <HeaderNavMenu />
      </div>
    </nav>
  );
}
