import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['Stripe', 'Seats'],
  endpoints: {
    getSeats: {
      providesTags: ['Seats'],
    },
    updateSeats: {
      invalidatesTags: ['Seats'],
    },
  },
});

export const {
  useLazyCreateStripeCheckoutSessionQuery,
  useLazyCreateUpdateBillingSessionQuery,
  useCheckOrgSubscriptionQuery,
  useUpdateSeatsMutation,
  useGetSeatsQuery,
  useGetCheckoutSessionInfoQuery,
} = endpoints;
