import { formatRelative } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { Activity } from '../../store/endpoints';
import { getProfileName } from '../../utility-fns/profile-fns';
import DynamicHeroIcon from '../DynamicHeroIcon';
import { IconName } from '../DynamicHeroIcon/DynamicHeroIcon';
import './ActivityListItem.scss';
interface ActivityListItemProps {
  activity: Activity;
}

export default function ActivityListItem({
  activity,
}: ActivityListItemProps): JSX.Element {
  // memoized
  const targetResource = useCallback(
    (
      activity: Activity,
    ): {
      icon: IconName;
      resourceName: string;
      caseName: string;
    } => {
      let icon = 'ExclamationCircleIcon';
      let resourceName = '';
      let caseName = '';
      switch (activity.targetResource) {
        case 'user':
          icon = 'UserCircleIcon';
          if (activity.profile) {
            resourceName = `${getProfileName(activity.profile)}'s profile`;
          } else {
            resourceName = 'Profile';
          }
          break;
        case 'case':
          icon = 'BuildingLibraryIcon';
          resourceName = activity.case?.name || 'Case';
          break;
        case 'adminIssue':
          icon = 'FlagIcon';
          resourceName = activity.adminIssue?.name || 'Admin Issue';
          break;
        case 'caseIssue':
          icon = 'FlagIcon';
          resourceName = activity.caseIssue?.name || 'Issue';
          caseName = activity.case?.name || '';
          break;
        case 'caseDocument':
          icon = 'DocumentTextIcon';
          resourceName = activity.caseDocument?.name || 'Document';
          caseName = activity.case?.name || '';
          break;
        case 'casePeople':
          icon = 'UsersIcon';
          if (activity.casePeople) {
            resourceName = getProfileName(activity.casePeople);
            caseName = activity.case?.name || '';
          } else {
            resourceName = 'Person';
          }
          break;
        case 'caseType':
          icon = 'DocumentDuplicateIcon';
          resourceName = activity.caseType?.name || 'Case Type';
          break;
      }
      return {
        icon: icon as IconName,
        resourceName,
        caseName,
      };
    },
    [],
  );

  function actionText(action: 'create' | 'update' | 'delete') {
    switch (action) {
      case 'create':
        return 'was created by';
      case 'update':
        return 'was updated by';
      case 'delete':
        return 'was deleted by';
    }
  }

  const { icon, resourceName, action, caseName } = useMemo(() => {
    const { icon, resourceName, caseName } = targetResource(activity);
    const action = actionText(activity.action);
    return { icon, resourceName, caseName, action };
  }, [activity, targetResource]);

  return (
    <div className="ActivityListItem_row">
      <span className="flex w-[56px] justify-center">
        <DynamicHeroIcon
          className="h-7 w-7 text-gray-400"
          icon={icon}
          outline={true}
        />
      </span>
      {/* TODO: Add activity type (Issue, User, Case People, etc.) */}
      <span className="font-bold">{resourceName}</span>
      <span>{action}</span>
      <span className="font-bold">{getProfileName(activity.createdBy)}</span>
      {caseName && (
        <span>
          for case <span className="font-bold">{caseName}</span>
        </span>
      )}
      <span className="ml-4 flex gap-2 rounded-lg bg-gray-100 px-2 py-1 text-sm">
        <span className="capitalize">
          {formatRelative(new Date(activity.createdOn), new Date())}
        </span>
      </span>
    </div>
  );
}
