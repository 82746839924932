import { Chip } from 'primereact/chip';
import { useMemo } from 'react';
import { useLogDocumentEditActivityQuery } from '../../../../../../shared/store/endpoints';
import { useGetAllProfilesWithPhotoPathQuery } from '../../../../../../shared/store/endpoints/profile';

function RenderActiveUsers({ documentId }: { documentId: string }) {
  const { data: editLogs } = useLogDocumentEditActivityQuery(
    { caseDocumentId: documentId },
    { pollingInterval: 3000 },
  );

  const { data: orgProfiles } = useGetAllProfilesWithPhotoPathQuery(null);

  const logs = useMemo(() => {
    if (editLogs) {
      return editLogs.map((log) => ({
        ...log,
        profile: orgProfiles?.find((p) => p.id === log.profileId),
      }));
    }
    return [];
  }, [editLogs, orgProfiles]);

  if (!editLogs || (editLogs as any).length === 0) {
    return <></>;
  }

  return (
    <div className="mt-2 flex gap-4 text-base ">
      <span className="font-medium">Active users:</span>
      {logs.map((log) => (
        <Chip
          key={log.profileId}
          label={log?.profile?.firstName}
          className="whitespace-nowrap"
          image={log?.profile?.profilePhotoPath}
        />
      ))}
    </div>
  );
}

export default RenderActiveUsers;
