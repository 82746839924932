interface BrandedHeaderProps {
  text: string;
}
export default function BrandedHeader({ text }: BrandedHeaderProps) {
  return (
    <h1 className=" my-4 text-5xl font-bold ">
      <span className="bg-gradient-to-tr from-primary-base to-primary-dark bg-clip-text text-transparent">
        {text}
      </span>
    </h1>
  );
}
