import { Button } from 'primereact/button';
import { useState } from 'react';
import CreatePeopleModal from '../CreatePeopleModal';

export default function CreatePeopleButton({
  className,
  label = 'Add a person',
}: {
  className?: string;
  label?: string;
}): JSX.Element {
  const [showCreatePeopleModal, setShowCreatePeopleModal] = useState(false);

  return (
    <>
      <Button
        icon="pi pi-plus"
        label={label}
        className={className}
        onClick={() => setShowCreatePeopleModal(true)}
      />
      <CreatePeopleModal
        showModal={showCreatePeopleModal}
        setShowModal={setShowCreatePeopleModal}
      />
    </>
  );
}
