import './CaseStatusChip.css';

// Types
import { CaseStatus } from '../../types/case';
interface CaseStatusChipProps {
  status: 'open' | 'closed' | CaseStatus;
}

export default function CaseStatusChip({
  status,
}: CaseStatusChipProps): JSX.Element {
  const isOpen = status === 'open';
  return (
    <div className={`${status} w-max rounded-lg py-[2px] px-4 font-medium`}>
      {isOpen ? 'Open' : 'Closed'}{' '}
    </div>
  );
}
