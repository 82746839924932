import { useFormContext } from 'react-hook-form';
import FormInput from '../../../shared/components/form/FormInput';

export interface ProfileFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
}

export function setProfileFormValues(
  valueSet?: Partial<ProfileFormValues>,
): ProfileFormValues {
  let values: ProfileFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
  };

  if (valueSet) {
    values = {
      ...values,
      ...valueSet,
    };
  }

  return values;
}

function ProfileForm() {
  const { control, formState } = useFormContext();
  return (
    <>
      <FormInput
        label="First Name"
        name="firstName"
        control={control}
        rules={{ required: 'First name is required' }}
        errors={formState.errors}
      />
      <FormInput
        label="Last Name"
        name="lastName"
        control={control}
        rules={{ required: 'Last name is required' }}
        errors={formState.errors}
      />
      <FormInput
        label="Email"
        name="email"
        control={control}
        rules={{ required: 'Email is required' }}
        errors={formState.errors}
        disabled
      />
      <FormInput
        label="Phone"
        name="phoneNumber"
        control={control}
        errors={formState.errors}
      />
    </>
  );
}

export default ProfileForm;
