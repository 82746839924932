// Libraries

import axios from 'axios';
import { Menu } from 'primereact/menu';
import { useParams } from 'react-router-dom';
import { useExportMenu } from '../../hooks/useExportMenu';
import useStorage from '../../hooks/useStorage';
import Button from '../Button';

// Endpoints

// Components

interface ExportButtonProps {
  selected: string[] | number[];
  resource: string;
  filters?: { [key: string]: string };
  showAll: boolean;
}

export default function ExportButton({
  selected,
  resource,
  filters,
  showAll,
}: ExportButtonProps): JSX.Element {
  // hooks
  const { saveBlob } = useStorage();
  const { menuItems, menuRef } = useExportMenu({ showAll });
  const { caseId } = useParams() as { caseId: string };

  // methods
  async function downloadExport(
    format: 'pdf' | 'docx',
    type: 'selected' | 'all',
  ) {
    let res;
    if (type === 'selected') {
      res = await axios.get(
        `${process.env.REACT_APP_API_URL}/${resource}/export`,
        {
          params: {
            selected,
            format,
            caseId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          responseType: 'blob',
        },
      );
    } else if (type === 'all' && filters) {
      const { search, searchBy, orderBy, orderDir } = filters;
      res = await axios.get(
        `${process.env.REACT_APP_API_URL}/${resource}/export/all`,
        {
          params: {
            format,
            caseId,
            search,
            searchBy,
            orderBy,
            orderDir,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          responseType: 'blob',
        },
      );
    }
    if (!res) return;
    // get filename from response headers
    const filename = res.headers['content-disposition'].split('filename=')[1];
    saveBlob(res.data, filename);
  }
  return (
    <>
      <Button
        className="p-button-outlined"
        label="Export"
        icon="pi pi-chevron-down"
        iconPos="right"
        onClick={(e) => menuRef.current?.toggle(e)}
        disabled={selected.length === 0}
      />
      <Menu ref={menuRef} model={menuItems(downloadExport)} popup />
    </>
  );
}
