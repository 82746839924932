// Libraries
import { FilterMatchMode } from 'primereact/api';
import { useMemo, useState } from 'react';

// Endpoints
import { useDeleteCaseIssuesMutation } from '../../../../../shared/store/endpoints/case-issues';

// Data
import { CaseIssue } from '../../../../../shared/store/endpoints';
import { FilterState } from '../../../../../shared/types/table-types';

// Components
import TableDeleteButton from '../../../../../shared/components/TableDeleteButton';
import UpdateCaseIssueModal from '../UpdateCaseIssueModal';
import {
  tableDateTemplate,
  tableRangeFilterTemplate,
} from '../../../../../shared/components/table-templates/table-date-template';
import CreateCaseIssueButton from '../CreateCaseIssueButton';
import { tableFilterSearchElement } from '../../../../../shared/components/table-templates/searchFilterElement';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import ExportButton from '../../../../../shared/components/ExportButton';
import useToast from '../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface CaseIssueData extends Omit<CaseIssue, 'createdOn'> {
  // Must be type Date to work with Prime's date filtering functions
  createdOn: Date;
}
interface CaseIssueTableProps {
  caseIssues: CaseIssue[] | undefined;
}

export default function CaseIssueTable({
  caseIssues,
}: CaseIssueTableProps): JSX.Element {
  // state
  const [filters] = useState<FilterState>({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdOn: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const [issueToUpdate, setIssueToUpdate] = useState<CaseIssue | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<CaseIssue[]>([]);

  // hooks
  const [deleteCaseIssues, { isLoading }] = useDeleteCaseIssuesMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  const tableData = useMemo(() => {
    if (!caseIssues) {
      return [];
    }
    const data: CaseIssueData[] = caseIssues.map((caseIssue) => {
      return {
        ...caseIssue,
        createdOn: new Date(caseIssue.createdOn),
      };
    });
    return data;
  }, [caseIssues]);

  // methods
  function handleUpdateButtonClick(target: CaseIssue) {
    setIssueToUpdate(target);
    setShowUpdateModal(true);
  }

  async function handleDeleteCaseIssues() {
    const caseIssueIds = selectedIssues.map((issue) => issue.id.toString());
    try {
      await deleteCaseIssues({ caseIssueIds }).unwrap();
      showSuccessToast('Case issues successfully deleted');
      setSelectedIssues([]);
    } catch (err) {
      showErrorToast('Error deleting case issues');
    }
  }

  return (
    <div className="app-datatable">
      <DataTable
        value={tableData}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sortField="createdOn"
        selection={selectedIssues}
        onSelectionChange={(e) => setSelectedIssues(e.value)}
        filters={filters as DataTableFilterMeta}
        filterDisplay="menu"
        dataKey="id"
        emptyMessage="There are no issues related to this case yet"
        header={
          <div className="flex justify-end gap-4">
            <ExportButton
              selected={selectedIssues.map((issue) => issue.id)}
              resource={'case-issue'}
              showAll={false}
            />
            <TableDeleteButton
              targetPluralText="issues"
              deleteFunction={handleDeleteCaseIssues}
              selectedItems={selectedIssues}
              loading={isLoading}
            />
            <CreateCaseIssueButton />
          </div>
        }
        className="p-datatable-data"
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3em' }}
        ></Column>
        <Column
          key="createdOn"
          field="createdOn"
          header="Date Added"
          dataType="date"
          body={(caseIssue: CaseIssue) =>
            tableDateTemplate(caseIssue.createdOn)
          }
          filterElement={tableRangeFilterTemplate}
          filter
          showFilterMatchModes={false}
        />
        <Column
          key="name"
          field="name"
          header="Issue Name"
          sortable
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search name')
          }
          showFilterMatchModes={false}
        />
        <Column
          key="description"
          field="description"
          header="Description"
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search description')
          }
          showFilterMatchModes={false}
        />
        <Column
          header="Action"
          body={(rowData) => (
            <Button
              label="Edit"
              className="p-button-outlined p-button-sm p-button-info"
              onClick={() => handleUpdateButtonClick(rowData)}
            />
          )}
        />
      </DataTable>
      {showUpdateModal && (
        <UpdateCaseIssueModal
          showModal={showUpdateModal}
          setShowModal={setShowUpdateModal}
          issueToEdit={issueToUpdate}
          onHide={() => setShowUpdateModal(false)}
        />
      )}
      <Toast ref={toastRef} />
    </div>
  );
}
