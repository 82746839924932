export enum ActivityAction {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export enum TargetResource {
  user = 'user',
  case = 'case',
  casePeople = 'casePeople',
  caseType = 'caseType',
  caseIssue = 'caseIssue',
  adminIssue = 'adminIssue',
  caseDocument = 'caseDocument',
}
