//Libraries
import { Dialog } from 'primereact/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { Case, CasePayload } from '../../../../shared/store/endpoints';
import { useUpdateCaseMutation } from '../../../../shared/store/endpoints/cases';

//Components
import Button from '../../../../shared/components/Button';
import CaseForm, { setCaseFormValues, CaseFormValues } from '../CaseForm';
import useToast from '../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface UpdateCaseModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  caseToEdit: Case | null | undefined;
}

export default function UpdateCaseModal({
  showModal,
  setShowModal,
  caseToEdit,
}: UpdateCaseModalProps) {
  const { toastRef, showErrorToast, showSuccessToast } = useToast();
  const [updateCase, { isLoading }] = useUpdateCaseMutation();

  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseFormValues({
      name: caseToEdit?.name,
      description: caseToEdit?.description,
      type: caseToEdit?.caseTypeId,
      caseUserIds: caseToEdit?.caseProfiles.map((profile) => profile.id),
      caseStatus: caseToEdit?.caseStatus,
    }),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;

  function closeModal() {
    setShowModal(false);
    reset();
  }

  async function submit(data: CaseFormValues) {
    if (!caseToEdit) {
      return;
    }

    const {
      name,
      description,
      type: caseTypeId,
      caseUserIds,
      caseStatus,
    } = data;

    const casePayload: CasePayload = {
      name,
      description: description || '',
      caseTypeId: caseTypeId || 0,
      caseUserIds,
      caseStatus: caseStatus || 'open',
    };

    try {
      await updateCase({ casePayload, caseId: caseToEdit.id }).unwrap();
      showSuccessToast('Case successfully updated');
      closeModal();
    } catch (err) {
      showErrorToast('Error updating case, please try again');
    }
  }

  const footer = (
    <div>
      <Button
        className="p-button-outlined"
        label="Cancel"
        onClick={closeModal}
        disabled={isLoading}
      />
      <Button
        label="Update case"
        onClick={handleSubmit(submit)}
        disabled={!isValid || isLoading}
      />
    </div>
  );

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="Edit Case"
        visible={showModal}
        onHide={closeModal}
        footer={footer}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submit)}>
            <CaseForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
