import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Button as PrimeButton } from 'primereact/button';
import { ButtonProps } from 'primereact/button';

/**
 *  Important: pass in the button text as a label prop
 *  instead of wrapping the text in the button tag
 *
 * Do it like this 👍
 * <Button
 *  label="Start loading"
 *   onClick={() => setLoading(!loading)}
 *  loading={loading}
 *  />;
 *
 * Not like this 🛑
 * <Button onClick={() => setLoading(!loading)} loading={loading}>
 * Start loading
 * </Button>;
 */

interface CustomButtonProps extends ButtonProps {
  fullWidth?: boolean;
}

export default function Button({
  fullWidth,
  disabled,
  loading,
  className,
  ...props
}: CustomButtonProps) {
  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      className={classNames(
        'inline-flex',
        { 'pointer-events-none': disabled || loading },
        { 'w-full': fullWidth },
      )}
    >
      <PrimeButton
        {...props}
        className={classNames(
          'justify-center',
          { 'w-full': fullWidth },
          className,
        )}
        disabled={disabled}
        loading={loading}
      />
    </motion.div>
  );
}
