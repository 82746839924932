//libraries
import { useState } from 'react';

//components
import BrandedHeader from '../../shared/components/BrandedHeader';
import Button from '../../shared/components/Button';
import PageTitle from '../../shared/components/PageTitle';
import Spacer from '../../shared/components/Spacer';
import CaseTable from './components/CaseTable';
import CreateCaseModal from './components/CreateCaseModal';

//hooks
import useProfile from '../../shared/hooks/useProfile';
import { useGetCasesQuery } from '../../shared/store/endpoints/cases';

//styles
import SubscribeModal from '../../shared/components/SubscribeModal';
import { useCheckOrgSubscriptionQuery } from '../../shared/store/endpoints/stripe';
import './CaseDashboardPage.css';

interface CaseDashboardPageProps {
  freeTestCase?: boolean;
}

export default function CaseDashboardPage({
  freeTestCase,
}: CaseDashboardPageProps): JSX.Element {
  //hooks
  const { profile, isAdmin } = useProfile();

  // Query
  const { data: cases } = useGetCasesQuery();

  const [showCaseModal, setShowCaseModal] = useState(false);

  //Subscribe modal
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const { data: subscribed } = useCheckOrgSubscriptionQuery();

  function handleCreateNewCase() {
    if (!subscribed) {
      setShowSubscribeModal(true);
      return;
    }
    setShowCaseModal(true);
  }

  return (
    <>
      <div className="mx-auto max-w-[1400px]">
        <>
          <div className="relative overflow-hidden rounded-lg bg-gray-100 px-12 py-12">
            <div className="flex justify-between">
              <BrandedHeader text={`Welcome ${profile?.firstName}`} />
            </div>
            {profile?.organization?.showSampleCase ? (
              <p className="mb-4 font-medium text-gray-700">
                Start exploring Document Elf for free using this sample case
              </p>
            ) : (
              <Spacer height="h-6" />
            )}
            <img
              src="/assets/doc-elf-graphic.png"
              alt="doc elf wizard"
              className="absolute -bottom-20 right-20 hidden w-[350px] lg:block"
            />
          </div>
          <Spacer height="h-8" />
        </>

        <div className="flex items-start justify-between pr-10">
          <PageTitle text={` ${profile?.organization?.name} Cases`} />
          {isAdmin && (
            <>
              <Button
                icon="pi pi-plus"
                label="New Case"
                onClick={() => handleCreateNewCase()}
              />
              <CreateCaseModal
                showModal={showCaseModal}
                setShowModal={setShowCaseModal}
              />
            </>
          )}
        </div>

        <Spacer height="h-4" />
        <CaseTable cases={cases} />
        <SubscribeModal
          showModal={showSubscribeModal}
          setShowModal={setShowSubscribeModal}
        />
      </div>
    </>
  );
}
