import { motion } from 'framer-motion';
import React from 'react';
import DynamicHeroIcon from '../../../../../shared/components/DynamicHeroIcon';

interface IncrementButtonProps {
  onClick: () => void;
  icon: 'PlusIcon' | 'MinusIcon';
}
function IncrementButton({ onClick, icon }: IncrementButtonProps) {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      className="cursor-pointer"
      onClick={onClick}
    >
      <DynamicHeroIcon
        icon={icon}
        className="h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-600 transition-colors hover:bg-gray-200"
      />
    </motion.div>
  );
}

export default IncrementButton;
