import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { RouteProps, useLocation, useNavigate } from 'react-router-dom';
import FullPageLoading from '../../components/FullPageLoading';
import useProfile from '../../hooks/useProfile';
import { useRecordLastLoginMutation } from '../../store/endpoints';

const AuthGuard: React.FC<RouteProps> = ({ children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [skip, setSkip] = useState(true);
  const {
    profile,
    isLoading: profileLoading,
    isUninitialized: profileUninitialized,
    isFetching: profileFetching,
  } = useProfile(skip);
  const [recordLastLogin] = useRecordLastLoginMutation();
  const navigate = useNavigate();
  const location = useLocation();

  // handle Auth0 loading
  useEffect(() => {
    // methods
    const addInterceptor = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        axios.interceptors.request.use((request) => {
          if (request.url) {
            const isApiUrl = request.url.startsWith(
              process.env.REACT_APP_API_URL as string,
            );
            if (user && isApiUrl && accessToken.length > 0) {
              if (
                // @ts-ignore - this is a valid header
                request.headers.common.Authorization !== `Bearer ${accessToken}`
              ) {
                //@ts-ignore
                request.headers.common.Authorization = `Bearer ${accessToken}`;
              }
            }
          }
          return request;
        });
        setSkip(false);
      } catch (error) {
        console.error('error', error);
      }
    };

    if (!isLoading) {
      if (isAuthenticated && (user?.email || user?.name)) {
        // adds interceptor and also gets profile
        addInterceptor();
      } else {
        navigate('/get-started');
      }
    }
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently, navigate]);

  // handle last login
  useEffect(() => {
    if (!profileLoading && profile?.id) {
      recordLastLogin();
    }
  }, [profileLoading, profile?.id, recordLastLogin]);

  // handle profile loading
  useEffect(() => {
    if (!profileLoading && !profileUninitialized && !profileFetching) {
      if (profile) {
        // user can't register a second time.  If profile exists, redirect to home.
        if (location.pathname.includes('register')) {
          navigate('/');
        }
      } else {
        // redirect to register if profile doesn't exist
        if (!location.pathname.includes('register')) {
          // if there is no profile, force user here to register.
          if (user?.email) {
            navigate('/register');
          }
        }
      }
    }
  }, [
    profileLoading,
    location.pathname,
    profileUninitialized,
    profileFetching,
    user?.email,
    profile,
    navigate,
  ]);

  return isLoading || profileLoading || profileUninitialized ? (
    <FullPageLoading />
  ) : (
    <>{children}</>
  );
};

export default AuthGuard;
