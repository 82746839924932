import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import FormError from '../SharedFormComponents/FormError';
import FormLabel from '../SharedFormComponents/FormLabel';

const FormInput = ({
  control,
  errors,
  name,
  label,
  rules,
  type = 'text',
  inputRef,
  className,
  disabled,
  autoFocus,
  onBlur = () => null,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  className?: string;
  disabled?: boolean;
  onBlur?: () => any;
  autoFocus?: boolean;
}) => {
  function triggerOnBlur() {
    if (onBlur) {
      onBlur();
    }
  }

  return (
    <div onBlur={triggerOnBlur}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormLabel
                fieldState={fieldState}
                text={label}
                name={name}
                rules={rules}
              />
              <InputText
                id={name + '-input'}
                className={classNames('mt-1 w-full', className, {
                  'p-invalid': fieldState.error,
                })}
                {...field}
                ref={inputRef}
                type={type}
                disabled={disabled}
                autoFocus={autoFocus}
              />
            </>
          );
        }}
      />
      <FormError errors={errors} name={name} />
    </div>
  );
};
export default FormInput;
