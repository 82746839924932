import { SelectItemOptionsType } from 'primereact/selectitem';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../../../shared/components/form/FormInput';
import FormInputTextarea from '../../../../../shared/components/form/FormInputTextarea';
import FormSelectButton from '../../../../../shared/components/form/FormSelectButton';

export interface PeopleFormValues {
  firstName: string;
  lastName: string;
  peopleRole: 'witness' | 'party';
  statementObtained: boolean;
  about: string;
}

export function setPeopleFormValues(
  valueSet?: Partial<PeopleFormValues>,
): PeopleFormValues {
  let values: PeopleFormValues = {
    firstName: '',
    lastName: '',
    peopleRole: 'witness',
    statementObtained: false,
    about: '',
  };

  if (valueSet) {
    values = {
      ...values,
      ...valueSet,
    };
  }

  return values;
}

function PeopleForm() {
  const { control, formState } = useFormContext();

  const roleOptions: SelectItemOptionsType = [
    {
      label: 'Party',
      value: 'party',
    },
    {
      label: 'Witness',
      value: 'witness',
    },
  ];

  const statementObtainedOptions: SelectItemOptionsType = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  return (
    <>
      <FormInput
        label="First Name"
        name="firstName"
        control={control}
        rules={{ required: 'First name is required' }}
        errors={formState.errors}
        autoFocus
      />
      <FormInput
        label="Last Name"
        name="lastName"
        control={control}
        rules={{ required: 'Last name is required' }}
        errors={formState.errors}
      />
      <FormSelectButton
        label="Role"
        errors={formState.errors}
        name="peopleRole"
        options={roleOptions}
        control={control}
      />
      <FormSelectButton
        label="Statement Obtained?"
        errors={formState.errors}
        name="statementObtained"
        options={statementObtainedOptions}
        control={control}
      />
      <FormInputTextarea
        label="About"
        name="about"
        control={control}
        errors={formState.errors}
      />
    </>
  );
}

export default PeopleForm;
