import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['Cases', 'Case', 'Profile', 'AllProfiles'],
  endpoints: {
    updateOrganization: {
      invalidatesTags: ['AllProfiles', 'Profile', 'Cases', 'Case'],
    },
  },
});

export const { useUpdateOrganizationMutation } = enhancedApi;
