import { motion } from 'framer-motion';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/Button';
import DynamicHeroIcon from '../../../../shared/components/DynamicHeroIcon';
import { Profile } from '../../../../shared/store/endpoints';
import { useUpdateSeatsMutation } from '../../../../shared/store/endpoints/stripe';
import IncrementButton from './IncrementButton/IncrementButton';

interface EditSeatsModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  numberOfSeats: number | undefined;
  activeProfiles: Profile[] | undefined;
}
function EditSeatsModal({
  showModal,
  setShowModal,
  numberOfSeats,
  activeProfiles,
}: EditSeatsModalProps) {
  // Hooks
  const [updateNumberOfSeats, { isLoading }] = useUpdateSeatsMutation();
  //Keep form in sync with the number of seats
  useEffect(() => {
    setFormNumberOfSeats(numberOfSeats || 1);
  }, [numberOfSeats]);

  //Form state, opting to keep it super simple and not use React Hook Form for this
  const [formNumberOfSeats, setFormNumberOfSeats] = useState<number>(
    numberOfSeats || 1,
  );
  const [error, setError] = useState<string | null>(null);

  //Form handlers
  function increment() {
    setFormNumberOfSeats(formNumberOfSeats + 1);
    setError(null);
  }

  function decrement() {
    if (activeProfiles && activeProfiles?.length < formNumberOfSeats) {
      setError(null);
      setFormNumberOfSeats(formNumberOfSeats - 1);
    } else {
      setError('You cannot have fewer seats than active users');
    }
  }

  //Review step
  const [showReview, setShowReview] = useState(false);

  function handleShowReview() {
    setShowReview(true);
  }

  function handleBack() {
    setShowReview(false);
  }

  async function handleUpdateStripeSeats() {
    //Send request to update stripe seats to Stripe
    await updateNumberOfSeats({ numberOfSeats: formNumberOfSeats });
    //Reset and close modal
    setShowReview(false);
    setShowModal(false);
  }

  //Modal functions
  function handleClose() {
    setShowModal(false);
    setShowReview(false);
  }

  return (
    <Dialog
      header="Change number of seats"
      visible={showModal}
      onHide={() => handleClose()}
      style={{ width: '400px' }}
    >
      {!showReview ? (
        <motion.section>
          <div className="mt-6 flex w-full items-center justify-center gap-x-6">
            <IncrementButton icon="MinusIcon" onClick={() => decrement()} />
            <InputText
              style={{ width: '70px', textAlign: 'center' }}
              value={formNumberOfSeats}
              onChange={(e) => setFormNumberOfSeats(Number(e.target.value))}
              type="tel"
              className="p-inputtext-lg"
            />
            <IncrementButton icon="PlusIcon" onClick={() => increment()} />
          </div>
          <div className="relative h-16 w-full">
            {error && (
              <p className="absolute inset-0 my-4 text-center text-red-600">
                {error}
              </p>
            )}
          </div>
          <Button label="Update" fullWidth onClick={() => handleShowReview()} />
        </motion.section>
      ) : (
        <motion.section>
          <div className="flex w-full items-center justify-center gap-x-4">
            <div className="flex flex-col items-center text-gray-400">
              <span className="text-6xl font-bold">{numberOfSeats}</span>
              old seats
            </div>
            <DynamicHeroIcon
              icon="ArrowRightIcon"
              className="h-6 w-6 text-gray-600"
            />
            <div className="flex flex-col items-center text-primary-base">
              <span className="text-6xl font-bold">{formNumberOfSeats}</span>
              new seats
            </div>
          </div>
          <div className="my-6 select-none">
            <small>
              You will see these changes reflected in your next invoice.
            </small>
          </div>
          <div className="flex w-full gap-x-4">
            <Button
              label="Back"
              className="p-button-outlined p-button-secondary"
              onClick={() => handleBack()}
            />
            <Button
              label="Confirm"
              fullWidth
              onClick={() => handleUpdateStripeSeats()}
              loading={isLoading}
            />
          </div>
        </motion.section>
      )}
    </Dialog>
  );
}

export default EditSeatsModal;
