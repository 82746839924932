import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

//Components
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import Spacer from '../../../shared/components/Spacer';
import TimelineNavigateArrow from './TimelineNavigateArrow';
import TimelineNoteLabel from './TimelineNoteLabel';

//Helpers
import { sliderAnimation } from '../../../shared/helpers/animations';
import './TimelineTab.scss';

//Endpoints
import {
  CaseDocumentNote,
  CaseIssue,
  CasePeople,
} from '../../../shared/store/endpoints';
import { useGetAllCaseNotesQuery } from '../../../shared/store/endpoints/case-documents-notes';
import FlexCol from '../../../shared/components/FlexCol';
import { Skeleton } from 'primereact/skeleton';
import { Link } from 'react-router-dom';
import TimelineLabel from './TimelineLabel';

function TimelineTab() {
  //Hooks
  const { caseId } = useParams();
  //TODO: Decide if these are the params we want to use. Probably not 100.
  const { data } = useGetAllCaseNotesQuery(
    {
      caseId: caseId as string,
      orderBy: 'date',
      orderDir: 'asc',
    },
    { skip: !caseId || false },
  );

  const { notes } = data || { notes: [], count: 0 };

  //Active index
  const [[activeIndex, direction], setActiveIndex] = useState([0, 0]);

  const activeNote = useMemo(() => {
    return notes[activeIndex];
  }, [notes, activeIndex]);

  function isActiveIndex(id: string) {
    return (
      activeIndex ===
      notes.findIndex((note: CaseDocumentNote) => note.id === id)
    );
  }

  useEffect(() => {
    const activeNote = notes[activeIndex];
    if (activeNote) {
      document
        .getElementById(activeNote.id)
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeIndex, notes]);

  //Templates
  const customizedMarker = ({ id, date }: CaseDocumentNote) => {
    return (
      <div key={id}>
        <Tooltip
          target=".tooltipTarget"
          mouseTrack
          mouseTrackLeft={10}
          className="custom-tooltip"
        />
        <span
          className="tooltipTarget cursor-pointer"
          onClick={() =>
            setActiveIndex([
              notes.findIndex(
                (note: CaseDocumentNote) => note.id === id,
              ) as number,
              1,
            ])
          }
          data-pr-tooltip={format(new Date(date as string), 'MMMM d, yyyy')}
        >
          <motion.svg
            height="20"
            width="20"
            initial={{ scale: 1 }}
            animate={{ scale: isActiveIndex(id) ? 1.5 : 1 }}
          >
            <motion.circle
              cx="10"
              cy="10"
              r="8"
              stroke="hsl(230, 100%, 85%)"
              fill="hsl(230, 75%, 85%)"
              initial={{ strokeWidth: 0, fill: 'hsl(230, 75%, 85%)' }}
              animate={{
                strokeWidth: isActiveIndex(id) ? 2 : 0,
                fill: isActiveIndex(id)
                  ? 'hsl(230, 75%, 55%)'
                  : 'hsl(230, 75%, 85%)',
              }}
            />
          </motion.svg>
        </span>
      </div>
    );
  };

  const customizedContent = ({ id, date }: CaseDocumentNote) => {
    // Fixed width of w-32 to prevent timeline layout from breaking
    return (
      <div className="relative w-32">
        {isActiveIndex(id) && (
          <motion.div
            className={`bg-primary-white transition-opacity} absolute -ml-12 w-32 select-none rounded-full border-2 border-primary-base px-2 py-1 text-center font-medium text-primary-base shadow-lg`}
            id={id}
          >
            <p>{format(new Date(date as string), 'MMM d, yyyy')}</p>
          </motion.div>
        )}
      </div>
    );
  };

  function handleNavigateRight() {
    if (activeIndex < (data?.count as number) - 1) {
      const newIndex = activeIndex + 1;
      setActiveIndex([newIndex, -1]);
    }
  }

  function handleNavigateLeft() {
    if (activeIndex >= 1) {
      const newIndex = activeIndex - 1;
      setActiveIndex([newIndex, 1]);
    }
  }

  return (
    <Suspense fallback={<Skeleton width="full" height="30rem" />}>
      {/* No notes  */}
      {notes.length === 0 && (
        <div className="w-full rounded-lg bg-gray-100 p-6">
          <p className="text-lg font-medium">
            This is where a timeline of your notes will show up. Add a note to a{' '}
            <Link
              to={`/cases/${caseId}#documents`}
              className="font-semibold text-primary-base hover:underline"
            >
              document{' '}
            </Link>
            or in the{' '}
            <Link
              to={`/cases/${caseId}#notes`}
              className="font-semibold text-primary-base hover:underline"
            >
              notes tab
            </Link>{' '}
            to get started.
          </p>
        </div>
      )}

      {/* Show when there is only one note */}
      {notes.length === 1 && (
        <div className="rounded-lg bg-gray-100 p-6">
          <p className="text-lg text-primary-base">
            This is your only note. Add more than 1 note to see the case
            timeline.
          </p>
        </div>
      )}

      {/* Display timeline when there is more than one note */}
      {notes.length > 1 && (
        <div className="relative flex items-start justify-between gap-x-4 pt-12">
          <TimelineLabel
            label="See first note"
            direction="left"
            setTimelineIndex={setActiveIndex}
            eventsCount={notes.length}
          />
          <TimelineNavigateArrow
            direction="left"
            onClick={() => handleNavigateLeft()}
          />

          <Timeline
            value={notes}
            layout="horizontal"
            marker={customizedMarker}
            content={customizedContent}
            // To align the arrows with the timeline we have to use negative margin
            style={{ margin: '-20px 20px 20px 0' }}
          />

          <TimelineNavigateArrow
            direction="right"
            onClick={() => handleNavigateRight()}
          />
          <TimelineLabel
            label="See most recent note"
            direction="right"
            setTimelineIndex={setActiveIndex}
            eventsCount={notes.length}
          />
        </div>
      )}

      {/* Display note content */}
      {notes.length > 0 && (
        <motion.div
          custom={direction}
          variants={sliderAnimation}
          initial="enter"
          animate="center"
          exit="exit"
          key={activeNote?.id}
        >
          <div className="mx-4 mt-2 mb-10 flex items-end justify-between">
            <p className="select-none text-3xl font-bold">{activeNote?.name}</p>
            <p className="text-lg text-gray-500">
              <span className="font-semibold text-gray-700">
                {activeIndex + 1}
              </span>{' '}
              of {notes.length}
            </p>
          </div>
          <div className="flex w-full rounded-lg border-2 border-gray-200 px-4 py-8 shadow-xl">
            <div className="relative flex w-1/4 justify-center gap-x-8">
              <FlexCol className="gap-y-2">
                <TimelineNoteLabel label="Date" />
                <p>
                  {format(new Date(activeNote?.date as string), 'MM/dd/yyyy')}
                </p>
              </FlexCol>
              <FlexCol className="gap-y-2">
                <TimelineNoteLabel label="Time" />
                <p>{format(new Date(activeNote?.date as string), 'p')}</p>
              </FlexCol>
            </div>
            <div className="relative ml-10 w-3/4">
              <div className="absolute -left-6 top-0 h-full w-[2px] rounded-full bg-gray-200" />
              <FlexCol className="gap-y-2">
                <TimelineNoteLabel label="Note name" />
                <p>{activeNote?.name}</p>
              </FlexCol>

              {activeNote?.document && (
                <>
                  <Spacer height="h-8" />
                  <FlexCol>
                    <TimelineNoteLabel label="Document" />
                    {/* TODO: Make this a link to the document */}
                    <p>{activeNote?.document?.name}</p>
                  </FlexCol>
                  <Spacer height="h-8" />
                  <FlexCol>
                    <TimelineNoteLabel label="Selected Text" />
                    <p>
                      {activeNote?.editedSelectedText ||
                        activeNote?.selectedText}
                    </p>
                  </FlexCol>
                </>
              )}
              {activeNote?.comment && (
                <>
                  <Spacer height="h-8" />
                  <FlexCol className="gap-y-2">
                    <TimelineNoteLabel label="Comment" />
                    <p>{activeNote?.comment}</p>
                  </FlexCol>
                </>
              )}
              {activeNote?.people && activeNote?.people.length > 0 && (
                <>
                  <Spacer height="h-8" />
                  <FlexCol className="gap-y-2">
                    <TimelineNoteLabel label="People" />
                    <div className="flex gap-x-2">
                      {activeNote?.people?.map((person: CasePeople) => (
                        <div
                          key={person.id}
                          className="flex items-center gap-x-2 rounded-full bg-primary-base/20 px-3 py-1"
                        >
                          {person.firstName} {person.lastName}
                        </div>
                      ))}
                    </div>
                  </FlexCol>
                </>
              )}
              {activeNote?.issues && activeNote?.issues.length > 0 && (
                <>
                  <Spacer height="h-8" />
                  <FlexCol className="gap-y-2">
                    <TimelineNoteLabel label="Issues" />
                    <div className="flex gap-x-2">
                      {activeNote?.issues?.map((issue: CaseIssue) => (
                        <div
                          key={issue.id}
                          className="flex items-center gap-x-2 rounded-full bg-primary-base/20 px-3 py-1"
                        >
                          {issue.name}
                        </div>
                      ))}
                    </div>
                  </FlexCol>
                </>
              )}
            </div>
          </div>
        </motion.div>
      )}

      <Spacer height="h-12" />
    </Suspense>
  );
}

export default TimelineTab;
