// Libraries
import { FormProvider, useForm } from 'react-hook-form';

// Endpoints
import { useCreateCaseIssueMutation } from '../../../../../shared/store/endpoints/case-issues';

// Components
import FormModalFooter from '../../../../../shared/components/form/FormModalFooter';
import { Dialog } from 'primereact/dialog';
import { useParams } from 'react-router-dom';
import CaseIssueForm, {
  setCaseIssueFormValues,
  CaseIssueFormValues,
} from '../CaseIssueForm';
import useToast from '../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface CreateCaseIssueModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function CreateCaseIssueModal({
  showModal,
  setShowModal,
}: CreateCaseIssueModalProps): JSX.Element {
  // hooks
  const [createCaseIssue, { isLoading }] = useCreateCaseIssueMutation();
  const { caseId } = useParams();
  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseIssueFormValues(),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // methods
  async function addNewIssue(data: CaseIssueFormValues) {
    try {
      await createCaseIssue({
        createCaseIssuePayload: {
          caseId: caseId as string,
          ...data,
        },
      }).unwrap();
      showSuccessToast('Case issue successfully created');
      closeModal();
    } catch (err) {
      showErrorToast('Error creating case issue, please try again');
    }
    setShowModal(false);
  }

  function closeModal() {
    reset();
    setShowModal(false);
  }

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="Add Issue"
        appendTo="self"
        visible={showModal}
        onHide={closeModal}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            disabled={!isValid}
            onSubmit={handleSubmit(addNewIssue)}
            submitText="Add Issue"
            loading={isLoading}
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(addNewIssue)}>
            <CaseIssueForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
