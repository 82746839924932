import React from 'react';
import DynamicHeroIcon from '../../../../shared/components/DynamicHeroIcon/DynamicHeroIcon';

interface ChangePhotoButtonProps {
  handleSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ChangePhotoButton({
  handleSelectFile,
}: ChangePhotoButtonProps) {
  return (
    <label
      htmlFor="file-upload"
      className="relative mt-4 flex w-max cursor-pointer items-center gap-x-2 rounded-lg border bg-gradient-to-br from-gray-500 to-gray-800 py-2 px-4 text-lg font-medium text-white transition-colors hover:from-gray-600 hover:to-gray-900"
    >
      <input
        className="sr-only"
        type="file"
        id="file-upload"
        name="file-upload"
        onChange={handleSelectFile}
        accept="image/jpeg, image/png"
      />
      <DynamicHeroIcon icon="CameraIcon" />
      <p className="">Upload new</p>
    </label>
  );
}
