import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['Cases', 'Case'],
  endpoints: {
    getCase: {
      providesTags: ['Case'],
    },
    getCases: {
      providesTags: ['Cases'],
    },
    createCase: {
      invalidatesTags: ['Cases'],
    },
    updateCase: {
      invalidatesTags: ['Cases', 'Case'],
    },
  },
});

export const {
  useGetCaseQuery,
  useGetCasesQuery,
  useCreateCaseMutation,
  useUpdateCaseMutation,
} = enhancedApi;
