import { useGetCaseTypesQuery } from '../../../../shared/store/endpoints/case-type-endpoints';
import CaseTypeTable from './components/CaseTypeTable';

export default function CaseTypeTab(): JSX.Element {
  const { data: caseTypes } = useGetCaseTypesQuery();
  return (
    <div>
      <CaseTypeTable caseTypes={caseTypes} />
    </div>
  );
}
