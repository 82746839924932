import { FC } from 'react';
import ActivityList from '../../shared/components/ActivityList';
import PageTitle from '../../shared/components/PageTitle';

interface ActivityDashboardPageProps {}

const ActivityDashboardPage: FC<ActivityDashboardPageProps> = () => {
  return (
    <div>
      <PageTitle text="Activity" />
      <ActivityList />
    </div>
  );
};

export default ActivityDashboardPage;
