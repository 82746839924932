import { Tooltip } from 'primereact/tooltip';

/**
 * Remember to pass in the whole tailwind class for width
 */

export const truncatedTextWithTooltipTemplate = (
  text: string,
  width = '15rem',
) => (
  <>
    <Tooltip
      target=".tooltipTarget"
      mouseTrack
      mouseTrackLeft={10}
      style={{ maxWidth: '20rem' }}
    />
    <div className="grid" style={{ width: width }}>
      <p
        className={`tooltipTarget cursor-default truncate`}
        data-pr-tooltip={text}
      >
        {text}
      </p>
    </div>
  </>
);
