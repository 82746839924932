import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['CaseDocuments'],
  endpoints: {
    getAllCaseDocuments: {
      providesTags: ['CaseDocuments'],
    },
    addMultipleCaseDocuments: {
      invalidatesTags: ['CaseDocuments'],
    },
    updateCaseDocument: {
      invalidatesTags: ['CaseDocuments'],
    },
    deleteCaseDocuments: {
      invalidatesTags: ['CaseDocuments'],
    },
    checkDocumentEditLog: {
      invalidatesTags: ['CaseDocuments'],
    },
  },
});

export const {
  useGetAllCaseDocumentsQuery,
  useAddMultipleCaseDocumentsMutation,
  useUpdateCaseDocumentMutation,
  useDeleteCaseDocumentsMutation,
  useCheckDocumentEditLogMutation,
  useLazyExportCaseDocumentWithNotesQuery,
} = enhancedApi;
