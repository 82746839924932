import { motion } from 'framer-motion';
import DynamicHeroIcon from '../../../../shared/components/DynamicHeroIcon';
interface TimelineNavigateArrowProps {
  direction: 'left' | 'right';
  onClick: () => void;
}
function TimelineNavigateArrow({
  direction,
  onClick,
}: TimelineNavigateArrowProps): JSX.Element {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="mt-1 flex cursor-pointer items-center justify-center rounded-full bg-primary-base p-1 hover:bg-primary-dark"
    >
      {direction === 'right' ? (
        <DynamicHeroIcon
          icon="ChevronRightIcon"
          className="w-6 cursor-pointer text-white"
        />
      ) : (
        <DynamicHeroIcon
          icon="ChevronLeftIcon"
          className="w-6 cursor-pointer text-white"
        />
      )}
    </motion.div>
  );
}

export default TimelineNavigateArrow;
