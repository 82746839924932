import classNames from 'classnames';
import { InputMask } from 'primereact/inputmask';
import { Controller } from 'react-hook-form';
import FormLabel from '../SharedFormComponents/FormLabel';
import FormError from '../SharedFormComponents/FormError';

const FormInputWithMask = ({
  control,
  errors,
  name,
  label,
  rules,
  type,
  inputRef,
  forgot,
  mask,
  slotChar,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  forgot?: boolean;
  mask?: string;
  slotChar?: string;
}) => {
  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <InputMask
                id={name + '-input'}
                className={classNames('mt-1 w-full', {
                  'p-invalid': fieldState.error,
                })}
                {...field}
                mask={mask}
                ref={inputRef}
                slotChar={slotChar}
              />
            </>
          );
        }}
      />
      <FormError name={name} errors={errors} />
    </div>
  );
};
export default FormInputWithMask;
