import axios from 'axios';

export default function useStorage() {
  async function getReadUrl(fileName: string) {
    try {
      const { data: url } = await axios.get(
        `${process.env.REACT_APP_API_URL}/storage/read-url/${encodeURIComponent(
          fileName,
        )}`,
      );
      return url;
    } catch (error) {
      console.error(error);
    }
  }

  // use this for cropper image upload or any other time we get a base64 string
  async function uploadFileString(
    file: string,
    fileName: string,
    fileType: string,
  ) {
    // get signed url to upload file
    const { data: url } = await axios.get(
      `${process.env.REACT_APP_API_URL}/storage/write-url/`,
      {
        params: {
          fileName,
          fileType,
        },
      },
    );
    // convert file url to blob
    const res = await fetch(file);
    const blob = await res.blob();
    const newFile = new File([blob], fileName, { type: fileType });
    await axios.put(url, newFile, {
      headers: {
        'Content-Type': fileType,
      },
      onUploadProgress: (progressEvent) => {
        // todo - handle global uploading progress
        console.info(
          'Upload Progress: ' +
            Math.round((progressEvent.loaded / progressEvent.total) * 100) +
            '%',
        );
      },
    });
    return fileName;
  }

  async function uploadFile(file: File, path?: string) {
    const fileName = path ? `${path}/${file.name}` : file.name;
    const { data: url } = await axios.get(
      `${process.env.REACT_APP_API_URL}/storage/write-url/`,
      {
        params: {
          fileName,
          fileType: file.type,
        },
      },
    );
    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        // todo - handle global uploading progress
        console.info(
          'Upload Progress: ' +
            Math.round((progressEvent.loaded / progressEvent.total) * 100) +
            '%',
        );
      },
    });
    return fileName;
  }

  async function downloadFile(url: string, path: string) {
    // download as blob so it can be saved as a file
    const res = await axios.get(url, {
      responseType: 'blob',
    });
    const filename = path.split('/').pop() as string;
    saveBlob(res.data, filename);
  }

  function saveBlob(blob: Blob, filename: string) {
    const href = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = href;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return {
    uploadFile,
    uploadFileString,
    getReadUrl,
    downloadFile,
    saveBlob,
  };
}
