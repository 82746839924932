import classNames from 'classnames';
import { motion } from 'framer-motion';
import DynamicHeroIcon from '../../../../shared/components/DynamicHeroIcon';

interface TimelineLabelProps {
  label: string;
  direction: 'left' | 'right';
  setTimelineIndex: (value: React.SetStateAction<[number, number]>) => void;
  eventsCount: number;
}
function TimelineLabel({
  label,
  direction,
  setTimelineIndex,
  eventsCount,
}: TimelineLabelProps) {
  return (
    <motion.p
      className={classNames(
        'mt-1 flex cursor-pointer items-center justify-center rounded-full bg-primary-base p-1 hover:bg-primary-dark',
      )}
      onClick={() => {
        direction === 'left'
          ? setTimelineIndex([0, 1])
          : setTimelineIndex([eventsCount - 1, -1]);
      }}
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.1 }}
    >
      {direction === 'left' ? (
        <DynamicHeroIcon
          icon="ChevronDoubleLeftIcon"
          className="w-6 text-white"
        />
      ) : (
        <DynamicHeroIcon
          icon="ChevronDoubleRightIcon"
          className="w-6 text-white"
        />
      )}
    </motion.p>
  );
}

export default TimelineLabel;
