import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { SelectItemOptionsType } from 'primereact/selectitem';
import FormLabel from '../SharedFormComponents/FormLabel';
import FormError from '../SharedFormComponents/FormError';

const FormSelect = ({
  control,
  errors,
  name,
  label,
  rules,
  inputRef,
  placeholder,
  options,
  filter,
  filterBy,
  showClear,
  className,
  disabled,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  options: SelectItemOptionsType;
  rules?: any;
  inputRef?: any;
  placeholder?: string;
  showClear?: boolean;
  className?: string;
  filter?: boolean;
  filterBy?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <Dropdown
                id={name + '-input'}
                className={classNames('w-full', {
                  'p-invalid': fieldState.error,
                })}
                {...field}
                ref={inputRef}
                options={options}
                placeholder={placeholder}
                showClear={showClear}
                filter={filter}
                filterBy={filterBy}
                disabled={disabled}
              />
            </>
          );
        }}
      />
      <FormError name={name} errors={errors} />
    </div>
  );
};
export default FormSelect;
