import { Menu } from 'primereact/menu';
import { useRef } from 'react';

export interface UseExportMenuProps {
  showAll?: boolean;
}
export const useExportMenu = ({ showAll = false }: UseExportMenuProps) => {
  function menuItems(
    downloadExport: (
      exportType: 'pdf' | 'docx',
      type: 'selected' | 'all',
    ) => void,
  ) {
    let options = [
      {
        label: 'Selected as PDF',
        icon: 'pi pi-fw pi-download',
        command: () => downloadExport('pdf', 'selected'),
      },
      {
        label: 'Selected as Docx',
        icon: 'pi pi-fw pi-download',
        command: () => downloadExport('docx', 'selected'),
      },
    ];
    if (showAll) {
      options = [
        ...options,
        {
          label: 'All as PDF',
          icon: 'pi pi-fw pi-download',
          command: () => downloadExport('pdf', 'all'),
        },
        {
          label: 'All as Docx',
          icon: 'pi pi-fw pi-download',
          command: () => downloadExport('docx', 'all'),
        },
      ];
    }
    return options;
  }

  const menuRef = useRef<Menu>(null);

  return { menuItems, menuRef };
};
