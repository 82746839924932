import { Dropdown } from 'primereact/dropdown';
import { PeopleTableData } from '../PeopleTable';
import TrueFalseChip from '../../../../../../shared/components/TrueFalseChip';

export const statementObtainedTemplate = ({
  statementObtained,
}: PeopleTableData) => {
  return (
    <TrueFalseChip
      label={statementObtained === 'true' ? 'Yes' : 'No'}
      value={statementObtained === 'true'}
    />
  );
};

const statuses = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const onStatementObtainedChange = (event: any, options: any) => {
  options.filterCallback(event.value);
};

export const statementObtainedValueTemplate = (
  options: {
    label: string;
    value: string;
  },
  props: any,
) => {
  return (
    <>
      {options ? (
        <TrueFalseChip label={options.label} value={options.value === 'true'} />
      ) : (
        <span>{props.placeholder}</span>
      )}
    </>
  );
};

export const statementObtainedFilterTemplate = (filterOptions: any) => {
  return (
    <Dropdown
      value={filterOptions.value}
      options={statuses}
      onChange={(e) => {
        onStatementObtainedChange(e, filterOptions);
      }}
      itemTemplate={(options) => (
        <TrueFalseChip label={options.label} value={options.value === 'true'} />
      )}
      placeholder="Select"
      className="p-column-filter"
      valueTemplate={statementObtainedValueTemplate}
    />
  );
};
