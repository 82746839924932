import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import checkmark from '../../../shared/lottie/checkmark.json';
import confettiTwoAnimation from '../../../shared/lottie/confetti-two.json';
import confettiAnimation from '../../../shared/lottie/confetti.json';

function SuccessCheckmark({ width = 'w-[350px]' }: { width?: string }) {
  return (
    <div className={`relative z-10 mx-auto ${width}`}>
      <Lottie
        animationData={confettiAnimation}
        className="absolute top-0 right-0 left-0 w-full"
        loop={false}
      />
      <Lottie
        animationData={confettiTwoAnimation}
        className="absolute top-0 right-0 left-0 w-full"
        loop={false}
      />
      <motion.div
        initial={{ y: 0, scale: 1 }}
        animate={{ y: [0, -50, 0], scale: [1, 1.5, 1] }}
        transition={{ type: 'spring' }}
      >
        <Lottie
          animationData={checkmark}
          className="absolute top-0 right-0 left-0"
          loop={false}
        />
      </motion.div>
    </div>
  );
}

export default SuccessCheckmark;
