import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FormProvider, useForm } from 'react-hook-form';
import FormModalFooter from '../../../../../shared/components/form/FormModalFooter';
import useToast from '../../../../../shared/hooks/useToast';
import {
  CasePeople,
  UpdateCasePeoplePayload,
} from '../../../../../shared/store/endpoints';
import { useUpdateCasePeopleMutation } from '../../../../../shared/store/endpoints/case-people';
import PeopleForm, {
  PeopleFormValues,
  setPeopleFormValues,
} from '../PeopleForm';

interface UpdatePeopleModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  personToEdit: CasePeople | null | undefined;
}

function UpdatePeopleModal({
  showModal,
  setShowModal,
  personToEdit,
}: UpdatePeopleModalProps) {
  const [updateCasePeople] = useUpdateCasePeopleMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  const form = useForm({
    mode: 'onChange',
    defaultValues: setPeopleFormValues({
      firstName: personToEdit?.firstName,
      lastName: personToEdit?.lastName,
      peopleRole: personToEdit?.peopleRole,
      statementObtained: personToEdit?.statementObtained,
      about: personToEdit?.about || '',
    }),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;

  //methods
  async function submitUpdatedPerson(data: PeopleFormValues) {
    if (personToEdit) {
      const { firstName, lastName, peopleRole, statementObtained, about } =
        data;

      const updateCasePeoplePayload: UpdateCasePeoplePayload = {
        firstName,
        lastName,
        peopleRole: peopleRole as 'witness' | 'party',
        statementObtained,
        about: about as string,
      };

      const { id: casePersonId } = personToEdit;

      try {
        await updateCasePeople({
          casePersonId,
          updateCasePeoplePayload,
        }).unwrap();
        showSuccessToast('Person successfully updated');
        closeModal();
      } catch (err) {
        showErrorToast('Error updating person, please try again');
      }
    }
  }

  function closeModal() {
    reset();
    setShowModal(false);
  }

  return (
    <>
      <Dialog
        style={{ width: '50vw' }}
        header="Update Person"
        visible={showModal}
        onHide={closeModal}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            onSubmit={handleSubmit(submitUpdatedPerson)}
            disabled={!isValid}
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submitUpdatedPerson)}>
            <PeopleForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}

export default UpdatePeopleModal;
