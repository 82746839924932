// templates/component/PdfExpressViewer.tsx

// Libraries
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

// @ts-ignore - types unavailable for this library
import WebViewer from '@pdftron/pdfjs-express';

// Endpoints

// Components
import { CaseDocumentNote } from '../../../../../../shared/store/endpoints';
import {
  convertAnnotationToHighlight,
  convertHighlightToAnnotation,
  DISABLED_ELEMENTS,
  DISABLED_TOOLS,
} from './helpers';
import { PdfEditorNote } from '../../../../../../shared/types/pdf-editor-types';

interface PdfExpressViewerProps {
  url: string | undefined;
  notes: CaseDocumentNote[] | undefined;
  currentNote: CaseDocumentNote | PdfEditorNote | undefined;
  selectNote: (
    note?: CaseDocumentNote | PdfEditorNote | undefined,
    action?: 'add' | 'edit' | 'selectionUpdated' | undefined,
  ) => void;
  setXfdfData: (xfdfData: string) => void;
}

export default function PdfExpressViewer({
  url,
  notes,
  currentNote,
  selectNote,
  setXfdfData,
}: PdfExpressViewerProps): JSX.Element {
  /** hooks */

  /** state */
  const [viewerLoaded, setViewerLoaded] = useState(false);
  const [annotationsLoaded, setAnnotationsLoaded] = useState(false);

  /** refs */
  const viewer = useRef<any>(null);
  const instanceRef = useRef<any>(null);
  const currentNoteRef = useRef<any>(null);

  /** useCallback */

  const cleanupAnnotations = useCallback(() => {
    if (!instanceRef.current || !notes) return;
    const annotationManager = getAnnotationManager();
    const annotationsList = annotationManager.getAnnotationsList();
    for (let index = 0; index < annotationsList.length; index++) {
      const annotation = annotationsList[index];
      // cleanup annotation that doesn't have contents
      if (!annotation.getContents()) {
        annotationManager.deleteAnnotation(annotation);
      }
      // cleanup annotation that doesn't have a note
      if (notes.find((n: CaseDocumentNote) => n.id === annotation.Id)) {
        // this caused infinite loop, but it's not needed for now
        // annotationManager.addAnnotation(annotation);
      } else {
        annotationManager.deleteAnnotation(annotation);
      }
    }
  }, [notes]);

  // adds annotations to the viewer;
  const addAnnotations = useCallback(() => {
    if (!instanceRef.current || !notes) return;
    const annotationManager = getAnnotationManager();
    if (notes && notes.length === 0) {
    }
    const annotationsList = annotationManager.getAnnotationsList();
    const annotationsToAdd = [];
    for (let index = 0; index < notes.length; index++) {
      const note = notes[index];
      if (!note.annotation) continue;
      const noteAnnotation = convertHighlightToAnnotation(
        note.annotation as any,
        instanceRef,
      );
      const found = annotationsList.find(
        (a: any) => a.Id === note.id && a.getContents(),
      );
      if (found) {
        annotationManager.updateAnnotation(noteAnnotation);
      } else {
        annotationsToAdd.push(noteAnnotation);
      }
    }
    annotationManager.addAnnotations(annotationsToAdd);
    cleanupAnnotations();
    if (!annotationsLoaded) {
      setAnnotationsLoaded(true);
    }
  }, [notes, annotationsLoaded, cleanupAnnotations]);

  // selects the highlight in the pdf viewer from the notes.  this cascades to "selectAnnotation" which opens the note editor
  const selectHighlight = useCallback((note: any) => {
    const annotationManager = getAnnotationManager();
    const selected = annotationManager.getSelectedAnnotations();
    // if the note is already selected, do nothing.
    if (selected.length > 0 && selected.find((s: any) => s.Id === note.id)) {
      return;
    }
    // select annotation
    const annotationsList = annotationManager.getAnnotationsList();
    const found = annotationsList.find((a: any) => a.Id === note.id);
    if (found) {
      annotationManager.selectAnnotation(found);
      const docViewer = getDocViewer();
      const currentPage = docViewer.getCurrentPage();
      if (currentPage !== found.PageNumber) {
        docViewer.setCurrentPage(found.PageNumber);
      }
    } else {
      if (note.annotation) {
        annotationManager.selectAnnotation(note.annotation);
      }
    }
  }, []);

  // Removes the buttons under the highlight that is selected.
  const deselectHighlights = useCallback(() => {
    const annotationManager = getAnnotationManager();
    annotationManager.deselectAllAnnotations();
    cleanupAnnotations();
  }, [cleanupAnnotations]);

  // called when selection has changed in the viewer
  const updateAnnotation = useCallback(
    (annotations: any) => {
      const annotation = annotations[0];
      annotation.setContents(annotation.rj['trn-annot-preview']);
      const found = notes?.find((n) => n.id === annotation.Id);
      if (found) {
        const highlight = convertAnnotationToHighlight(annotation);
        const note = {
          ...found,
          rawAnnotation: annotation,
          annotation: highlight,
          selectedText: annotation.getContents(),
        };
        selectNote(note, 'selectionUpdated');
      } else {
        const highlight = convertAnnotationToHighlight(annotation);
        const note = {
          ...currentNote,
          id: highlight.id,
          annotation: highlight,
          rawAnnotation: annotation,
          selectedText: annotation.getContents(),
        };
        selectNote(note, 'add');
      }
    },
    [notes, selectNote, currentNote],
  );

  const saveXFDFData = useCallback(async () => {
    const annotationManager = getAnnotationManager();
    const xfdfString = await annotationManager.exportAnnotations({
      widgets: false,
      links: false,
      fields: false,
      annotations: true,
    });
    setXfdfData(xfdfString);
  }, [setXfdfData]);

  /** Listeners */
  // annotation changed listener callback
  const annotationChangedListener = useCallback(
    (annotations: any, action: any, imported: any) => {
      if (imported.imported) {
        return;
      }
      if (action === 'add') {
        // handle add annotation.  Currently "add" actions are covered by "annotationSelectedListener"
        saveXFDFData();
      }
      if (action === 'modify') {
        // handle modify annotation.
        updateAnnotation(annotations);
        saveXFDFData();
      }
      if (action === 'delete') {
        // handle delete annotation.  Currently "delete" reacting to a "delete" side effect is not being done in the viewer.
        saveXFDFData();
      }
    },
    [updateAnnotation, saveXFDFData],
  );

  // annotation selected listener callback
  const annotationSelectedListener = useCallback(
    (annotations: any, action: any, obj: any) => {
      const annotation = annotations[0];
      const contents = annotation.getContents();
      const preview = annotation.rj['trn-annot-preview'];
      const found = notes?.find((n) => n.id === annotation.Id);
      if (action === 'selected') {
        annotation.setContents(preview || contents);
        const highlight = convertAnnotationToHighlight(annotation);
        if (found) {
          selectNote(found, 'edit');
        } else {
          const note = {
            id: highlight.id,
            annotation: highlight,
            rawAnnotation: annotation,
            selectedText: annotation.getContents(),
          };
          // handle when notation is selected
          selectNote(note, 'add');
        }
      } else if (action === 'deselected') {
        selectNote();
      }
    },
    [notes, selectNote],
  );

  const addListeners = useCallback(() => {
    if (!instanceRef.current) return;
    const annotationManager = getAnnotationManager();
    const docViewer = getDocViewer();
    if (!annotationManager || !docViewer) return;

    annotationManager.addEventListener(
      'annotationChanged',
      annotationChangedListener,
    );

    annotationManager.addEventListener(
      'annotationSelected',
      annotationSelectedListener,
    );

    docViewer.addEventListener('documentLoaded', addAnnotations);
  }, [addAnnotations, annotationChangedListener, annotationSelectedListener]);

  const removeListeners = useCallback(() => {
    if (!instanceRef.current) return;
    const annotationManager = getAnnotationManager();
    const { docViewer } = instanceRef.current;
    if (!annotationManager || !docViewer) return;
    annotationManager.removeEventListener(
      'annotationChanged',
      annotationChangedListener,
    );
    annotationManager.removeEventListener(
      'annotationSelected',
      annotationSelectedListener,
    );
    docViewer.removeEventListener('documentLoaded', addAnnotations);
  }, [addAnnotations, annotationChangedListener, annotationSelectedListener]);

  /** effects */
  // loads pdf viewer
  useLayoutEffect(() => {
    async function loadWebViewer() {
      const instance = await WebViewer(
        {
          path: '/vendor/webviewer',
          initialDoc: url,
          licenseKey: process.env.REACT_APP_PDF_EXPRESS || undefined,
        },
        viewer.current,
      );
      instanceRef.current = instance;
      setViewerLoaded(true);
    }
    if (!viewerLoaded) {
      setTimeout(() => loadWebViewer(), 250);
    }
  }, [url, viewerLoaded]);

  // add listeners when the viewer is loaded
  useEffect(() => {
    if (viewerLoaded) {
      disablePartsOfUI();
      addListeners();
    }
    return () => {
      removeListeners();
    };
  }, [viewerLoaded, addListeners, removeListeners]);

  // add annotations any time the notes change
  useEffect(() => {
    if (instanceRef.current) {
      addAnnotations();
    }
  }, [notes, addAnnotations]);

  // after annotations are loaded, select the note that is passed in
  useEffect(() => {
    if (annotationsLoaded) {
      if (currentNote) {
        selectHighlight(currentNote);
        currentNoteRef.current = currentNote;
      } else {
        if (currentNoteRef.current) {
          deselectHighlights();
          currentNoteRef.current = null;
        }
      }
    }
  }, [annotationsLoaded, selectHighlight, currentNote, deselectHighlights]);

  // methods
  function disablePartsOfUI() {
    if (!instanceRef.current) return;
    const { UI } = instanceRef.current;
    const { Feature } = UI;
    // disable features
    UI.disableFeatures([Feature.Copy]);
    // full list of tools that can be disabled
    UI.disableTools(DISABLED_TOOLS);
    // disable element/functionality that are not removed by feature and tools
    UI.disableElements(DISABLED_ELEMENTS);
  }

  // helper fn to get annotation manager
  function getAnnotationManager() {
    if (!instanceRef.current) return;
    const { annotationManager } = instanceRef.current.Core;
    return annotationManager;
  }

  function getDocViewer() {
    if (!instanceRef.current) return;
    const { docViewer } = instanceRef.current;
    return docViewer;
  }

  return <div className="webviewer h-full w-full" ref={viewer}></div>;
}
