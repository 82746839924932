import { motion } from 'framer-motion';
import React from 'react';
import { NavLink } from 'react-router-dom';
import DynamicHeroIcon from '../../DynamicHeroIcon';
import { IconName } from '../../DynamicHeroIcon/DynamicHeroIcon';
interface HeaderNavLinkProps {
  icon: IconName;
  children: React.ReactNode;
  href: string;
}

export default function HeaderNavLink({
  children,
  icon,
  href,
}: HeaderNavLinkProps) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        'relative my-3 flex items-center rounded-xl px-6 ring-1 ring-transparent transition-colors hover:border-primary-light/70 hover:bg-primary-darker/30 ' +
        (isActive &&
          ' border-primary-light bg-primary-darker/50 font-semibold text-white ring-primary-light')
      }
      children={({ isActive }) => (
        <>
          <motion.div
            className="flex items-center gap-x-2"
            whileTap={{ scale: 0.9 }}
          >
            <DynamicHeroIcon icon={icon} outline={!isActive} />
            <span>{children}</span>
          </motion.div>
          {isActive && (
            // This is the line that appears under the active nav item
            <motion.div
              className="absolute -bottom-3 right-0 left-0 h-1 w-full rounded-full bg-primary-light"
              layoutId="activeNavLink"
            />
          )}
        </>
      )}
    />
  );
}
