// Libraries

import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../../shared/components/Button';
import ExportButton from '../../../../../shared/components/ExportButton';
import { tableFilterSearchElement } from '../../../../../shared/components/table-templates/searchFilterElement';
import {
  tableDateTemplate,
  tableRangeFilterTemplate,
} from '../../../../../shared/components/table-templates/table-date-template';
import TableDeleteButton from '../../../../../shared/components/TableDeleteButton';
import useIssuesOptions from '../../../../../shared/hooks/useIssuesOptions';
import usePeopleOptions from '../../../../../shared/hooks/usePeopleOptions';
import useToast from '../../../../../shared/hooks/useToast';
import {
  CaseDocumentNote,
  CaseDocumentNotesWithCount,
} from '../../../../../shared/store/endpoints';
import { useDeleteCaseNotesMutation } from '../../../../../shared/store/endpoints/case-documents-notes';
import { truncatedTextWithTooltipTemplate } from '../../../../../shared/tableTemplates/truncatedTextWithTooltipTemplate';
import { LazyFilterParams } from '../../../../../shared/types/lazy-filter-params';
import { sanitizeNoteSearchFromFilter } from '../../NotesTab';

// Endpoints

// Components

interface CaseNotesTableProps {
  data: CaseDocumentNotesWithCount | undefined;
  isLoading: boolean;
  lazyParams: LazyFilterParams;
  setLazyParams: (params: LazyFilterParams) => void;
  createNote: (note?: CaseDocumentNote) => void;
}

export default function CaseNotesTable({
  data,
  isLoading,
  lazyParams,
  setLazyParams,
  createNote,
}: CaseNotesTableProps): JSX.Element {
  // hooks
  const { caseId } = useParams() as { caseId: string };
  const { peopleOptions } = usePeopleOptions(caseId);
  const { issuesOptions } = useIssuesOptions(caseId);
  const [deleteNotes] = useDeleteCaseNotesMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  // state
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState<CaseDocumentNote[]>([]);

  // methods
  function onPage(event: any) {
    setLazyParams(event);
  }

  function onSort(event: any) {
    setLazyParams(event);
  }

  function onFilter(event: any) {
    event['first'] = 0;
    setLazyParams(event);
  }

  async function handleDeleteNotes() {
    try {
      const notesToDelete = selectedNotes.map((note) => note.id);
      await deleteNotes({ noteIds: notesToDelete }).unwrap();
      setSelectedNotes([]);
      showSuccessToast('Notes deleted successfully');
    } catch (error) {
      showErrorToast('Error deleting notes');
    }
  }

  function onSelectAllChange(event: any) {
    const selectAll = event.checked;
    if (selectAll) {
      if (data?.notes) {
        setSelectAll(true);
        setSelectedNotes(data.notes);
      }
    } else {
      setSelectAll(false);
      setSelectedNotes([]);
    }
  }

  // templates
  function tableHeader() {
    const sanitizedFilter = sanitizeNoteSearchFromFilter(lazyParams.filters);
    const filters = {
      search: sanitizedFilter?.search,
      searchBy: sanitizedFilter?.searchBy,
      orderBy: lazyParams.sortField || 'date',
      orderDir: lazyParams.sortOrder === 1 ? 'asc' : 'desc',
    };
    return (
      <div className="flex w-full justify-end">
        <div className="flex gap-4">
          <>
            <ExportButton
              selected={selectedNotes.map((note) => note.id)}
              resource="case-notes"
              filters={filters}
              showAll={true}
            />
            <TableDeleteButton
              targetPluralText="notes"
              deleteFunction={handleDeleteNotes}
              selectedItems={selectedNotes}
              loading={isLoading}
            />
          </>
          <Button
            label="Add note"
            icon="pi pi-plus"
            onClick={() => createNote()}
          />
        </div>
      </div>
    );
  }

  function peopleTemplate(rowData: CaseDocumentNote) {
    return (
      <AvatarGroup>
        {rowData.people?.map((person: any) => (
          <Avatar
            size="large"
            shape="circle"
            label={`${person.firstName.split('')[0]}${
              person.lastName.split('')[0]
            }`}
            key={person.id}
          />
        ))}
      </AvatarGroup>
    );
  }

  function issuesTemplate(rowData: CaseDocumentNote) {
    return rowData.issues?.map((issue: any) => issue.name).join(', ');
  }

  function documentsTemplate(rowData: CaseDocumentNote) {
    return rowData.document ? rowData.document.name : '';
  }

  function peopleFilterTemplate(options: any) {
    return (
      <Dropdown
        value={options.value}
        options={peopleOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select person"
        showClear
        className="p-column-filter"
      />
    );
  }

  function issuesFilterTemplate(options: any) {
    return (
      <Dropdown
        value={options.value}
        options={issuesOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select issue"
        showClear
        className="p-column-filter"
      />
    );
  }

  return isLoading ? (
    <div>
      <Skeleton width="full" height="30rem" />
    </div>
  ) : (
    <div className="app-datatable">
      <DataTable
        value={data?.notes}
        lazy
        filterDisplay="menu"
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={data?.count}
        header={tableHeader}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onFilter={onFilter}
        filters={lazyParams.filters}
        loading={isLoading}
        selection={selectedNotes}
        onSelectionChange={(e) => setSelectedNotes(e.value)}
        selectAll={selectAll}
        onSelectAllChange={onSelectAllChange}
        className="p-datatable-data"
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        <Column
          field="date"
          header="Date"
          showFilterMatchModes={false}
          sortable
          filter
          body={(note: CaseDocumentNote) =>
            tableDateTemplate(note.date as string)
          }
          filterElement={tableRangeFilterTemplate}
        />
        <Column
          field="name"
          header="Name"
          showFilterMatchModes={false}
          sortable
          filter
          filterPlaceholder="Search by name"
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search by name')
          }
        />
        <Column
          field="comment"
          header="Comment"
          filter
          showFilterMatchModes={false}
          filterPlaceholder="Search comments"
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search comments')
          }
          body={(options) => truncatedTextWithTooltipTemplate(options.comment)}
        />
        <Column
          field="people"
          header="People"
          showFilterMatchModes={false}
          filter
          filterPlaceholder="Search people"
          body={peopleTemplate}
          filterElement={peopleFilterTemplate}
        />
        <Column
          field="issues"
          header="Issues"
          showFilterMatchModes={false}
          filter
          filterPlaceholder="Search issues"
          body={issuesTemplate}
          filterElement={issuesFilterTemplate}
        />
        <Column
          field="document"
          header="Documents"
          showFilterMatchModes={false}
          filter
          filterPlaceholder="Search documents"
          body={documentsTemplate}
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search documents')
          }
        />
        <Column
          header="Action"
          body={(rowData) => (
            <Button
              label={rowData.documentId ? 'View' : 'Edit'}
              className="p-button-outlined p-button-sm p-button-info"
              onClick={() => createNote(rowData)}
            />
          )}
        />
      </DataTable>
      <Toast ref={toastRef} />
    </div>
  );
}
