import { useState } from 'react';
import Button from '../../../../../shared/components/Button';
import CreateCaseIssueModal from '../CreateCaseIssueModal';

export default function CreateCaseIssueButton({
  label = 'Add Issue',
  className,
}: {
  label?: string;
  className?: string;
}): JSX.Element {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Button
        label={label}
        icon="pi pi-plus"
        className={className}
        onClick={() => setShowCreateModal(true)}
      />
      <CreateCaseIssueModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      />
    </>
  );
}
