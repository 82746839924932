import classNames from 'classnames';
import { get } from 'lodash';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { FC, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFormContext,
} from 'react-hook-form';
import FormError from '../SharedFormComponents/FormError';
import FormLabel from '../SharedFormComponents/FormLabel';

/**
 * NOTE: this multi select must be used within a form-provider component because of
 * the use of FormContext. Otherwise this component will not function correctly.
 */

interface FormMultiSelectProps {
  control: Control;
  errors: FieldErrorsImpl;
  name: string;
  label: string;
  options: SelectItemOptionsType;
  rules?: any;
  placeholder?: string;
  showClear?: boolean;
  className?: string;
  filter?: boolean;
  filterBy?: string;
}

const FormMultiSelect: FC<FormMultiSelectProps> = ({
  control,
  errors,
  name,
  label,
  rules,
  placeholder,
  options,
  filter,
  filterBy,
  showClear,
  className,
}) => {
  // Keeps in line with the actual formValues coming from the formContext
  const [chipDisplayValue, setChipDisplayValue] =
    useState<SelectItemOptionsType>([]);
  // Matches the input options, but with a disabled status depending on selection
  const [filteredOptions, setFilteredOptions] = useState<SelectItemOptionsType>(
    [],
  );
  const { setValue: setFormValue, getValues: getFormValues } = useFormContext();

  // Patch in changes from places other than this component
  useEffect(() => {
    if (options && options.length > 0) {
      const formValues = getFormValues();
      // Get our form control value and use the values to find the matching options to map to the chip display values
      const controlValue: string[] = get(formValues, name);
      if (controlValue !== undefined) {
        const value = controlValue.map((key) =>
          options.find((option) => option.value === key),
        );
        setChipDisplayValue(value);
      }
    }
  }, [options, getFormValues, name]);

  // When display values change, set disabled statuses for options in the filtered drop down options list
  useEffect(() => {
    const newOptions = options.map((option) => {
      return {
        ...option,
        disabled:
          chipDisplayValue.find((item) => item.value === option.value) !==
          undefined,
      };
    });
    setFilteredOptions([...newOptions]);
  }, [options, chipDisplayValue]);

  // Selected options are disabled, this should only need to add new values
  function onDropdownChange(selection: string) {
    const newValue: SelectItemOptionsType = [...chipDisplayValue];
    const option = options.find((option) => option.value === selection);
    newValue.push(option);
    updateValues(newValue);
  }

  function removeSelection(index: number) {
    const newValue: SelectItemOptionsType = [...chipDisplayValue];
    newValue.splice(index, 1);
    updateValues(newValue);
  }

  // Updates the chip display values and then maps just the values into the form control value
  function updateValues(newValue: SelectItemOptionsType) {
    setChipDisplayValue(newValue);
    const formValue = newValue.map((item) => item.value);
    setFormValue(name, formValue);
  }

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <div className="flex flex-col gap-2">
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <Dropdown
                id={name + '-input'}
                className={classNames('w-full', {
                  'p-invalid': fieldState.error,
                })}
                {...field}
                options={filteredOptions}
                placeholder={placeholder}
                showClear={showClear}
                filter={filter}
                filterBy={filterBy}
                onChange={(e) => onDropdownChange(e.value)}
              />
              <div className="flex flex-wrap gap-2">
                {chipDisplayValue.map((item, index) => {
                  return (
                    <div key={item.value}>
                      <Chip
                        label={item.label}
                        removable
                        onRemove={() => removeSelection(index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      />
      <FormError name={name} errors={errors} />
    </div>
  );
};

export default FormMultiSelect;
