//libraries
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { Navigate, Route, Routes } from 'react-router-dom';

//styles
import 'primeicons/primeicons.css'; //icons
import 'primereact/resources/primereact.min.css'; //core css
import './App.css';
import './theme/theme.css'; //our theme
import './theme/variables.css';

//components
import GetStarted from './auth/pages/GetStartedPage';
import RegisterPage from './auth/pages/RegisterPage';
import ActivityDashboardPage from './pages/ActivityDashboardPage';
import AdminTemplatesPage from './pages/AdminTemplatesPage';
import CaseDashboardPage from './pages/CaseDashboardPage';
import IndividualCasePage from './pages/IndividualCasePage';
import MembershipBaseFeeCancelPage from './pages/Membership/MembershipCancelPage';
import MembershipSuccessPage from './pages/Membership/MembershipSuccessPage';
import UpdatedBillingPage from './pages/Membership/UpdatedBillingPage';
import ProfilePage from './pages/ProfilePage';
import UserManagementPage from './pages/UserManagementPage';
import DashboardLayout from './shared/components/DashboardLayout';
import AuthGuard from './shared/guards/AuthGuard';
import RoleGuard from './shared/guards/RoleGuard/RoleGuard';

export default function App(): JSX.Element {
  return (
    <MotionConfig reducedMotion="user">
      <AnimatePresence mode="wait">
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard>
                <DashboardLayout />
              </AuthGuard>
            }
          >
            <Route path="cases" element={<CaseDashboardPage />} />
            <Route path="cases/:caseId" element={<IndividualCasePage />} />
            <Route path="activity" element={<ActivityDashboardPage />} />
            <Route path="account-settings" element={<ProfilePage />} />

            <Route
              path="user-management"
              element={
                <RoleGuard>
                  <UserManagementPage />
                </RoleGuard>
              }
            />
            <Route
              path="templates"
              element={
                <RoleGuard>
                  <AdminTemplatesPage />
                </RoleGuard>
              }
            />

            <Route
              path="membership/success"
              element={<MembershipSuccessPage />}
            />
            <Route
              path="membership/cancel"
              element={<MembershipBaseFeeCancelPage />}
            />
            <Route path="updated-billing" element={<UpdatedBillingPage />} />

            {/* handle the default route */}
            <Route path="/" element={<Navigate to="/cases" />} />
            {/* handle if the wrong route is typed in */}
            <Route path="*" element={<Navigate to="/cases" />} />
            {/* handle the default route */}
          </Route>
          <Route
            path="/register"
            element={
              <AuthGuard>
                <RegisterPage />
              </AuthGuard>
            }
          />

          <Route path="/get-started" element={<GetStarted />} />
        </Routes>
      </AnimatePresence>
    </MotionConfig>
  );
}
