// Libraries
import { FormProvider, useForm } from 'react-hook-form';
import UserForm, { setUserFormValues, UserFormValues } from '../UserForm';

// Endpoints
import { useUpdateProfileMutation } from '../../../../shared/store/endpoints/profile';
import {
  Profile,
  UpdateProfilePayload,
} from '../../../../shared/store/endpoints';

// Components
import FormModalFooter from '../../../../shared/components/form/FormModalFooter';
import { Dialog } from 'primereact/dialog';
import useToast from '../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';
import { ProfileRoles } from '../../../../shared/types/profile-types';

interface EditUserModalProps {
  profile: Profile;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function EditUserModal({
  showModal,
  setShowModal,
  profile: { firstName, lastName, email, active, permission },
  profile,
}: EditUserModalProps): JSX.Element {
  // hooks
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: setUserFormValues({
      firstName,
      lastName,
      email,
      active,
      role: permission.role,
    }),
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // methods
  function closeModal() {
    setShowModal(false);
    reset();
  }

  async function submit(data: UserFormValues) {
    const { firstName, lastName, email, active, role } = data;
    const updateProfilePayload: UpdateProfilePayload = {
      firstName,
      lastName,
      email,
      active,
      role: role as ProfileRoles,
    };
    try {
      await updateProfile({
        updateProfilePayload,
        profileId: profile.id,
      }).unwrap();
      showSuccessToast('User successfully updated');
      closeModal();
    } catch (err) {
      showErrorToast('Error updating user, please try again');
    }
  }

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="Edit User"
        visible={showModal}
        onHide={closeModal}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            disabled={!isValid || isLoading}
            onSubmit={handleSubmit(submit)}
            submitText="Save changes"
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submit)}>
            <UserForm editMode={true} />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
