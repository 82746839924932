import axios from 'axios';
import { endpoints, Profile } from '../endpoints';

export const profileCustomApi = endpoints.injectEndpoints({
  endpoints: (build) => ({
    getAllProfilesWithPhotoPath: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, getAllProfiles) {
        const result = await getAllProfiles({ url: '/profile' });
        let profiles: Profile[] = [];
        const data = result.data as Profile[];

        for (const profile of data) {
          //If it user has a profile photo, go get it
          if (profile.profilePhotoPath) {
            const { data: readablePhotoUrl } = await axios.get(
              `${
                process.env.REACT_APP_API_URL
              }/storage/read-url/${encodeURIComponent(
                profile.profilePhotoPath,
              )}`,
            );
            profiles = [
              ...profiles,
              { ...profile, profilePhotoPath: readablePhotoUrl },
            ];
          }
          //If not, return the profile without a photo
          else {
            profiles = [...profiles, profile];
          }
        }
        return {
          data: profiles,
        };
      },
    }),
  }),
});

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['Profile', 'AllProfiles'],
  endpoints: {
    getByEmail: {
      providesTags: ['Profile'],
    },
    updateProfile: {
      invalidatesTags: ['Profile', 'AllProfiles'],
    },
    createProfile: {
      invalidatesTags: ['Profile', 'AllProfiles'],
    },
    inviteUser: {
      invalidatesTags: ['AllProfiles'],
    },
    getAllProfiles: {
      providesTags: ['AllProfiles'],
    },
    setProfileActiveStatus: {
      invalidatesTags: ['AllProfiles', 'Profile'],
    },
  },
});

export const { useGetAllProfilesWithPhotoPathQuery } = profileCustomApi;

export const {
  useGetByEmailQuery,
  useLazyGetByEmailQuery,
  useUpdateProfileMutation,
  useCreateProfileMutation,
  useInviteUserMutation,
  useGetAllProfilesQuery,
  useSetProfileActiveStatusMutation,
  useGetUserProfilesQuery,
} = enhancedApi;
