import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';

import axios, { AxiosRequestConfig, AxiosError } from 'axios';

// adapted from https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = 'get', body, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL as string,
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
