// Libraries

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useMemo, useState } from 'react';
import FormModalFooter from '../../../shared/components/form/FormModalFooter';
import useToast from '../../../shared/hooks/useToast';
import {
  Profile,
  useGetAdminProfilesQuery,
} from '../../../shared/store/endpoints';
import { useUpdateProfileMutation } from '../../../shared/store/endpoints/profile';
import { ProfileRolesEnum } from '../../../shared/types/profile-types';
import { translateProfileRoles } from '../../../shared/utility-fns/profile-fns';

// Endpoints

// Components

interface ChangeRoleModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  profile: Profile | undefined;
}

export default function ChangeRoleModal({
  showModal,
  setShowModal,
  profile,
}: ChangeRoleModalProps): JSX.Element {
  // hooks
  const { data: admins, isLoading } = useGetAdminProfilesQuery();
  const [updateProfile, { isLoading: updating }] = useUpdateProfileMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // state
  const [role, setRole] = useState(profile?.permission?.role);

  // data
  const roleSelectOptions = [
    { label: translateProfileRoles('admin'), value: ProfileRolesEnum.admin },
    {
      label: translateProfileRoles('caseUser'),
      value: ProfileRolesEnum.caseUser,
    },
  ];

  const adminCount = useMemo(() => {
    return admins?.length || 0;
  }, [admins]);

  // methods
  async function submit() {
    if (!profile) {
      return;
    }
    try {
      await updateProfile({
        profileId: profile?.id,
        updateProfilePayload: {
          role,
        },
      }).unwrap();
      showSuccessToast('Role successfully updated');
      setShowModal(false);
    } catch (err) {
      showErrorToast('Error changing your role, please try again');
    }
  }

  return (
    <>
      <Dialog
        visible={showModal}
        onHide={() => setShowModal(false)}
        header="Change Role"
        footer={
          <FormModalFooter
            resetForm={() => setRole(profile?.permission?.role)}
            setShowModal={setShowModal}
            disabled={adminCount < 2}
            loading={updating}
            onSubmit={submit}
            submitText="Change Role"
          />
        }
      >
        {adminCount < 2 && (
          <div className="w-[512px]">
            <p>
              You are the only admin in your organization. There must be at
              least one admin per organization. Please designate another user as
              an admin on the User Management page before changing your role.
            </p>
          </div>
        )}
        {adminCount >= 2 && (
          <div className="w-[512px]">
            <p className="mb-6">
              There are {adminCount} admins in your organization. There is a
              minimum of one admin per organization.
            </p>
            <p className="p-error mb-6">
              <span className="font-bold">Warning:</span> by changing your role
              from admin, you will lose access to user management, billing, and
              templates. You will be unable to change your role yourself and
              must have another admin in the organization make alterations for
              you.
            </p>
            <label
              htmlFor="role-select"
              className="ml-2 mb-2 block font-medium"
            >
              User Role
            </label>
            <Dropdown
              className="w-full"
              id="role-select"
              options={roleSelectOptions}
              value={role}
              disabled={isLoading}
              onChange={(e) => setRole(e.value)}
            />
          </div>
        )}
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
