import { format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { FilterOptions } from '../../types/table-types';

export const tableDateTemplate = (dateInput: string | Date) => {
  const date = new Date(dateInput);
  const monthDay = format(date, 'MMM d');
  const year = format(date, 'yyyy');
  const time = format(date, 'p');
  return (
    <div className="flex flex-col">
      <p>
        <span className="font-bold">{monthDay}</span>
        {', '}
        {year}
      </p>
      <span className="text-xs text-gray-500">{time}</span>
    </div>
  );
};

export const tableDateFilterTemplate = (options: FilterOptions) => {
  return (
    <div className="relative w-[13rem] text-sm font-medium focus:relative md:block">
      <Calendar
        value={options.value}
        onChange={(event) => options.filterCallback(event.value)}
        dateFormat="m/d/y"
        className="chart-calendar"
        placeholder="Select a date"
        showIcon
      />
    </div>
  );
};

export const tableRangeFilterTemplate = (options: FilterOptions) => {
  const start = options.value && options.value[0];
  const end = options.value && options.value[1];
  return (
    <div className="flex-col">
      <div className="w-[13rem] font-medium">
        <Calendar
          id="start"
          value={start}
          onChange={(event) => options.filterCallback([event.value, end])}
          dateFormat="m/d/y"
          className="chart-calendar"
          placeholder="Select start date"
          showIcon
        />
      </div>
      <div className="flex w-full justify-center py-4"></div>
      <div className="w-[13rem] font-medium">
        <Calendar
          id="end"
          value={options.value && options.value[1]}
          onChange={(event) => options.filterCallback([start, event.value])}
          dateFormat="m/d/y"
          className="chart-calendar"
          placeholder="Select end date"
          showIcon
        />
      </div>
    </div>
  );
};
