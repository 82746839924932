// Libraries
import { FormProvider, useForm } from 'react-hook-form';

// Endpoints
import {
  CaseIssue,
  UpdateCaseIssuePayload,
} from '../../../../../shared/store/endpoints';
import { useUpdateCaseIssueMutation } from '../../../../../shared/store/endpoints/case-issues';

// Components
import { Dialog } from 'primereact/dialog';
import FormModalFooter from '../../../../../shared/components/form/FormModalFooter';
import CaseIssueForm, {
  setCaseIssueFormValues,
  CaseIssueFormValues,
} from '../CaseIssueForm';
import useToast from '../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface UpdateCaseIssueModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  issueToEdit: CaseIssue | null | undefined;
  onHide: () => void;
}

export default function UpdateCaseIssueModal({
  showModal,
  setShowModal,
  issueToEdit,
  onHide,
}: UpdateCaseIssueModalProps): JSX.Element {
  // hooks
  const [updateCaseIssue, { isLoading }] = useUpdateCaseIssueMutation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseIssueFormValues({
      name: issueToEdit?.name,
      description: issueToEdit?.description,
    }),
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  // methods
  async function submit(data: CaseIssueFormValues) {
    if (issueToEdit) {
      const { name, description } = data;
      const updateCaseIssuePayload: UpdateCaseIssuePayload = {
        name,
        description,
      };
      try {
        await updateCaseIssue({
          issueId: issueToEdit.id.toString(),
          updateCaseIssuePayload,
        }).unwrap();
        showSuccessToast('Case issue successfully updated');
        setShowModal(false);
      } catch (err) {
        showErrorToast('Error updating case issue');
      }
    }
  }

  return (
    <>
      <Dialog
        header="Update Issue"
        visible={showModal}
        onHide={() => {
          setShowModal(false);
          onHide();
        }}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            onSubmit={handleSubmit(submit)}
            disabled={!isValid}
            loading={isLoading}
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submit)}>
            <CaseIssueForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
