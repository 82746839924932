import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useLazyCreateStripeCheckoutSessionQuery } from '../../store/endpoints/stripe';
import Button from '../Button';
import Spacer from '../Spacer';

interface SubscribeModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}
function SubscribeModal({ showModal, setShowModal }: SubscribeModalProps) {
  function renderSubscribeHeader() {
    return (
      <h2 className="bg-gradient-to-tr from-primary-base to-primary-dark bg-clip-text text-3xl font-bold text-transparent">
        Subscribe to unlock new cases
      </h2>
    );
  }

  //Stripe checkout
  const [getUrl] = useLazyCreateStripeCheckoutSessionQuery();

  async function handleCheckout() {
    const url = await getUrl().unwrap();
    window.location.replace(url);
  }
  return (
    <Dialog
      visible={showModal}
      onHide={() => setShowModal(false)}
      style={{ backgroundColor: '#000000' }}
      header={renderSubscribeHeader}
    >
      <section className="w-[500px] text-lg">
        <div className="flex flex-col gap-y-4">
          <p className="font-medium text-gray-600">
            <span className="text-3xl">💼 </span>Unlimited cases{' '}
          </p>
          <p className="font-medium text-gray-600">
            {' '}
            <span className="text-3xl">📜 </span> Unlimited documents{' '}
          </p>
          <p className="font-medium text-gray-600">
            <span className="text-3xl">💰 </span> $58/month • $25/month per user
          </p>
        </div>

        <Spacer height="h-8" />
        <Button
          label="Upgrade now"
          onClick={() => handleCheckout()}
          className="shadow-lg"
          icon="pi pi-unlock"
          fullWidth
        />
      </section>
    </Dialog>
  );
}

export default SubscribeModal;
