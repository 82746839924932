// Libraries

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../shared/components/Button';
import DynamicHeroIcon from '../../../../shared/components/DynamicHeroIcon';
import useToast from '../../../../shared/hooks/useToast';
import {
  CaseDocument,
  CaseDocumentNote,
  useAddCaseNoteMutation,
  useGetAllCaseNotesForDocumentQuery,
  useGetCaseQuery,
  useUpdateCaseNoteMutation,
} from '../../../../shared/store/endpoints';
import { useUpdateCaseDocumentMutation } from '../../../../shared/store/endpoints/case-documents';
import { useDeleteCaseNotesMutation } from '../../../../shared/store/endpoints/case-documents-notes';
import {
  Highlight,
  PdfEditorNote,
} from '../../../../shared/types/pdf-editor-types';
import { CreateNoteFormValues } from '../CreateNote';
import NotesSidebar from './_components/NotesSidebar';
import PdfExpressViewer from './_components/PdfExpressViewer';
import RenderActiveUsers from './_components/RenderActiveUsers';

interface PdfModalProps {
  document: CaseDocument;
  caseId: string;
  showModal: boolean;
  closeModal: () => void;
  selectedNote?: CaseDocumentNote;
  url: string;
}

export default function PdfModal({
  document,
  caseId,
  showModal,
  closeModal,
  selectedNote,
  url,
}: PdfModalProps): JSX.Element {
  const [page, setPage] = useState(0);
  // hooks

  const [addNote, { isLoading: addLoading }] = useAddCaseNoteMutation();
  const [updateNote, { isLoading: updateLoading }] =
    useUpdateCaseNoteMutation();
  const [updateDocument, { isLoading: updateDocumentLoading }] =
    useUpdateCaseDocumentMutation();
  const [deleteNotes, { isLoading: deleteLoading }] =
    useDeleteCaseNotesMutation();

  const { data: caseData } = useGetCaseQuery({ caseId });

  const { showErrorToast, showSuccessToast, toastRef } = useToast();
  const { data } = useGetAllCaseNotesForDocumentQuery({
    caseId,
    documentId: document.id,
    skip: page,
    take: 10,
  });

  const { notes: notesArray, count } = data || {};
  /** useMemo */
  // converts annotations on notes to object and assigns new array to notes const
  const notes: CaseDocumentNote[] | undefined = useMemo(() => {
    if (!notesArray) return;
    const parsedNotes = notesArray?.map((note: CaseDocumentNote) => ({
      ...note,
      annotation: JSON.parse(note.annotation),
    }));
    return parsedNotes;
  }, [notesArray]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // hacky way to prevent flickering in the "load" button as the loading state changes
    let timeout: any;
    if (addLoading || updateLoading || deleteLoading) {
      setLoading(true);
    } else {
      timeout = setTimeout(() => setLoading(false), 500);
    }
    return () => timeout && clearTimeout(timeout);
  }, [addLoading, updateLoading, updateDocumentLoading, deleteLoading]);

  // state
  const [currentNote, setCurrentNote] = useState<
    CaseDocumentNote | PdfEditorNote | undefined
  >(selectedNote);
  const [xfdfData, setXfdfData] = useState<string | undefined>();
  // handle selecting a note that doesn't have a highlight upon modal open
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | undefined>(
    selectedNote && !selectedNote.selectedText ? 'edit' : undefined,
  );

  /** useCallback */
  const selectNote = useCallback(
    async (
      note: CaseDocumentNote | PdfEditorNote | undefined = undefined,
      action: 'edit' | 'add' | 'selectionUpdated' | undefined = undefined,
    ) => {
      try {
        /**
         * Eagerly save note if the text has been changed.
         * This is to keep the highlight in the viewer in sync with the note in the sidebar.
         * if we don't do this, things will get out of sync, which can be confusing.  Definitely
         * not an ideal solution, but this was needed because there are multiple things happening
         * at once in the PDF viewer, so there wasn't a good way to "revert" a highlight if the user changed their mind
         *
         */
        if (action === 'selectionUpdated') {
          if (note && note.id) {
            // TODO: Update with own payload type
            const { name, comment, date, annotation } = note as any;
            await updateNote({
              caseNoteId: note.id,
              createCaseDocumentNotePayload: {
                name,
                caseId,
                comment,
                date,
                selectedText: note.selectedText as string,
                editedSelectedText: note.selectedText as string,
                annotation: annotation as Highlight,
                pageNumber: annotation.pageNumber,
                topCoordinate: annotation.y,
              },
            }).unwrap();
            // update the document on save to make sure the xfdf is up to date
            await updateDocument({
              caseDocumentId: document.id,
              caseDocument: {
                ...document,
                xfdf: xfdfData as string,
              },
            }).unwrap();
          }
          if (note) {
            (note as CaseDocumentNote).editedSelectedText = note.selectedText;
          }
          setCurrentNote(note);
          setNoteAction('edit');
        } else {
          // adding date of previous note as "default" date for new note per client request - DOCELF-191
          if (action === 'add' && notesArray && notesArray.length > 0 && note) {
            const previousDate = notesArray[notesArray.length - 1].date;
            (note as CaseDocumentNote).date = previousDate;
          }
          setCurrentNote(note);
          setNoteAction(action);
        }
      } catch (err) {
        showErrorToast('Error selecting note');
      }
    },
    [
      updateNote,
      document,
      xfdfData,
      updateDocument,
      showErrorToast,
      caseId,
      notesArray,
    ],
  );

  // methods
  function handleClose() {
    closeModal();
  }

  function handleCancel() {
    selectNote();
  }

  async function submit(
    data: CreateNoteFormValues & { editedSelectedText: string },
  ) {
    const note: any = {
      ...currentNote,
      ...data,
    }; // lots of types going into this.  Casting to any for now to get it working
    if (noteAction === 'add') {
      try {
        await addNote({
          createCaseDocumentNotePayload: {
            id: note.id || undefined,
            caseId,
            documentId: document.id,
            name: note.name,
            selectedText: note.selectedText,
            editedSelectedText: data.editedSelectedText,
            annotation: note.annotation || null,
            comment: note.comment,
            date: note.date,
            people: note.people,
            issues: note.issues,
            pageNumber: note.annotation?.pageNumber || null,
            topCoordinate: note.annotation?.y || null,
          },
        }).unwrap();
        showSuccessToast('Note added successfully');
      } catch (error) {
        showErrorToast('Error adding note, please try again');
      }
    } else if (noteAction === 'edit') {
      const { name, comment, date } = note as any;
      try {
        await updateNote({
          caseNoteId: note.id,
          createCaseDocumentNotePayload: {
            name,
            caseId,
            comment,
            date,
            selectedText: note.selectedText as string,
            editedSelectedText: data.editedSelectedText,
            annotation: note.annotation as Highlight,
            pageNumber: note.annotation?.pageNumber,
            topCoordinate: note.annotation?.y,
            people: note.people,
            issues: note.issues,
          },
        }).unwrap();
      } catch (err) {
        showErrorToast('Error updating note, please try again');
      }
    }
    // update the document on save to make sure the xfdf is up to date
    await updateDocument({
      caseDocumentId: document.id,
      caseDocument: {
        ...document,
        xfdf: xfdfData as string,
      },
    }).unwrap();
    selectNote();
  }

  async function handleDelete() {
    if (currentNote) {
      if (notes?.find((note) => note.id === currentNote.id)) {
        try {
          await deleteNotes({
            noteIds: [currentNote.id],
          }).unwrap();
          showSuccessToast('Notes deleted');
        } catch (err) {
          showErrorToast('Error deleting notes, please try again');
        }
      }
    }
    selectNote();
  }

  function renderHeader() {
    return (
      <div className="flex justify-between w-full my-3">
        <div className="flex items-start gap-2">
          <Link to="/cases" className="mt-2">
            <DynamicHeroIcon
              icon="HomeIcon"
              className="w-6 h-6 hover:text-gray-900"
            />
          </Link>
          <div className="flex-col">
            <Link className="hover:underline" to={`/cases/${caseData?.id}`}>
              / {caseData?.name}
            </Link>
            / {document.name}
            <RenderActiveUsers documentId={document.id} />
          </div>
        </div>

        <div>
          <Button
            className="p-button-outlined"
            icon="pi pi-arrow-left"
            label={`Back to ${selectedNote ? 'notes' : 'documents'}`}
            onClick={handleClose}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialog
        visible={showModal}
        onHide={handleClose}
        header={renderHeader}
        headerClassName="!px-4 !py-2"
        contentClassName="!p-0"
        resizable={false}
        draggable={false}
        closable={false}
        blockScroll={true}
        maximized={true}
      >
        <div className="flex w-full h-full">
          <PdfExpressViewer
            url={url}
            notes={notes}
            currentNote={currentNote}
            selectNote={selectNote}
            setXfdfData={setXfdfData}
          />
          <NotesSidebar
            documentName={document.name}
            notes={notes}
            currentNote={currentNote}
            noteAction={noteAction}
            handleCancel={handleCancel}
            caseId={caseId}
            documentId={document.id}
            selectNote={selectNote}
            handleSubmit={submit}
            handleDelete={handleDelete}
            count={count as number}
            page={page}
            setPage={setPage}
            loading={loading}
          />
        </div>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
