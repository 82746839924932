import { CasePeople, Profile } from '../store/endpoints';
import { ProfileRoles, ProfileRolesEnum } from '../types/profile-types';

export function translateProfileRoles(role: ProfileRoles | ProfileRolesEnum) {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'caseUser':
      return 'Case User';
  }
}

export function getProfileName(profile?: Profile | CasePeople) {
  if (!profile) {
    return '';
  }
  return `${profile.firstName} ${profile.lastName}`;
}
