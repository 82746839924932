import { useAuth0 } from '@auth0/auth0-react';
import { useGetByEmailQuery } from '../store/endpoints/profile';
import { useMemo } from 'react';

const useProfile = (skip = false) => {
  // hooks
  const { user } = useAuth0();
  const {
    data: profile,
    isLoading,
    isUninitialized,
    isFetching,
    isSuccess,
    refetch,
  } = useGetByEmailQuery(
    { email: user?.email as string },
    { skip: skip || !user?.email },
  );

  const isAdmin = useMemo(() => {
    if (profile) {
      return profile?.permission?.role === 'admin';
    }
  }, [profile]);

  return {
    profile,
    isLoading,
    isUninitialized,
    isFetching,
    isSuccess,
    isAdmin,
    refetch,
  };
};

export default useProfile;
