// templates/component/IndividualCasePage.tsx

// Libraries
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Endpoints
import { useGetCaseQuery } from '../../shared/store/endpoints/cases';

// Components
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { TabPanel, TabView, TabViewTabChangeParams } from 'primereact/tabview';
import PageTransitionWrapper from '../../shared/components/PageTransitionWrapper';
import Spacer from '../../shared/components/Spacer';
import CaseIssueTab from './CaseIssueTab';
import DocumentsTab from './DocumentsTab';
import NotesTab from './NotesTab';
import PeopleTab from './PeopleTab';
import TimelineTab from './TimelineTab';
import CaseDetailHeader from './_components/CaseDetailHeader';

export default function IndividualCasePage(): JSX.Element {
  // hooks
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { data: caseData } = useGetCaseQuery({
    caseId: caseId as string,
  });

  // state
  const [activeIndex, setActiveIndex] = useState(0);

  // refs

  // methods
  /**
   * Navigates to the a tab while keeping url hash in sync.
   * We are using the URL as the source of truth and inferring
   * the active tab from the hash in the effect below.
   * @param e the event from Prime Tab view
   */
  const handleTabChange = (e: TabViewTabChangeParams) => {
    switch (e.index) {
      case 0:
        navigate('#timeline');
        break;
      case 1:
        navigate('#documents');
        break;
      case 2:
        navigate('#notes');
        break;
      case 3:
        navigate('#people');
        break;
      case 4:
        navigate('#issues');
        break;
      default:
        return '#timeline';
    }
  };

  // effects
  //When the hash changes, update the active tab
  useEffect(() => {
    switch (hash) {
      case '#timeline':
        setActiveIndex(0);
        break;
      case '#documents':
        setActiveIndex(1);
        break;
      case '#notes':
        setActiveIndex(2);
        break;
      case '#people':
        setActiveIndex(3);
        break;
      case '#issues':
        setActiveIndex(4);
        break;
      default:
        setActiveIndex(0);
    }
  }, [hash]);

  return (
    <PageTransitionWrapper>
      <Link
        to="/cases"
        className="flex items-center gap-x-2 font-medium text-gray-500 hover:text-gray-800"
      >
        <ArrowLeftIcon className="inline-block h-5 w-5" />
        Back to all cases
      </Link>
      <Spacer height="h-4" />
      <CaseDetailHeader caseData={caseData} />
      <Spacer height="h-4" />
      <section className="relative mt-2">
        <TabView
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
          className="w-full"
        >
          <TabPanel header="Timeline">
            <TimelineTab />
          </TabPanel>
          <TabPanel header="Documents">
            <DocumentsTab />
          </TabPanel>
          <TabPanel header="Notes">
            <NotesTab />
          </TabPanel>
          <TabPanel header="People">
            <PeopleTab caseId={caseId as string} />
          </TabPanel>
          <TabPanel header="Issues">
            <CaseIssueTab caseId={caseId as string} />
          </TabPanel>
        </TabView>
      </section>
      <Spacer height="h-10" />
    </PageTransitionWrapper>
  );
}
