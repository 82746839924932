import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/Button';
import DynamicHeroIcon from '../../../shared/components/DynamicHeroIcon';
import Spacer from '../../../shared/components/Spacer';

function MembershipBaseFeeCancelPage() {
  const navigate = useNavigate();
  return (
    <div className="mx-auto mt-12 w-[600px] rounded-xl bg-white py-6 px-12 shadow-4xl">
      <div className="flex items-center justify-center gap-x-4 text-red-600">
        <DynamicHeroIcon icon="XCircleIcon" className="h-12 w-12 " />
        <h1 className="text-center text-3xl font-semibold">
          Payment cancelled
        </h1>
      </div>
      <Spacer height="h-4" />
      <p>
        You can still use the free case to explore Document Elf. If you would
        like to subscribe, head over to your account and click
        <strong> Upgrade Now</strong>. If you have questions please reach out to
        help@documentelf.com.
      </p>
      <Spacer height="h-8" />
      <Button
        label="Go to My Account"
        fullWidth
        onClick={() => navigate('/account-settings')}
      />
    </div>
  );
}

export default MembershipBaseFeeCancelPage;
