import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['CaseTypes'],
  endpoints: {
    getCaseTypes: {
      providesTags: ['CaseTypes'],
    },
    updateCaseType: {
      invalidatesTags: ['CaseTypes'],
    },
    deleteCaseTypes: {
      invalidatesTags: ['CaseTypes'],
    },
    createCaseType: {
      invalidatesTags: ['CaseTypes'],
    },
  },
});

export const {
  useGetCaseTypesQuery,
  useUpdateCaseTypeMutation,
  useDeleteCaseTypesMutation,
  useCreateCaseTypeMutation,
} = enhancedApi;
