//Libraries
import { FilterMatchMode } from 'primereact/api';
import { useMemo, useState } from 'react';

//Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//Templates
import {
  peopleRoleFilterTemplate,
  peopleRoleTemplate,
} from './templates/peopleRole';
import {
  statementObtainedFilterTemplate,
  statementObtainedTemplate,
} from './templates/statementObtained';
import UpdatePeopleModal from '../UpdatePeopleModal';
import CreatePeopleButton from '../CreatePeopleButton';
import { CasePeople } from '../../../../../shared/store/endpoints';
import { useDeleteCasePeopleMutation } from '../../../../../shared/store/endpoints/case-people';
import { Button } from 'primereact/button';
import { truncatedTextWithTooltipTemplate } from '../../../../../shared/tableTemplates/truncatedTextWithTooltipTemplate';
import TableDeleteButton from '../../../../../shared/components/TableDeleteButton';
import ExportButton from '../../../../../shared/components/ExportButton';
import useToast from '../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface PeopleTableProps {
  casePeople: CasePeople[] | undefined;
}

//The shape of the data that will be displayed in the table
export interface PeopleTableData
  extends Omit<CasePeople, 'updatedOn' | 'statementObtained'> {
  name: string;
  updatedOn: Date;
  statementObtained: string;
  originalData: CasePeople;
}

/* Component starts here */
function PeopleTable({ casePeople }: PeopleTableProps): JSX.Element {
  // State
  const [filters] = useState<any>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    peopleRole: { value: null, matchMode: FilterMatchMode.CONTAINS },
    statementObtained: { value: null, matchMode: FilterMatchMode.EQUALS },
    about: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [showUpdatePeopleModal, setShowUpdatePeopleModal] = useState(false);
  const [personToEdit, setPersonToEdit] = useState<
    CasePeople | null | undefined
  >(null);

  const [deleteCasePeople, { isLoading }] = useDeleteCasePeopleMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  //This is an array of IDs of the selected CasePeople
  const [selectedPeople, setSelectedPeople] = useState<CasePeople[]>([]);

  async function handleDeletePeople() {
    const casePeopleToDeleteIds = selectedPeople.map((person) => person.id);
    try {
      await deleteCasePeople({ casePeopleIds: casePeopleToDeleteIds }).unwrap();
      showSuccessToast('People deleted successfully');
      setSelectedPeople([]);
    } catch (error) {
      showErrorToast('Error deleting people, please try again.');
    }
  }

  // Transform the incoming data to match the table filter formats
  const tableData = useMemo(() => {
    return casePeople
      ? casePeople.map((casePerson) => {
          return {
            ...casePerson,
            updatedOn: new Date(casePerson.updatedOn),
            name: `${casePerson.firstName} ${casePerson.lastName}`,
            statementObtained: casePerson.statementObtained.toString(),
            originalData: casePerson,
          };
        })
      : [];
  }, [casePeople]);

  //Templates
  const nameBodyTemplate = ({ name }: PeopleTableData) => {
    return <span className="text-lg font-medium">{name}</span>;
  };

  const handleEditButtonClick = (rowData: PeopleTableData) => {
    setPersonToEdit(rowData.originalData);
    setShowUpdatePeopleModal(true);
  };

  function header() {
    return (
      <div className="flex justify-end gap-x-6">
        <ExportButton
          selected={selectedPeople.map((p) => p.id)}
          resource="case-people"
          showAll={false}
        />
        <TableDeleteButton
          targetPluralText="people"
          deleteFunction={handleDeletePeople}
          selectedItems={selectedPeople}
          loading={isLoading}
        />
        <CreatePeopleButton />
      </div>
    );
  }

  return (
    <div className="app-datatable">
      <DataTable
        value={tableData}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={['name', 'peopleRole']}
        selection={selectedPeople}
        onSelectionChange={(e) => setSelectedPeople(e.value)}
        dataKey="id"
        emptyMessage="There are no people related to this case yet"
        header={header}
        className="p-datatable-data"
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3em' }}
        ></Column>
        <Column
          field="name"
          header="Name"
          filter
          filterPlaceholder="Search by name"
          showFilterMatchModes={false}
          body={nameBodyTemplate}
          style={{ width: '12rem' }}
        />
        <Column
          field="peopleRole"
          header="Role"
          body={peopleRoleTemplate}
          filter
          showFilterMatchModes={false}
          filterPlaceholder="Search by role"
          filterElement={peopleRoleFilterTemplate}
        />
        <Column
          field="about"
          header="About"
          filter
          showFilterMatchModes={false}
          filterPlaceholder="Search by about"
          body={({ about }) => truncatedTextWithTooltipTemplate(about, 'w-48')}
        />
        <Column
          field="statementObtained"
          header="Statement"
          filter
          showFilterMatchModes={false}
          body={statementObtainedTemplate}
          filterElement={statementObtainedFilterTemplate}
          style={{ width: '10rem' }}
        />

        <Column
          header="Action"
          body={(rowData) => (
            <Button
              label="Edit"
              className="p-button-outlined p-button-sm p-button-info"
              onClick={() => handleEditButtonClick(rowData)}
            />
          )}
        />
      </DataTable>
      {/* By unmounting this modal, we reset the form with the new personToEdit */}
      {showUpdatePeopleModal && (
        <UpdatePeopleModal
          showModal={showUpdatePeopleModal}
          setShowModal={setShowUpdatePeopleModal}
          personToEdit={personToEdit}
        />
      )}
      <Toast ref={toastRef} />
    </div>
  );
}

export default PeopleTable;
