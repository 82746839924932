//Libraries
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
//Hooks
import useProfile from '../../../shared/hooks/useProfile';
import useStorage from '../../../shared/hooks/useStorage';
//Endpoints
import { UpdateProfilePayload } from '../../../shared/store/endpoints';
import { useUpdateProfileMutation } from '../../../shared/store/endpoints/profile';
//Components
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import Button from '../../../shared/components/Button';
import Cropper from '../../../shared/components/Cropper/Cropper';
import Spacer from '../../../shared/components/Spacer';
import { ChangePhotoButton } from './ChangePhotoButton/ChangePhotoButton';

interface ProfilePhotoProps {
  showErrorToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
}

function ProfilePhoto({
  showErrorToast,
  showSuccessToast,
}: ProfilePhotoProps): JSX.Element {
  //State
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<any>(
    '/assets/default-avatar.png',
  );
  const [photoToEdit, setPhotoToEdit] = useState<any>(null);
  const { uploadFileString, getReadUrl } = useStorage();
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  //Hooks
  const { profile } = useProfile();

  const [updateProfile] = useUpdateProfileMutation();

  //Methods
  async function handleUploadPhoto(imageBlob: string) {
    if (!profile) {
      return;
    }
    setUploading(true);
    setShowPhotoModal(false);
    setProfilePhoto(imageBlob);
    setLoadingPhoto(false);
    // Photo path format: avatars/${orgId}/${userId}.jpg
    const fileName = `avatars/${profile?.organizationId}/${profile?.id}.jpg`;
    const fileType = 'image/jpeg';
    try {
      const storageFileName = await uploadFileString(
        imageBlob,
        fileName,
        fileType,
      );
      const updateProfilePayload: UpdateProfilePayload = {
        profilePhotoPath: storageFileName,
      };
      await updateProfile({
        profileId: profile.id,
        updateProfilePayload,
      }).unwrap();
      showSuccessToast('Profile photo updated successfully');
    } catch (error) {
      showErrorToast('Error updating your profile photo');
    } finally {
      setUploading(false);
    }
  }

  function handleSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e?.target &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      setPhotoToEdit(e.currentTarget.files[0]);
      setShowPhotoModal(true);
    }
  }

  async function handleEditCurrentPhoto() {
    setPhotoToEdit(profilePhoto);
    setShowPhotoModal(true);
  }

  useEffect(() => {
    async function getPhotoUrl() {
      if (profile?.profilePhotoPath) {
        const url = await getReadUrl(profile?.profilePhotoPath);
        setProfilePhoto(url);
      }
    }
    if (profile) {
      getPhotoUrl();
    }
  }, [getReadUrl, profile, showErrorToast]);

  return (
    <>
      <div className="flex h-max flex-col items-center rounded-xl  bg-gray-200 p-4 py-8 shadow-inner">
        {loadingPhoto || !profilePhoto ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Skeleton
              width="128px"
              height="128px"
              shape="circle"
              className="ring-2 ring-primary-darker"
            />
          </motion.div>
        ) : (
          <div
            className="relative flex items-center justify-center"
            style={{ width: '160px' }}
          >
            <motion.img
              src={profilePhoto}
              className={` mx-auto w-32 rounded-full shadow-lg transition-all ${
                uploading && 'blur-sm'
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
            {uploading && (
              <motion.div
                className="absolute -top-4 bottom-0 left-0 right-0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <ProgressSpinner style={{ width: '160px', height: '160px' }} />
              </motion.div>
            )}
          </div>
        )}
        <Spacer height="h-4" />
        <ChangePhotoButton handleSelectFile={handleSelectFile} />

        {/* TODO: Look into CORS issue when this is deployed */}
        <Spacer height="h-4" />
        <Button
          label="Edit current"
          className="p-button-secondary p-button-outlined"
          icon="pi pi-pencil"
          onClick={handleEditCurrentPhoto}
          fullWidth
        />
      </div>
      <Dialog
        style={{ width: '600px' }}
        header="Change your profile photo"
        visible={showPhotoModal}
        onHide={() => {
          setShowPhotoModal(false);
        }}
      >
        <Cropper
          image={photoToEdit}
          handleSave={handleUploadPhoto}
          handleCancel={() => {
            setPhotoToEdit(null);
            setShowPhotoModal(false);
          }}
        />
      </Dialog>
    </>
  );
}

export default ProfilePhoto;
