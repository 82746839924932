// Libraries
import { useMemo, useState } from 'react';

// Endpoints
import { useGetAllProfilesWithPhotoPathQuery } from '../../shared/store/endpoints/profile';

// Components
import Button from '../../shared/components/Button';
import InviteUserModal from '../../shared/components/InviteUserModal';
import PageTitle from '../../shared/components/PageTitle';
import { useGetSeatsQuery } from '../../shared/store/endpoints';
import EditSeatsModal from './components/EditSeatsModal';
import ProfileTable from './components/ProfileTable';
import { useCheckOrgSubscriptionQuery } from '../../shared/store/endpoints/stripe';
import SubscribeModal from '../../shared/components/SubscribeModal';

function UserManagementPage() {
  // hooks
  const { data: profiles, isLoading } =
    useGetAllProfilesWithPhotoPathQuery(null);
  const { data: numberOfSeats } = useGetSeatsQuery();

  // state
  const [showAddUser, setShowAddUser] = useState(false);
  const [showSeatsModal, setShowSeatsModal] = useState(false);

  //misc
  const activeProfiles = useMemo(() => {
    return profiles?.filter((profile) => profile.active);
  }, [profiles]);

  //add new user
  const { data: subscribed } = useCheckOrgSubscriptionQuery();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  function handleAddNewUser() {
    if (!subscribed) {
      setShowSubscribeModal(true);
      return;
    }
    if (activeProfiles?.length! < numberOfSeats!) {
      setShowAddUser(true);
    } else {
      // TODO: Show text about not having enough seats to add a new user in the modal
      setShowSeatsModal(true);
    }
  }

  return (
    <>
      <PageTitle text="User Management" />

      <div className="flex items-center justify-between gap-4 p-4">
        {subscribed ? (
          <div className="flex items-center gap-x-4">
            <p className="text-lg text-gray-600">
              You are using{' '}
              <strong className="font-bold">{activeProfiles?.length}</strong> of{' '}
              <strong>{numberOfSeats}</strong> paid seats
            </p>
            <Button
              label="Edit"
              className="p-button-outlined p-button-sm p-button-secondary"
              icon="pi pi-pencil"
              iconPos="right"
              onClick={() => setShowSeatsModal(true)}
            />
          </div>
        ) : (
          <p className="text-lg text-gray-800">
            You are the only user in organization.{' '}
            <span
              className="cursor cursor-pointer font-semibold text-primary-base"
              onClick={() => setShowSubscribeModal(true)}
            >
              Upgrade{' '}
            </span>
            to invite your team members!
          </p>
        )}
        <Button
          label="Add User"
          icon="pi pi-plus"
          onClick={() => handleAddNewUser()}
        />
      </div>
      <ProfileTable profiles={profiles} isLoading={isLoading} />
      <InviteUserModal showModal={showAddUser} setShowModal={setShowAddUser} />
      <EditSeatsModal
        showModal={showSeatsModal}
        setShowModal={setShowSeatsModal}
        numberOfSeats={numberOfSeats}
        activeProfiles={activeProfiles}
      />
      <SubscribeModal
        showModal={showSubscribeModal}
        setShowModal={setShowSubscribeModal}
      />
    </>
  );
}

export default UserManagementPage;
