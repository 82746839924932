import { InputText } from 'primereact/inputtext';
import { FilterOptions } from '../../types/table-types';

export function tableFilterSearchElement(
  options: FilterOptions<string>,
  placeholder = 'Search',
) {
  return (
    <InputText
      placeholder={placeholder}
      className="ui-column-filter"
      value={options.value || ''}
      onChange={(event) => options.filterCallback(event.target.value)}
    />
  );
}
