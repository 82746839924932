// Libraries
import { useEffect, useRef } from 'react';
import { FieldErrorsImpl, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// Endpoints

// Components
import Button from '../../../../shared/components/Button';
import FormInput from '../../../../shared/components/form/FormInput';
import FormInputTextarea from '../../../../shared/components/form/FormInputTextarea';
import { format, parse } from 'date-fns';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import FormMultiSelectWithSearch from '../../../../shared/components/form/FormMultiSelectWithSearch';
import useIssuesOptions from '../../../../shared/hooks/useIssuesOptions';
import usePeopleOptions from '../../../../shared/hooks/usePeopleOptions';
import { CaseDocumentNote } from '../../../../shared/store/endpoints';
import CreateCaseIssueButton from '../../CaseIssueTab/components/CreateCaseIssueButton';
import CreatePeopleButton from '../../PeopleTab/components/CreatePeopleButton';
import FormInputWithMask from '../../../../shared/components/form/FormInputWithMask';

interface CreateNoteProps {
  note: any;
  handleCancel: () => void;
  submitNote: (note: any) => void;
  cancelCopy?: string;
  loading: boolean;
  hideParentModal?: () => void;
  numberOfCaseNotes: number | undefined;
  placeholderName: string | undefined;
}

function setCreateNoteFormValues(
  placeholderName: string | undefined,
  numberOfCaseNotes: number | undefined,
  note?: CaseDocumentNote,
): CreateNoteFormValues {
  return {
    name:
      note?.name ||
      placeholderName + '_Note_' + String((numberOfCaseNotes || 0) + 1),
    comment: note?.comment || '',
    selectedText: note?.editedSelectedText || note?.selectedText || '',
    people: note?.people?.map((person) => person.id) || [],
    issues: note?.issues?.map((issue) => issue.id) || [],
    date: note?.date
      ? format(new Date(note.date), 'MM/dd/yyyy')
      : format(new Date(), 'MM/dd/yyyy'),
    time: note?.date
      ? format(new Date(note.date), 'KK:mm bbb')
      : format(new Date(), 'KK:mm bbb'),
    id: note?.id || undefined,
  };
}

export interface CreateNoteFormValues {
  name: string;
  comment: string;
  selectedText: string;
  people: string[];
  issues: number[];
  date: string;
  id?: string;
  time: string;
}

export default function CreateNote({
  note,
  handleCancel,
  submitNote,
  cancelCopy,
  loading,
  hideParentModal,
  numberOfCaseNotes,
  placeholderName,
}: CreateNoteProps): JSX.Element {
  const { caseId } = useParams() as { caseId: string };
  // hooks
  const form = useForm({
    mode: 'onChange',
    defaultValues: setCreateNoteFormValues(
      placeholderName,
      numberOfCaseNotes,
      note,
    ),
  });
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;
  const { peopleOptions } = usePeopleOptions(caseId);
  const { issuesOptions } = useIssuesOptions(caseId);

  /**
   * there is an underlying problem where when a not gets selected things are re-rendering multiple times,
   * causing the form to reset to the default values. This is a hacky solution to prevent that from happening.
   * The more permanent fix is to refactor the PDF viewer to take out the multiple re-renders.
   */
  const noteRef = useRef(note);

  const selectedTextInputRef = useRef<HTMLInputElement>(null);

  // effects
  useEffect(() => {
    if (
      noteRef.current &&
      note?.selectedText &&
      noteRef.current.selectedText !== note.selectedText
    ) {
      // change value in form if selected text is changed
      if (
        note?.selectedText &&
        note?.selectedText !== getValues('selectedText')
      ) {
        setValue('selectedText', note.selectedText);
        // if selected text is changed, focus input
        if (selectedTextInputRef.current) {
          // resizes input, needs to wait a bit so value can catch up with the render.
          setTimeout(() => selectedTextInputRef.current?.focus(), 100);
        }
      }
      noteRef.current = note;
    }
  }, [note, setValue, getValues]);

  useEffect(() => {
    // if note.id changes, reset the form
    if (note?.id !== getValues('id')) {
      reset();
    }
  }, [note?.id, reset, note, getValues]);

  function handleSubmitNote(data: CreateNoteFormValues) {
    const { date, time, selectedText, ...rest } = data;
    // combine date and time to a date object
    const updatedDate: Date = parse(
      `${date} ${time}`,
      'MM/dd/yyyy hh:mm bbb',
      new Date(),
    );
    // check to make sure it's a valid date
    if (!isNaN(updatedDate.getTime())) {
      return submitNote({
        ...rest,
        date: updatedDate,
        selectedText: note?.selectedText,
        editedSelectedText: selectedText || undefined,
      });
    } else {
      confirmDialog({
        header: 'Error saving note',
        message: 'Please enter a valid date and time',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ok',
        rejectClassName: '!hidden',
      });
    }
  }

  const renderSelectedLength = (length: number) => (
    <div className="px-3 py-2">
      <b>{length}</b> item{length > 1 ? 's' : ''} selected.
    </div>
  );

  //Template for people multiselect
  const peopleFooterTemplate = () => {
    const selectedItems = getValues('people');
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="flex justify-between">
        {renderSelectedLength(length)}
        <div className="mx-4 my-2 ">
          <CreatePeopleButton
            label="Create new person"
            className="p-button-sm p-button-secondary"
          />
        </div>
      </div>
    );
  };

  //Template for the footer of the issues multiselect
  const issuesFooterTemplate = () => {
    const selectedItems = getValues('issues');
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="flex justify-between">
        {renderSelectedLength(length)}
        <div className="mx-4 my-2 ">
          <CreateCaseIssueButton
            label="Create new issue"
            className="p-button-sm p-button-secondary"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <FormProvider {...form}>
        <form className="flex flex-col" onSubmit={handleSubmit(submitNote)}>
          <FormInput
            name="name"
            label="Note Name"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'Note name is required',
              },
            }}
          />
          {note?.selectedText && (
            <FormInputTextarea
              name="selectedText"
              label="Selected Text"
              control={control}
              errors={errors}
              inputRef={selectedTextInputRef}
            />
          )}
          <FormInputTextarea
            name="comment"
            label="Comment"
            control={control}
            errors={errors}
          />
          <div className="flex w-full gap-4">
            <FormInputWithMask
              control={control}
              errors={errors}
              name="date"
              label="Date"
              mask="99/99/9999"
            />
            <FormInputWithMask
              control={control}
              errors={errors}
              name="time"
              label="Time"
              mask="99:99 aa"
            />
          </div>
          <div></div>
          <FormMultiSelectWithSearch
            name="people"
            label="People"
            control={control as any}
            errors={
              errors as Partial<
                FieldErrorsImpl<{
                  [x: string]: any;
                }>
              >
            }
            options={peopleOptions}
            panelFooterTemplate={peopleFooterTemplate}
          />
          <FormMultiSelectWithSearch
            name="issues"
            label="Issues"
            control={control as any}
            errors={
              errors as Partial<
                FieldErrorsImpl<{
                  [x: string]: any;
                }>
              >
            }
            options={issuesOptions}
            panelFooterTemplate={issuesFooterTemplate}
          />
          <div></div>
          <div className="flex justify-center gap-2">
            <Button
              label={cancelCopy || 'Cancel'}
              className="p-button-outlined"
              onClick={handleCancel}
              type="reset"
              disabled={loading}
            />
            <Button
              label="Save note"
              onClick={handleSubmit(handleSubmitNote)}
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </FormProvider>
      <ConfirmDialog />
    </div>
  );
}
