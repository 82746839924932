// Libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Endpoints
import {
  CaseDocument,
  CaseDocumentNote,
} from '../../../shared/store/endpoints';
import { useGetAllCaseNotesQuery } from '../../../shared/store/endpoints/case-documents-notes';

//Types
import {
  LazyFilterFilters,
  LazyFilterParams,
} from '../../../shared/types/lazy-filter-params';

//Components
import CaseNotesTable from './components/CaseNotesTable';
import CreateCaseNoteModal from './components/CreateCaseNoteModal';
import useStorage from '../../../shared/hooks/useStorage';
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import PdfModal from '../_components/PdfModal';
import { FilterMatchMode } from 'primereact/api';

export function sanitizeNoteSearchFromFilter(filters: LazyFilterFilters) {
  const activeFilters = Object.entries(filters).filter(
    ([key, value]: [string, any]) => {
      return value?.value;
    },
  );
  const search = [];
  const searchBy = [];
  for (const [key, value] of activeFilters) {
    if (key === 'date') {
      search.push(value.value[0]);
      searchBy.push('dateStart');
      search.push(value.value[1]);
      searchBy.push('dateEnd');
    } else {
      search.push(value.value);
      searchBy.push(key);
    }
  }
  return {
    search: search.length ? search.join(',') : '',
    searchBy: searchBy.length ? searchBy.join(',') : '',
  };
}

export default function NotesTab(): JSX.Element {
  // hooks
  const { caseId } = useParams();
  const { getReadUrl } = useStorage();

  // filtering
  const [lazyParams, setLazyParams] = useState<LazyFilterParams>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: undefined,
    sortOrder: undefined,
    filters: {
      date: { value: '', matchMode: FilterMatchMode.CONTAINS },
      name: { value: '', matchMode: FilterMatchMode.CONTAINS },
      people: { value: '', matchMode: FilterMatchMode.CONTAINS },
      issues: { value: '', matchMode: FilterMatchMode.CONTAINS },
      document: { value: '', matchMode: FilterMatchMode.CONTAINS },
      comment: { value: '', matchMode: FilterMatchMode.CONTAINS },
    },
  });

  // gets and refetches data for the table
  const { data, isLoading } = useGetAllCaseNotesQuery(
    {
      caseId: caseId as string,
      skip: lazyParams.first,
      take: lazyParams.rows,
      orderBy: lazyParams.sortField || 'date',
      orderDir: lazyParams.sortOrder === 1 ? 'asc' : 'desc',
      search: sanitizeNoteSearchFromFilter(lazyParams.filters).search,
      searchBy: sanitizeNoteSearchFromFilter(lazyParams.filters).searchBy,
    },
    { skip: !caseId },
  );
  // state
  const [selectedNote, setSelectedNote] = useState<CaseDocumentNote>();
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const [showViewPdfModal, setShowViewPdfModal] = useState<boolean>(false);

  // refs

  // effects
  useEffect(() => {
    async function viewDocument(doc: CaseDocument) {
      const url = await getReadUrl(doc.path);
      setFileUrl(url);
      setShowViewPdfModal(true);
    }
    if (selectedNote?.document) {
      viewDocument(selectedNote.document);
    }
  }, [selectedNote?.document, getReadUrl]);

  // methods
  function createNote(note?: CaseDocumentNote) {
    if (note) {
      setSelectedNote(note);
    } else {
      // adding date of previous note as "default" date for new note per client request - DOCELF-191
      const date =
        data?.notes && data.notes.length > 0 ? data.notes[0].date : new Date();
      setSelectedNote({ date } as any);
    }
    // if note does not have a documentId, open create modal. Opening pdf handled in effect
    if (!note?.documentId) {
      setShowCreateNoteModal(true);
    }
  }

  return (
    <PageTransitionWrapper>
      <CaseNotesTable
        data={data}
        isLoading={isLoading}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        createNote={createNote}
      />
      <CreateCaseNoteModal
        showModal={showCreateNoteModal}
        onHide={() => {
          setShowCreateNoteModal(false);
          setSelectedNote(undefined);
        }}
        note={selectedNote}
        numberOfCaseNotes={data?.count}
      />
      {fileUrl && (
        <PdfModal
          showModal={showViewPdfModal}
          closeModal={() => {
            setShowViewPdfModal(false);
            setFileUrl(undefined);
            setSelectedNote(undefined);
          }}
          url={fileUrl}
          document={selectedNote?.document as CaseDocument}
          caseId={caseId as string}
          selectedNote={selectedNote}
        />
      )}
    </PageTransitionWrapper>
  );
}
