import { ReactNode } from 'react';

import { Dialog } from 'primereact/dialog';

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
  children: ReactNode;
  headerText?: string;
  closeOnEscape?: boolean;
}

export default function Modal({
  showModal,
  onHide,
  children,
  headerText,
  closeOnEscape = true,
}: ModalProps): JSX.Element {
  // todo - style this
  return (
    <Dialog
      visible={showModal}
      onHide={onHide}
      style={{ width: '600px' }}
      header={headerText}
      closeOnEscape={closeOnEscape}
    >
      {children}
    </Dialog>
  );
}
