//Libraries
import { Outlet } from 'react-router-dom';
import useProfile from '../../hooks/useProfile';
//Components
import HeaderNav from '../HeaderNav/HeaderNav';
import PageTransitionWrapper from '../PageTransitionWrapper';
/**
 * An <Outlet> is used in parent route elements to render
 * their child route elements. This allows nested UI to show up
 * when child routes are rendered. If the parent route matched exactly,
 * it will render a child index route or null if there is no index route.
 */
export default function DashboardLayout() {
  // adding check for profile here so this doesn't render when we want to redirect to register or get-started
  const { profile } = useProfile();
  if (profile) {
    return (
      <PageTransitionWrapper>
        <HeaderNav />
        <main className="container mx-auto pt-24 lg:px-0">
          <Outlet />
        </main>
      </PageTransitionWrapper>
    );
  } else {
    return <></>;
  }
}
