// Libraries
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

// Endpoints
import {
  Activity,
  Profile,
  useGetActivityMutation,
} from '../../store/endpoints';
import { useGetAllProfilesQuery } from '../../store/endpoints/profile';

// Components
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { ActivityAction, TargetResource } from '../../types/activity-types';
import ActivityListItem from '../ActivityListItem';
import FormDropdown from '../form/FormDropdown';
import Button from '../Button';
import axios from 'axios';
import useStorage from '../../hooks/useStorage';
import useToast from '../../hooks/useToast';
import { Toast } from 'primereact/toast';

export default function ActivityList(): JSX.Element {
  // hooks
  const { data: profiles } = useGetAllProfilesQuery();
  const [getActivities, { data, isLoading }] = useGetActivityMutation();
  const { saveBlob } = useStorage();
  const { toastRef, showErrorToast } = useToast();

  const {
    control,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      action: '',
      targetResource: '',
      createdById: '',
    },
  });

  // state
  const [first, setFirst] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState(false);

  // memoized
  const triggerGetActivities = useCallback(
    ({ skip, take }: { skip: number; take: number }) => {
      const { action, targetResource, createdById } = getValues();
      getActivities({
        paginatedPayload: {
          skip,
          take,
          search: {
            action,
            targetResource,
            createdById,
          },
        },
      });
    },
    [getValues, getActivities],
  );
  const userOptions = useMemo(() => {
    return (
      profiles
        ?.map((profile: Profile) => {
          return {
            label: `${profile.firstName} ${profile.lastName}`,
            value: profile.id,
          };
        })
        .sort((optionA: SelectItem, optionB: SelectItem) => {
          const A = (optionA.label || '').toUpperCase();
          const B = (optionB.label || '').toUpperCase();
          return B > A ? -1 : B < A ? 1 : 0;
        }) || []
    );
  }, [profiles]);

  const { viewData, count } = useMemo(() => {
    return {
      viewData: data?.activity || [],
      count: data?.count || 0,
    };
  }, [data]);

  // effects
  useEffect(() => {
    triggerGetActivities({
      skip: first,
      take: 10,
    });
    const watchSubscription = watch(() => {
      triggerGetActivities({
        skip: first,
        take: 10,
      });
    });
    return () => {
      watchSubscription.unsubscribe();
    };
  }, [first, triggerGetActivities, watch]);

  // methods
  function onPage(event: DataViewPageParams) {
    setFirst(event.first);
    triggerGetActivities({
      skip: event.first,
      take: event.rows,
    });
  }

  const activityDataViewRow = (activity: Activity) => {
    return <ActivityListItem activity={activity} />;
  };

  const actionOptions: SelectItemOptionsType = [
    { label: 'Create', value: ActivityAction.create },
    { label: 'Update', value: ActivityAction.update },
    { label: 'Delete', value: ActivityAction.delete },
  ];

  const targetResourceOptions: SelectItemOptionsType = [
    { label: 'User', value: TargetResource.user },
    { label: 'Admin Issue', value: TargetResource.adminIssue },
    { label: 'Case', value: TargetResource.case },
    { label: 'Case Issue', value: TargetResource.caseIssue },
    { label: 'Case Document', value: TargetResource.caseDocument },
    { label: 'Case People', value: TargetResource.casePeople },
    { label: 'Case Type', value: TargetResource.caseType },
  ];

  async function downloadActivityLog() {
    setIsDownloading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/export`,
        {
          params: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          responseType: 'blob',
        },
      );
      // get filename from response headers
      const filename = res.headers['content-disposition'].split('filename=')[1];
      saveBlob(res.data, filename);
    } catch (err) {
      showErrorToast('Error downloading activity PDF, please try again');
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <>
      <div className="flex items-start gap-4">
        <FormDropdown
          errors={errors}
          name="action"
          control={control}
          options={actionOptions}
          placeholder="Action"
          watch={watch}
          showClear
        />
        <FormDropdown
          errors={errors}
          name="targetResource"
          control={control}
          options={targetResourceOptions}
          placeholder="Resource"
          watch={watch}
          showClear
        />
        <FormDropdown
          errors={errors}
          name="createdById"
          control={control}
          placeholder="User"
          options={userOptions}
          watch={watch}
          showClear
        />
        <Button
          label="Download Activity PDF"
          icon="pi pi-download"
          onClick={downloadActivityLog}
          loading={isDownloading}
        />
      </div>
      <DataView
        value={viewData}
        itemTemplate={activityDataViewRow}
        layout="list"
        loading={isLoading}
        rows={10}
        totalRecords={count}
        onPage={onPage}
        first={first}
        lazy
        paginator
      />
      <Toast ref={toastRef} />
    </>
  );
}
