//Libraries
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components
import Button from '../../../shared/components/Button';
import Spacer from '../../../shared/components/Spacer';
import SuccessCheckmark from '../../../shared/components/SuccessCheckmark/SuccessCheckmark';
import useProfile from '../../../shared/hooks/useProfile';
//Endpoints
import {
  useCheckOrgSubscriptionQuery,
  useGetCheckoutSessionInfoQuery,
} from '../../../shared/store/endpoints/stripe';

export default function MembershipSuccessPage() {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const {
    data: numberOfSeats,
    isLoading,
    refetch: refetchSeats,
  } = useGetCheckoutSessionInfoQuery({
    sessionId: sessionId as string,
  });

  const {
    data: isSubscribed,
    isLoading: isSubscribedLoading,
    refetch,
  } = useCheckOrgSubscriptionQuery();

  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const clearTimer = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    if (isSubscribed) {
      clearTimer();
      refetchSeats();
    } else {
      clearTimer();
      timerRef.current = setInterval(() => {
        refetch();
      }, 5000);
    }
    return () => {
      clearTimer();
    };
  }, [isSubscribed, isSubscribedLoading, refetch, refetchSeats]);

  return (
    <>
      <SuccessCheckmark />
      <Spacer height="h-6" />
      <motion.main
        className="relative z-0 mx-auto mt-[11rem] w-[600px] rounded-xl bg-gradient-to-tr from-primary-darker to-primary-base p-8 text-center text-white shadow-3xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'spring' }}
      >
        <Spacer height="h-4" />
        <h1 className="text-4xl font-bold">Payment successful</h1>
        <motion.div className="ml-12 mt-8 text-left" layout>
          {!isSubscribed ? (
            <>
              <motion.p
                className="font-blue-200 mb-4 text-xl font-medium"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <span>{profile?.organization.name}'s</span> subscription is
                pending
              </motion.p>
              <p className="font-medium text-blue-200">
                {' '}
                We are processing your payment. This may take a few minutes.
              </p>
            </>
          ) : (
            !isLoading &&
            numberOfSeats && (
              <>
                <motion.p
                  className="font-blue-200 mb-4 text-xl font-medium"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <span>{profile?.organization.name}</span> now has{' '}
                  {numberOfSeats} paid member{numberOfSeats! < 2 ? '' : 's'} 🎉
                </motion.p>
                <p className="font-medium text-blue-200">
                  {' '}
                  Now your team can enjoy:
                </p>
                <p className="font-medium text-blue-200"> • Unlimited cases</p>
                <p className="font-medium text-blue-200">
                  {' '}
                  • Unlimited document scanning
                </p>
              </>
            )
          )}
        </motion.div>
        <Spacer height="h-12" />

        {numberOfSeats! > 2 ? (
          <Button
            label="Add more members"
            className="p-button-success"
            fullWidth
            onClick={() => navigate('/user-management')}
          />
        ) : (
          <Button
            label="Go to my dashboard"
            className="p-button-success"
            loading={!isSubscribed}
            fullWidth
            onClick={() => navigate('/')}
          />
        )}
      </motion.main>
    </>
  );
}
