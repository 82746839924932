// libraries
import { SelectItemOptionsType } from 'primereact/selectitem';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// data
import { CaseStatus } from '../../../../shared/types/case';

// endpoints
import { useGetCaseTypesQuery } from '../../../../shared/store/endpoints/case-type-endpoints';

import { useGetAllProfilesWithPhotoPathQuery } from '../../../../shared/store/endpoints/profile';

// components
import FormInput from '../../../../shared/components/form/FormInput';
import FormInputTextarea from '../../../../shared/components/form/FormInputTextarea';
import FormMultiSelect from '../../../../shared/components/form/FormMultiSelect';
import FormSelect from '../../../../shared/components/form/FormSelect';
import FormSelectButton from '../../../../shared/components/form/FormSelectButton';
import { rules } from '../../../../shared/helpers/validators/form-validators';

export interface CaseFormValues {
  name: string;
  description: string | null;
  type: number | null;
  caseUserIds: string[];
  caseStatus: CaseStatus | 'open' | 'closed' | undefined;
}

export function setCaseFormValues(setValues?: Partial<CaseFormValues>) {
  let values: CaseFormValues = {
    name: '',
    description: '',
    type: null,
    caseUserIds: [],
    caseStatus: CaseStatus.open,
  };

  if (setValues) {
    values = {
      ...values,
      ...setValues,
    };
  }

  return values;
}

const CaseForm = () => {
  const { data: caseTypes } = useGetCaseTypesQuery();
  const { data: orgProfiles } = useGetAllProfilesWithPhotoPathQuery(null);

  const orgProfileOptions = useMemo(() => {
    return (
      orgProfiles?.map((profile) => ({
        label: `${profile.firstName} ${profile.lastName}`,
        value: profile.id,
      })) || []
    );
  }, [orgProfiles]);

  const { control, formState } = useFormContext();

  const caseTypeOptions = useMemo(() => {
    return (
      caseTypes?.map((caseType) => ({
        value: caseType.id,
        label: caseType.name,
      })) || []
    );
  }, [caseTypes]);

  const caseStatusOptions: SelectItemOptionsType = [
    {
      label: 'Open',
      value: CaseStatus.open,
    },
    {
      label: 'Closed',
      value: CaseStatus.closed,
    },
  ];

  return (
    <>
      <FormInput
        label="Case Name"
        name="name"
        control={control}
        rules={{ required: rules.required('Case name is required') }}
        errors={formState.errors}
      />
      <FormInputTextarea
        label="Case Description"
        name="description"
        control={control}
        errors={formState.errors}
      />
      <FormSelect
        label="Case Type"
        placeholder="Select a case type...."
        name="type"
        options={caseTypeOptions}
        control={control}
        errors={formState.errors}
      />
      <FormMultiSelect
        label="Assign Case User(s)"
        placeholder="Select case user(s)"
        name="caseUserIds"
        options={orgProfileOptions}
        control={control}
        errors={formState.errors}
        filter
        filterBy="label"
      />
      <FormSelectButton
        label="Case Status"
        errors={formState.errors}
        name="caseStatus"
        options={caseStatusOptions}
        control={control}
      />
    </>
  );
};

export default CaseForm;
