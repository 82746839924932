import { Controller } from 'react-hook-form';
import { SelectButton } from 'primereact/selectbutton';
import classNames from 'classnames';
import FormError from '../SharedFormComponents/FormError';
import FormLabel from '../SharedFormComponents/FormLabel';

const FormSelectButton = ({
  control,
  errors,
  name,
  label,
  rules,
  inputRef,
  placeholder,
  options,
  showClear,
  className,
  optionLabel,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  options: any;
  rules?: any;
  inputRef?: any;
  placeholder?: string;
  showClear?: boolean;
  className?: string;
  optionLabel?: string;
}) => {
  return (
    <div className={classNames(className, 'w-max')}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <SelectButton
                id={name + '-input'}
                className={classNames({
                  'p-invalid': fieldState.error,
                })}
                {...field}
                ref={inputRef}
                options={options}
                optionLabel={optionLabel}
              />
            </>
          );
        }}
      />
      <FormError errors={errors} name={name} />
    </div>
  );
};
export default FormSelectButton;
