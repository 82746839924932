import { ErrorMessage } from '@hookform/error-message';

interface FormErrorProps {
  errors: any;
  name: string;
}

export default function FormError({
  errors,
  name,
}: FormErrorProps): JSX.Element {
  return (
    <div className="mx-2 mb-1 flex h-4 w-full items-baseline justify-end pr-4">
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <small className="p-error p-d-block font-bold">{message}</small>
        )}
      />
    </div>
  );
}
