import { useGetCasePeopleQuery } from '../../../shared/store/endpoints/case-people';
import PeopleTable from './components/PeopleTable';
interface PeopleTabProps {
  caseId: string;
}

export default function PeopleTab({ caseId }: PeopleTabProps): JSX.Element {
  const { data: casePeople } = useGetCasePeopleQuery({
    caseId: caseId,
  });
  return <PeopleTable casePeople={casePeople} />;
}
