import { Dropdown } from 'primereact/dropdown';

import { FilterOptions } from '../../../../../shared/types/table-types';
import { translateProfileRoles } from '../../../../../shared/utility-fns/profile-fns';

export function profileTableRoleFilterElement(options: FilterOptions<string>) {
  const dropdownOptions = [
    { label: translateProfileRoles('admin'), value: 'admin' },
    { label: translateProfileRoles('caseUser'), value: 'caseUser' },
  ];
  return (
    <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(event) => options.filterCallback(event.value, options.index)}
      placeholder="Select a Role"
      className="p-column-filter"
    />
  );
}
