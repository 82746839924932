import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Chip } from 'primereact/chip';
import ProfileChipWithAvatar from '../../../../../shared/components/ProfileChipWithAvatar';
import { Profile } from '../../../../../shared/store/endpoints';
import { TableData } from '../CaseTable';

export const caseProfilesBodyTemplate = (rowData: TableData) => {
  return rowData.caseProfiles.length < 2 ? (
    <section className="flex gap-2" key={rowData.id}>
      {rowData.caseProfiles.map((profile: any, i: any) => {
        if (profile)
          return (
            <div key={profile.id}>
              <Chip
                label={profile.firstName}
                className="whitespace-nowrap"
                image={profile.profilePhotoPath || ''}
              />
            </div>
          );
        else return <></>;
      })}
    </section>
  ) : (
    <>
      <AvatarGroup>
        {rowData.caseProfiles.slice(0, 5).map((profile: any, i: any) => {
          if (profile)
            return (
              <>
                <Avatar
                  key={profile.id}
                  image={profile.profilePhotoPath}
                  label={profile.firstName[0] + profile.lastName[0]}
                  shape="circle"
                  size="large"
                  // Using a tooltip that is in the same row.
                  data-pr-tooltip={profile.firstName + ' ' + profile.lastName}
                  className="tooltipTarget cursor-context-menu"
                />
              </>
            );
          else return <></>;
        })}
        {rowData.caseProfiles.length > 5 && (
          <Avatar
            label={`+${rowData.caseProfiles.length - 5}`}
            shape="circle"
            size="large"
          />
        )}
      </AvatarGroup>
    </>
  );
};

export const caseProfilesItemTemplate = (profile: Profile) => {
  if (profile) {
    return <ProfileChipWithAvatar profile={profile} />;
  }
};

//Fix this
export const selectedProfileTemplate = (profile: Profile) => {
  if (profile) {
    return <ProfileChipWithAvatar profile={profile} />;
  }

  return <div>Select a profile</div>;
};
