// Libraries
import { FormProvider, useForm } from 'react-hook-form';
import { ProfileRoles } from '../../types/profile-types';

// Endpoints
import { useInviteUserMutation } from '../../store/endpoints/profile';

// Components
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import UserForm, {
  setUserFormValues,
  UserFormValues,
} from '../../../pages/UserManagementPage/components/UserForm';
import useToast from '../../hooks/useToast';
import FormModalFooter from '../form/FormModalFooter';

interface InviteUserModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function InviteUserModal({
  showModal,
  setShowModal,
}: InviteUserModalProps): JSX.Element {
  // hooks
  const [inviteUser, { isLoading }] = useInviteUserMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  const form = useForm({
    mode: 'onChange',
    defaultValues: setUserFormValues(),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;

  // methods
  async function addNewUser(data: UserFormValues) {
    const { firstName, lastName, email, role } = data;
    const inviteUserPayload = {
      firstName,
      lastName,
      email,
      role: role as ProfileRoles,
    };
    try {
      await inviteUser({
        inviteUserPayload,
      }).unwrap();
      showSuccessToast(`An email invitation was sent to ${firstName}`);
      closeModal();
    } catch (err) {
      showErrorToast('Error inviting user, please try again');
    }
  }

  function closeModal() {
    setShowModal(false);
    reset();
  }

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="Add User"
        visible={showModal}
        onHide={closeModal}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            disabled={!isValid}
            loading={isLoading}
            onSubmit={handleSubmit(addNewUser)}
            submitText="Invite User"
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(addNewUser)}>
            <UserForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
