import { Profile } from '../../store/endpoints';

interface ProfileChipWithAvatarProps {
  profile: Profile;
}

const ProfileChipWithAvatar = ({ profile }: ProfileChipWithAvatarProps) => {
  return (
    <div className="flex gap-x-2" key={profile.id}>
      {profile.profilePhotoPath && (
        <img
          alt={profile.firstName}
          src={profile.profilePhotoPath}
          className="inline-block rounded-full"
          width="24"
          height="24"
        />
      )}
      <div>
        {profile.firstName} {profile.lastName}
      </div>
    </div>
  );
};

export default ProfileChipWithAvatar;
