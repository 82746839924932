// Components
import { Button } from 'primereact/button';

interface FormModalFooterProps {
  setShowModal: (value: boolean) => void;
  onSubmit: () => void;
  disabled: boolean;
  loading?: boolean;
  cancelText?: string;
  submitText?: string;
  resetForm: () => void;
}

export default function FormModalFooter({
  setShowModal,
  onSubmit,
  disabled,
  loading,
  cancelText = 'Cancel',
  submitText = 'Submit',
  resetForm,
}: FormModalFooterProps): JSX.Element {
  // methods
  return (
    <div>
      <Button
        className="p-button-outlined"
        label={cancelText}
        disabled={loading}
        onClick={() => {
          resetForm();
          setShowModal(false);
        }}
      />
      <Button
        label={submitText}
        onClick={onSubmit}
        disabled={disabled}
        loading={loading}
      />
    </div>
  );
}
