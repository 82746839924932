import { Dropdown } from 'primereact/dropdown';
import { PeopleTableData } from '../PeopleTable';
import { capitalize } from 'lodash';

const roles = [
  { label: 'Party', value: 'party' },
  { label: 'Witness', value: 'witness' },
];

export const peopleRoleTemplate = ({ peopleRole }: PeopleTableData) => {
  return <span>{capitalize(peopleRole)}</span>;
};

const onPeopleRoleFilterChange = (event: any, options: any) => {
  options.filterCallback(event.value);
};

export const peopleRoleFilterTemplate = (filterOptions: any) => {
  return (
    <Dropdown
      value={filterOptions.value}
      options={roles}
      onChange={(e) => {
        onPeopleRoleFilterChange(e, filterOptions);
      }}
      placeholder="Select a role"
      className="p-column-filter"
    />
  );
};
