// Libraries
import { FilterMatchMode } from 'primereact/api';
import { useMemo, useState } from 'react';

// Endpoints
import { useDeleteCaseTypesMutation } from '../../../../../../shared/store/endpoints/case-type-endpoints';

// Data
import { FilterState } from '../../../../../../shared/types/table-types';
import { CaseType } from '../../../../../../shared/store/endpoints';

// Components
import TableDeleteButton from '../../../../../../shared/components/TableDeleteButton';
import UpdateCaseTypeModal from '../UpdateCaseTypeModal';
import {
  tableDateTemplate,
  tableRangeFilterTemplate,
} from '../../../../../../shared/components/table-templates/table-date-template';
import CreateCaseTypeButton from '../CreateCaseTypeButton';
import { tableFilterSearchElement } from '../../../../../../shared/components/table-templates/searchFilterElement';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import useToast from '../../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface CaseTypeData extends Omit<CaseType, 'createdOn'> {
  // Must be type Date to work with Prime's date filtering functions
  createdOn: Date;
}
interface CaseTypeTableProps {
  caseTypes: CaseType[] | undefined;
}

export default function CaseTypeTable({
  caseTypes,
}: CaseTypeTableProps): JSX.Element {
  // state
  const [filters] = useState<FilterState>({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdOn: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const [caseTypeToUpdate, setCaseTypeToUpdate] = useState<CaseType | null>(
    null,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selected, setSelected] = useState<CaseType[]>([]);

  // hooks
  const [deleteCaseTypes, { isLoading }] = useDeleteCaseTypesMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  const tableData = useMemo(() => {
    if (!caseTypes) {
      return [];
    }
    const data: CaseTypeData[] = caseTypes.map((caseType) => {
      return {
        ...caseType,
        createdOn: new Date(caseType.createdOn),
      };
    });
    return data;
  }, [caseTypes]);

  // methods
  function handleUpdateButtonClick(target: CaseType) {
    setCaseTypeToUpdate(target);
    setShowUpdateModal(true);
  }

  async function handleDeleteCaseTypes() {
    const caseTypeIds = selected.map((caseType) => caseType.id.toString());
    try {
      await deleteCaseTypes({ caseTypeIds }).unwrap();
      setSelected([]);
      showSuccessToast('Case types successfully deleted');
    } catch (error) {
      showErrorToast('Error deleting case types');
    }
  }

  return (
    <div className="app-datatable">
      <DataTable
        value={tableData}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sortField="createdOn"
        selection={selected}
        onSelectionChange={(e) => setSelected(e.value)}
        filters={filters as DataTableFilterMeta}
        filterDisplay="menu"
        dataKey="id"
        emptyMessage="There are no case types related to this case yet"
        header={
          <div className="flex justify-end gap-4">
            <TableDeleteButton
              targetPluralText="case types"
              deleteFunction={handleDeleteCaseTypes}
              selectedItems={selected}
              loading={isLoading}
            />
            <CreateCaseTypeButton />
          </div>
        }
        className="p-datatable-data"
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3em' }}
        ></Column>
        <Column
          key="createdOn"
          field="createdOn"
          header="Date Added"
          dataType="date"
          body={(CaseType: CaseType) => tableDateTemplate(CaseType.createdOn)}
          filterElement={tableRangeFilterTemplate}
          filter
          showFilterMatchModes={false}
        />
        <Column
          key="name"
          field="name"
          header="Case Type Name"
          sortable
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search name')
          }
          showFilterMatchModes={false}
        />
        <Column
          key="description"
          field="description"
          header="Description"
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search description')
          }
          showFilterMatchModes={false}
        />
        <Column
          header="Action"
          body={(rowData) => (
            <Button
              label="Edit"
              icon="pi pi-pencil"
              className="p-button-outlined p-button-secondary"
              onClick={() => handleUpdateButtonClick(rowData)}
            />
          )}
        />
      </DataTable>
      {showUpdateModal && (
        <UpdateCaseTypeModal
          showModal={showUpdateModal}
          setShowModal={setShowUpdateModal}
          caseTypeToEdit={caseTypeToUpdate}
          onHide={() => setShowUpdateModal(false)}
        />
      )}
      <Toast ref={toastRef} />
    </div>
  );
}
