import { capitalize } from 'lodash';

/**
 * @param label - The label to display
 * @param value - The value for the color of the chip: true = green, false = red
 */
interface TrueFalseChipProps {
  label: string;
  value: boolean;
  className?: string;
}
export default function TrueFalseChip({
  label,
  value,
  className,
}: TrueFalseChipProps): JSX.Element {
  return (
    <div
      className={`w-max rounded-lg py-[2px] px-4 font-medium ${
        value ? 'bg-green-300 text-green-800' : 'bg-red-300 text-red-900'
      } ${className}`}
    >
      {capitalize(label)}
    </div>
  );
}
