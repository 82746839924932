// Libraries
import { FormProvider, useForm } from 'react-hook-form';

// Endpoints
import {
  CaseType,
  CaseTypePayload,
} from '../../../../../../shared/store/endpoints';
import { useUpdateCaseTypeMutation } from '../../../../../../shared/store/endpoints/case-type-endpoints';

// Components
import { Dialog } from 'primereact/dialog';
import FormModalFooter from '../../../../../../shared/components/form/FormModalFooter';
import CaseTypeForm, {
  setCaseTypeFormValues,
  CaseTypeFormValues,
} from '../CaseTypeForm';
import useToast from '../../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface UpdateCaseTypeModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  caseTypeToEdit: CaseType | null | undefined;
  onHide: () => void;
}

export default function UpdateCaseTypeModal({
  showModal,
  setShowModal,
  caseTypeToEdit,
  onHide,
}: UpdateCaseTypeModalProps): JSX.Element {
  // hooks
  const [updateCaseType, { isLoading }] = useUpdateCaseTypeMutation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseTypeFormValues({
      name: caseTypeToEdit?.name,
      description: caseTypeToEdit?.description || undefined,
    }),
  });
  const { toastRef, showSuccessToast, showErrorToast } = useToast();
  const { reset, handleSubmit } = form;

  // methods
  async function submit(data: CaseTypeFormValues) {
    if (caseTypeToEdit) {
      const { name, description } = data;
      const caseTypePayload: CaseTypePayload = {
        name,
        description,
      };
      try {
        await updateCaseType({
          caseTypeId: caseTypeToEdit.id.toString(),
          caseTypePayload,
        }).unwrap();
        showSuccessToast('Case type successfully updated');
        closeModal();
      } catch (err) {
        showErrorToast('Error updating case type, please try again');
      }
    }
  }

  function closeModal() {
    reset();
    setShowModal(false);
  }

  return (
    <>
      <Dialog
        header="Update Issue"
        visible={showModal}
        onHide={() => {
          setShowModal(false);
          onHide();
        }}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            onSubmit={handleSubmit(submit)}
            disabled={!form.formState.isValid}
            loading={isLoading}
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submit)}>
            <CaseTypeForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
