import { apiSlice as api } from './api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHello: build.query<GetHelloApiResponse, GetHelloApiArg>({
      query: () => ({ url: `/` }),
    }),
    inviteUser: build.mutation<InviteUserApiResponse, InviteUserApiArg>({
      query: (queryArg) => ({
        url: `/auth/invite-user`,
        method: 'POST',
        body: queryArg.inviteUserPayload,
      }),
    }),
    getAllProfiles: build.query<
      GetAllProfilesApiResponse,
      GetAllProfilesApiArg
    >({
      query: () => ({ url: `/profile` }),
    }),
    getUserProfiles: build.query<
      GetUserProfilesApiResponse,
      GetUserProfilesApiArg
    >({
      query: () => ({ url: `/profile/user-profiles` }),
    }),
    getAdminProfiles: build.query<
      GetAdminProfilesApiResponse,
      GetAdminProfilesApiArg
    >({
      query: () => ({ url: `/profile/admin-profiles` }),
    }),
    getByEmail: build.query<GetByEmailApiResponse, GetByEmailApiArg>({
      query: (queryArg) => ({
        url: `/profile/get-by-email`,
        params: { email: queryArg.email },
      }),
    }),
    createProfile: build.mutation<
      CreateProfileApiResponse,
      CreateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/create-profile`,
        method: 'POST',
        body: queryArg.createProfilePayload,
      }),
    }),
    updateProfile: build.mutation<
      UpdateProfileApiResponse,
      UpdateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/update/${queryArg.profileId}`,
        method: 'PUT',
        body: queryArg.updateProfilePayload,
      }),
    }),
    recordLastLogin: build.mutation<
      RecordLastLoginApiResponse,
      RecordLastLoginApiArg
    >({
      query: () => ({ url: `/profile/record-last-login`, method: 'PUT' }),
    }),
    setProfileActiveStatus: build.mutation<
      SetProfileActiveStatusApiResponse,
      SetProfileActiveStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/set-profile-active-status/${queryArg.profileId}`,
        method: 'PUT',
        params: { activeStatus: queryArg.activeStatus },
      }),
    }),
    getActivity: build.mutation<GetActivityApiResponse, GetActivityApiArg>({
      query: (queryArg) => ({
        url: `/activity/get-activity`,
        method: 'POST',
        body: queryArg.paginatedPayload,
      }),
    }),
    exportActivity: build.query<
      ExportActivityApiResponse,
      ExportActivityApiArg
    >({
      query: () => ({ url: `/activity/export` }),
    }),
    updateOrganization: build.mutation<
      UpdateOrganizationApiResponse,
      UpdateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/update/${queryArg.orgId}`,
        method: 'PUT',
        body: queryArg.updateOrganizationPayload,
      }),
    }),
    generateReadUrl: build.query<
      GenerateReadUrlApiResponse,
      GenerateReadUrlApiArg
    >({
      query: (queryArg) => ({ url: `/storage/read-url/${queryArg.fileName}` }),
    }),
    generateWriteUrl: build.query<
      GenerateWriteUrlApiResponse,
      GenerateWriteUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/write-url`,
        params: { fileName: queryArg.fileName, fileType: queryArg.fileType },
      }),
    }),
    getCases: build.query<GetCasesApiResponse, GetCasesApiArg>({
      query: () => ({ url: `/case` }),
    }),
    createCase: build.mutation<CreateCaseApiResponse, CreateCaseApiArg>({
      query: (queryArg) => ({
        url: `/case`,
        method: 'POST',
        body: queryArg.casePayload,
      }),
    }),
    getCase: build.query<GetCaseApiResponse, GetCaseApiArg>({
      query: (queryArg) => ({ url: `/case/${queryArg.caseId}` }),
    }),
    updateCase: build.mutation<UpdateCaseApiResponse, UpdateCaseApiArg>({
      query: (queryArg) => ({
        url: `/case/update/${queryArg.caseId}`,
        method: 'PUT',
        body: queryArg.casePayload,
      }),
    }),
    getCaseTypes: build.query<GetCaseTypesApiResponse, GetCaseTypesApiArg>({
      query: () => ({ url: `/case-type` }),
    }),
    createCaseType: build.mutation<
      CreateCaseTypeApiResponse,
      CreateCaseTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/case-type`,
        method: 'POST',
        body: queryArg.caseTypePayload,
      }),
    }),
    deleteCaseTypes: build.mutation<
      DeleteCaseTypesApiResponse,
      DeleteCaseTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-type`,
        method: 'DELETE',
        params: { caseTypeIds: queryArg.caseTypeIds },
      }),
    }),
    updateCaseType: build.mutation<
      UpdateCaseTypeApiResponse,
      UpdateCaseTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/case-type/update/${queryArg.caseTypeId}`,
        method: 'PUT',
        body: queryArg.caseTypePayload,
      }),
    }),
    createCasePeople: build.mutation<
      CreateCasePeopleApiResponse,
      CreateCasePeopleApiArg
    >({
      query: (queryArg) => ({
        url: `/case-people`,
        method: 'POST',
        body: queryArg.createCasePeoplePayload,
      }),
    }),
    deleteCasePeople: build.mutation<
      DeleteCasePeopleApiResponse,
      DeleteCasePeopleApiArg
    >({
      query: (queryArg) => ({
        url: `/case-people/delete`,
        method: 'PUT',
        params: { casePeopleIds: queryArg.casePeopleIds },
      }),
    }),
    updateCasePeople: build.mutation<
      UpdateCasePeopleApiResponse,
      UpdateCasePeopleApiArg
    >({
      query: (queryArg) => ({
        url: `/case-people/update/${queryArg.casePersonId}`,
        method: 'PUT',
        body: queryArg.updateCasePeoplePayload,
      }),
    }),
    exportCasePeople: build.query<
      ExportCasePeopleApiResponse,
      ExportCasePeopleApiArg
    >({
      query: (queryArg) => ({
        url: `/case-people/export`,
        params: {
          selected: queryArg.selected,
          format: queryArg.format,
          caseId: queryArg.caseId,
        },
      }),
    }),
    getCasePeople: build.query<GetCasePeopleApiResponse, GetCasePeopleApiArg>({
      query: (queryArg) => ({ url: `/case-people/${queryArg.caseId}` }),
    }),
    getAllCaseDocuments: build.query<
      GetAllCaseDocumentsApiResponse,
      GetAllCaseDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/all/${queryArg.caseId}`,
        params: {
          skip: queryArg.skip,
          take: queryArg.take,
          search: queryArg.search,
          searchBy: queryArg.searchBy,
          orderBy: queryArg.orderBy,
          orderDir: queryArg.orderDir,
        },
      }),
    }),
    addCaseDocument: build.mutation<
      AddCaseDocumentApiResponse,
      AddCaseDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/add`,
        method: 'POST',
        body: queryArg.createCaseDocumentPayload,
      }),
    }),
    addMultipleCaseDocuments: build.mutation<
      AddMultipleCaseDocumentsApiResponse,
      AddMultipleCaseDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/add-multiple`,
        method: 'POST',
        body: queryArg.createMultipleCaseDocumentsPayload,
      }),
    }),
    updateCaseDocument: build.mutation<
      UpdateCaseDocumentApiResponse,
      UpdateCaseDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/update/${queryArg.caseDocumentId}`,
        method: 'PUT',
        body: queryArg.caseDocument,
      }),
    }),
    exportCaseDocuments: build.mutation<
      ExportCaseDocumentsApiResponse,
      ExportCaseDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/export`,
        method: 'POST',
        body: queryArg.caseDocumentExportPayload,
      }),
    }),
    deleteCaseDocuments: build.mutation<
      DeleteCaseDocumentsApiResponse,
      DeleteCaseDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents`,
        method: 'DELETE',
        params: { caseDocumentIds: queryArg.caseDocumentIds },
      }),
    }),
    exportCaseDocumentWithNotes: build.query<
      ExportCaseDocumentWithNotesApiResponse,
      ExportCaseDocumentWithNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/export-with-notes/${queryArg.caseId}/${queryArg.documentId}`,
      }),
    }),
    logDocumentEditActivity: build.query<
      LogDocumentEditActivityApiResponse,
      LogDocumentEditActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/log-edit-activity/${queryArg.caseDocumentId}`,
      }),
    }),
    checkDocumentEditLog: build.mutation<
      CheckDocumentEditLogApiResponse,
      CheckDocumentEditLogApiArg
    >({
      query: (queryArg) => ({
        url: `/case-documents/check-for-edit-activity/${queryArg.caseDocumentId}`,
        method: 'POST',
      }),
    }),
    getAllCaseNotes: build.query<
      GetAllCaseNotesApiResponse,
      GetAllCaseNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/all/${queryArg.caseId}`,
        params: {
          skip: queryArg.skip,
          take: queryArg.take,
          search: queryArg.search,
          searchBy: queryArg.searchBy,
          orderBy: queryArg.orderBy,
          orderDir: queryArg.orderDir,
        },
      }),
    }),
    getAllCaseNotesForDocument: build.query<
      GetAllCaseNotesForDocumentApiResponse,
      GetAllCaseNotesForDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/all/${queryArg.caseId}/document/${queryArg.documentId}`,
        params: { skip: queryArg.skip, take: queryArg.take },
      }),
    }),
    addCaseNote: build.mutation<AddCaseNoteApiResponse, AddCaseNoteApiArg>({
      query: (queryArg) => ({
        url: `/case-notes/add`,
        method: 'POST',
        body: queryArg.createCaseDocumentNotePayload,
      }),
    }),
    getAllCaseNotesForTimeline: build.query<
      GetAllCaseNotesForTimelineApiResponse,
      GetAllCaseNotesForTimelineApiArg
    >({
      query: (queryArg) => ({ url: `/case-notes/timeline/${queryArg.caseId}` }),
    }),
    updateCaseNote: build.mutation<
      UpdateCaseNoteApiResponse,
      UpdateCaseNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/update/${queryArg.caseNoteId}`,
        method: 'PUT',
        body: queryArg.createCaseDocumentNotePayload,
      }),
    }),
    deleteCaseNotes: build.mutation<
      DeleteCaseNotesApiResponse,
      DeleteCaseNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/notes`,
        method: 'DELETE',
        params: { noteIds: queryArg.noteIds },
      }),
    }),
    exportCaseNotes: build.query<
      ExportCaseNotesApiResponse,
      ExportCaseNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/export`,
        params: {
          caseId: queryArg.caseId,
          selected: queryArg.selected,
          format: queryArg.format,
          timezone: queryArg.timezone,
        },
      }),
    }),
    exportAllCaseNotes: build.query<
      ExportAllCaseNotesApiResponse,
      ExportAllCaseNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-notes/export/all`,
        params: {
          caseId: queryArg.caseId,
          format: queryArg.format,
          timezone: queryArg.timezone,
          search: queryArg.search,
          searchBy: queryArg.searchBy,
          orderBy: queryArg.orderBy,
          orderDir: queryArg.orderDir,
        },
      }),
    }),
    createCaseIssue: build.mutation<
      CreateCaseIssueApiResponse,
      CreateCaseIssueApiArg
    >({
      query: (queryArg) => ({
        url: `/case-issue`,
        method: 'POST',
        body: queryArg.createCaseIssuePayload,
      }),
    }),
    deleteCaseIssues: build.mutation<
      DeleteCaseIssuesApiResponse,
      DeleteCaseIssuesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-issue`,
        method: 'DELETE',
        params: { caseIssueIds: queryArg.caseIssueIds },
      }),
    }),
    getIssuesByCaseId: build.query<
      GetIssuesByCaseIdApiResponse,
      GetIssuesByCaseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/case-issue/by-case-id/${queryArg.caseId}`,
      }),
    }),
    updateCaseIssue: build.mutation<
      UpdateCaseIssueApiResponse,
      UpdateCaseIssueApiArg
    >({
      query: (queryArg) => ({
        url: `/case-issue/update/${queryArg.issueId}`,
        method: 'PUT',
        body: queryArg.updateCaseIssuePayload,
      }),
    }),
    exportCaseIssues: build.query<
      ExportCaseIssuesApiResponse,
      ExportCaseIssuesApiArg
    >({
      query: (queryArg) => ({
        url: `/case-issue/export`,
        params: {
          selected: queryArg.selected,
          format: queryArg.format,
          caseId: queryArg.caseId,
        },
      }),
    }),
    getAdminIssues: build.query<
      GetAdminIssuesApiResponse,
      GetAdminIssuesApiArg
    >({
      query: () => ({ url: `/admin-issue` }),
    }),
    createAdminIssue: build.mutation<
      CreateAdminIssueApiResponse,
      CreateAdminIssueApiArg
    >({
      query: (queryArg) => ({
        url: `/admin-issue`,
        method: 'POST',
        body: queryArg.createAdminIssuePayload,
      }),
    }),
    deleteAdminIssues: build.mutation<
      DeleteAdminIssuesApiResponse,
      DeleteAdminIssuesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin-issue`,
        method: 'DELETE',
        params: { adminIssueIds: queryArg.adminIssueIds },
      }),
    }),
    updateAdminIssue: build.mutation<
      UpdateAdminIssueApiResponse,
      UpdateAdminIssueApiArg
    >({
      query: (queryArg) => ({
        url: `/admin-issue/update/${queryArg.issueId}`,
        method: 'PUT',
        body: queryArg.updateAdminIssuePayload,
      }),
    }),
    createStripeCheckoutSession: build.query<
      CreateStripeCheckoutSessionApiResponse,
      CreateStripeCheckoutSessionApiArg
    >({
      query: () => ({ url: `/stripe/get-checkout-session` }),
    }),
    handleIncomingEvents: build.mutation<
      HandleIncomingEventsApiResponse,
      HandleIncomingEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/webhook`,
        method: 'POST',
        headers: { 'stripe-signature': queryArg['stripe-signature'] },
      }),
    }),
    checkOrgSubscription: build.query<
      CheckOrgSubscriptionApiResponse,
      CheckOrgSubscriptionApiArg
    >({
      query: () => ({ url: `/stripe/check-org-subscription` }),
    }),
    getSeats: build.query<GetSeatsApiResponse, GetSeatsApiArg>({
      query: () => ({ url: `/stripe/get-seats` }),
    }),
    getCheckoutSessionInfo: build.query<
      GetCheckoutSessionInfoApiResponse,
      GetCheckoutSessionInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/get-checkout-session-info`,
        params: { sessionId: queryArg.sessionId },
      }),
    }),
    updateSeats: build.mutation<UpdateSeatsApiResponse, UpdateSeatsApiArg>({
      query: (queryArg) => ({
        url: `/stripe/update-seats`,
        method: 'PUT',
        params: { numberOfSeats: queryArg.numberOfSeats },
      }),
    }),
    createUpdateBillingSession: build.query<
      CreateUpdateBillingSessionApiResponse,
      CreateUpdateBillingSessionApiArg
    >({
      query: () => ({ url: `/stripe/update-billing` }),
    }),
    markDocumentProcessed: build.mutation<
      MarkDocumentProcessedApiResponse,
      MarkDocumentProcessedApiArg
    >({
      query: (queryArg) => ({
        url: `/aws-lambda/mark-document-processed/${queryArg.id}`,
        method: 'POST',
        body: queryArg.documentProcessedPayload,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as endpoints };
export type GetHelloApiResponse = /** status 200  */ string;
export type GetHelloApiArg = void;
export type InviteUserApiResponse = /** status 201  */ Profile;
export type InviteUserApiArg = {
  inviteUserPayload: InviteUserPayload;
};
export type GetAllProfilesApiResponse = /** status 200  */
  | Profile[]
  | /** status 201  */ Profile[];
export type GetAllProfilesApiArg = void;
export type GetUserProfilesApiResponse = /** status 200  */
  | Profile[]
  | /** status 201  */ Profile[];
export type GetUserProfilesApiArg = void;
export type GetAdminProfilesApiResponse = /** status 200  */
  | Profile[]
  | /** status 201  */ Profile[];
export type GetAdminProfilesApiArg = void;
export type GetByEmailApiResponse = /** status 200  */
  | Profile
  | /** status 201  */ Profile;
export type GetByEmailApiArg = {
  email: string;
};
export type CreateProfileApiResponse = /** status 201  */ Profile;
export type CreateProfileApiArg = {
  createProfilePayload: CreateProfilePayload;
};
export type UpdateProfileApiResponse = /** status 200  */ Profile;
export type UpdateProfileApiArg = {
  profileId: string;
  updateProfilePayload: UpdateProfilePayload;
};
export type RecordLastLoginApiResponse = /** status 200  */ object;
export type RecordLastLoginApiArg = void;
export type SetProfileActiveStatusApiResponse = /** status 200  */ Profile;
export type SetProfileActiveStatusApiArg = {
  profileId: string;
  activeStatus: boolean;
};
export type GetActivityApiResponse =
  /** status 201  */ PaginatedActivityResponse;
export type GetActivityApiArg = {
  paginatedPayload: PaginatedPayload;
};
export type ExportActivityApiResponse = unknown;
export type ExportActivityApiArg = void;
export type UpdateOrganizationApiResponse = /** status 200  */ Organization;
export type UpdateOrganizationApiArg = {
  orgId: string;
  updateOrganizationPayload: UpdateOrganizationPayload;
};
export type GenerateReadUrlApiResponse = /** status 200  */
  | string
  | /** status 201  */ string;
export type GenerateReadUrlApiArg = {
  fileName: string;
};
export type GenerateWriteUrlApiResponse = /** status 200  */
  | string
  | /** status 201  */ string;
export type GenerateWriteUrlApiArg = {
  fileName: string;
  fileType: string;
};
export type GetCasesApiResponse = /** status 200  */
  | Case[]
  | /** status 201  */ Case[];
export type GetCasesApiArg = void;
export type CreateCaseApiResponse = /** status 201  */ Case;
export type CreateCaseApiArg = {
  casePayload: CasePayload;
};
export type GetCaseApiResponse = /** status 200  */
  | Case
  | /** status 201  */ Case;
export type GetCaseApiArg = {
  caseId: string;
};
export type UpdateCaseApiResponse = /** status 200  */ Case;
export type UpdateCaseApiArg = {
  caseId: string;
  casePayload: CasePayload;
};
export type GetCaseTypesApiResponse = /** status 200  */
  | CaseType[]
  | /** status 201  */ CaseType[];
export type GetCaseTypesApiArg = void;
export type CreateCaseTypeApiResponse = /** status 201  */ CaseType;
export type CreateCaseTypeApiArg = {
  caseTypePayload: CaseTypePayload;
};
export type DeleteCaseTypesApiResponse = /** status 200  */ object;
export type DeleteCaseTypesApiArg = {
  caseTypeIds: string[];
};
export type UpdateCaseTypeApiResponse = /** status 200  */ CaseType;
export type UpdateCaseTypeApiArg = {
  caseTypeId: string;
  caseTypePayload: CaseTypePayload;
};
export type CreateCasePeopleApiResponse = /** status 201  */ CasePeople;
export type CreateCasePeopleApiArg = {
  createCasePeoplePayload: CreateCasePeoplePayload;
};
export type DeleteCasePeopleApiResponse = /** status 200  */ object;
export type DeleteCasePeopleApiArg = {
  casePeopleIds: string[];
};
export type UpdateCasePeopleApiResponse = /** status 200  */ CasePeople;
export type UpdateCasePeopleApiArg = {
  casePersonId: string;
  updateCasePeoplePayload: UpdateCasePeoplePayload;
};
export type ExportCasePeopleApiResponse = unknown;
export type ExportCasePeopleApiArg = {
  selected: string[];
  format: string;
  caseId: string;
};
export type GetCasePeopleApiResponse = /** status 200  */
  | CasePeople[]
  | /** status 201  */ CasePeople[];
export type GetCasePeopleApiArg = {
  caseId: string;
};
export type GetAllCaseDocumentsApiResponse = /** status 200  */
  | CaseDocumentsWithCount
  | /** status 201  */ CaseDocumentsWithCount;
export type GetAllCaseDocumentsApiArg = {
  caseId: string;
  skip: number;
  take: number;
  search: string;
  searchBy: string;
  orderBy: string;
  orderDir: string;
};
export type AddCaseDocumentApiResponse = /** status 201  */ CaseDocument;
export type AddCaseDocumentApiArg = {
  createCaseDocumentPayload: CreateCaseDocumentPayload;
};
export type AddMultipleCaseDocumentsApiResponse =
  /** status 201  */ CaseDocumentsWithCount;
export type AddMultipleCaseDocumentsApiArg = {
  createMultipleCaseDocumentsPayload: CreateMultipleCaseDocumentsPayload;
};
export type UpdateCaseDocumentApiResponse = /** status 200  */ CaseDocument;
export type UpdateCaseDocumentApiArg = {
  caseDocumentId: string;
  caseDocument: CaseDocument;
};
export type ExportCaseDocumentsApiResponse =
  /** status 201  */ CaseDocumentExport[];
export type ExportCaseDocumentsApiArg = {
  caseDocumentExportPayload: CaseDocumentExportPayload;
};
export type DeleteCaseDocumentsApiResponse = unknown;
export type DeleteCaseDocumentsApiArg = {
  caseDocumentIds: string[];
};
export type ExportCaseDocumentWithNotesApiResponse = unknown;
export type ExportCaseDocumentWithNotesApiArg = {
  caseId: string;
  documentId: string;
  timezone: string;
};
export type LogDocumentEditActivityApiResponse = /** status 200  */
  | CaseDocumentEditLog[]
  | /** status 201  */ CaseDocumentEditLog[];
export type LogDocumentEditActivityApiArg = {
  caseDocumentId: string;
};
export type CheckDocumentEditLogApiResponse = /** status 201  */ number;
export type CheckDocumentEditLogApiArg = {
  caseDocumentId: string;
};
export type GetAllCaseNotesApiResponse =
  /** status 200  */ CaseDocumentNotesWithCount;
export type GetAllCaseNotesApiArg = {
  caseId: string;
  skip?: number;
  take?: number;
  search?: string;
  searchBy?: string;
  orderBy?: string;
  orderDir?: string;
};
export type GetAllCaseNotesForDocumentApiResponse = /** status 200  */
  | undefined
  | /** status 201  */ CaseDocumentNotesWithCount;
export type GetAllCaseNotesForDocumentApiArg = {
  caseId: string;
  documentId: string;
  skip: number;
  take: number;
};
export type AddCaseNoteApiResponse = /** status 201  */ CaseDocumentNote;
export type AddCaseNoteApiArg = {
  createCaseDocumentNotePayload: CreateCaseDocumentNotePayload;
};
export type GetAllCaseNotesForTimelineApiResponse = /** status 200  */
  | undefined
  | /** status 201  */ CaseDocumentNotesWithCount;
export type GetAllCaseNotesForTimelineApiArg = {
  caseId: string;
};
export type UpdateCaseNoteApiResponse = /** status 200  */
  | object
  | /** status 201  */ CaseDocumentNote;
export type UpdateCaseNoteApiArg = {
  caseNoteId: string;
  createCaseDocumentNotePayload: CreateCaseDocumentNotePayload;
};
export type DeleteCaseNotesApiResponse = /** status 200  */ object;
export type DeleteCaseNotesApiArg = {
  noteIds: string[];
};
export type ExportCaseNotesApiResponse = unknown;
export type ExportCaseNotesApiArg = {
  caseId: string;
  selected: string[];
  format: string;
  timezone: string;
};
export type ExportAllCaseNotesApiResponse = unknown;
export type ExportAllCaseNotesApiArg = {
  caseId: string;
  format: string;
  timezone: string;
  search: string;
  searchBy: string;
  orderBy: string;
  orderDir: string;
};
export type CreateCaseIssueApiResponse = /** status 201  */ CaseIssue;
export type CreateCaseIssueApiArg = {
  createCaseIssuePayload: CreateCaseIssuePayload;
};
export type DeleteCaseIssuesApiResponse = /** status 200  */ object;
export type DeleteCaseIssuesApiArg = {
  caseIssueIds: string[];
};
export type GetIssuesByCaseIdApiResponse = /** status 200  */
  | CaseIssue[]
  | /** status 201  */ CaseIssue[];
export type GetIssuesByCaseIdApiArg = {
  caseId: string;
};
export type UpdateCaseIssueApiResponse = /** status 200  */ CaseIssue;
export type UpdateCaseIssueApiArg = {
  issueId: string;
  updateCaseIssuePayload: UpdateCaseIssuePayload;
};
export type ExportCaseIssuesApiResponse = unknown;
export type ExportCaseIssuesApiArg = {
  selected: string[];
  format: string;
  caseId: string;
};
export type GetAdminIssuesApiResponse = /** status 200  */
  | AdminIssue[]
  | /** status 201  */ AdminIssue[];
export type GetAdminIssuesApiArg = void;
export type CreateAdminIssueApiResponse = /** status 201  */ AdminIssue;
export type CreateAdminIssueApiArg = {
  createAdminIssuePayload: CreateAdminIssuePayload;
};
export type DeleteAdminIssuesApiResponse = /** status 200  */ object;
export type DeleteAdminIssuesApiArg = {
  adminIssueIds: string[];
};
export type UpdateAdminIssueApiResponse = /** status 200  */ AdminIssue;
export type UpdateAdminIssueApiArg = {
  issueId: string;
  updateAdminIssuePayload: UpdateAdminIssuePayload;
};
export type CreateStripeCheckoutSessionApiResponse = /** status 200  */
  | string
  | /** status 201  */ string;
export type CreateStripeCheckoutSessionApiArg = void;
export type HandleIncomingEventsApiResponse = unknown;
export type HandleIncomingEventsApiArg = {
  'stripe-signature': string;
};
export type CheckOrgSubscriptionApiResponse = /** status 200  */
  | boolean
  | /** status 201  */ boolean;
export type CheckOrgSubscriptionApiArg = void;
export type GetSeatsApiResponse = /** status 200  */
  | number
  | /** status 201  */ number;
export type GetSeatsApiArg = void;
export type GetCheckoutSessionInfoApiResponse = /** status 200  */
  | number
  | /** status 201  */ number;
export type GetCheckoutSessionInfoApiArg = {
  sessionId: string;
};
export type UpdateSeatsApiResponse = /** status 200  */
  | undefined
  | /** status 201  */ number;
export type UpdateSeatsApiArg = {
  numberOfSeats: number;
};
export type CreateUpdateBillingSessionApiResponse = /** status 200  */
  | string
  | /** status 201  */ string;
export type CreateUpdateBillingSessionApiArg = void;
export type MarkDocumentProcessedApiResponse = /** status 201  */ object;
export type MarkDocumentProcessedApiArg = {
  id: string;
  documentProcessedPayload: DocumentProcessedPayload;
};
export type Permission = {
  role: 'admin' | 'caseUser';
  id: number;
};
export type Organization = {
  id: string;
  createdOn: string;
  updatedOn: string;
  createdById: string;
  updatedById: string;
  name: string;
  showSampleCase: boolean;
};
export type Profile = {
  id: string;
  createdOn: string;
  updatedOn: string;
  lastLogin: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  permission: Permission;
  permissionId: number;
  addressId: string;
  phoneNumber: string;
  profilePhotoPath: string;
  organizationId: string;
  organization: Organization;
  cases?: object[];
  _count: {
    cases?: number;
  };
};
export type InviteUserPayload = {
  role: 'admin' | 'caseUser';
  email: string;
  firstName: string;
  lastName: string;
};
export type CreateProfilePayload = {
  email: string;
  firstName: string;
  lastName: string;
  permissionId?: number;
  organizationName?: string;
  organizationId?: string;
};
export type UpdateProfilePayload = {
  role?: 'admin' | 'caseUser';
  profilePhotoPath?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  active?: boolean;
  lastLogin?: string;
  phoneNumber?: string;
  permissionId?: number;
};
export type Case = {
  caseStatus: 'open' | 'closed';
  id: string;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string | null;
  name: string;
  description: string | null;
  caseTypeId: number;
  caseProfiles: Profile[];
  organizationId: string;
  people: object[];
  documents: object[];
  isSampleCase: boolean;
};
export type CasePeople = {
  peopleRole: 'party' | 'witness';
  id: string;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string | null;
  caseId: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  notes: object[];
  statementObtained: boolean;
  about: string | null;
  issues: object[];
  addressId: string;
  address: object;
  phoneNumber: string | null;
};
export type CaseType = {
  id: number;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string | null;
  name: string;
  description: string | null;
  cases: object[];
  organizationId: string;
};
export type CaseIssue = {
  id: number;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string | null;
  name: string;
  description: string;
  isDefault: boolean;
  caseId: string;
};
export type AdminIssue = {
  id: number;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string | null;
  name: string;
  description: string;
  isDefault: boolean;
  organizationId: string;
};
export type CaseDocumentEditLog = {
  id: string;
  caseDocumentId: string;
  caseDocument?: CaseDocument;
  profileId: string;
  profile: {
    firstName?: string;
    lastName?: string;
  };
  lastActiveDate: string;
};
export type CaseDocument = {
  reviewStatus: 'processing' | 'failed' | 'todo' | 'inProgress' | 'complete';
  id: string;
  caseId: string;
  createdOn: string;
  createdById: string;
  updatedOn: string;
  updatedById: string;
  deleted: string;
  name: string;
  path: string;
  documentType: string;
  isExhibit: boolean;
  exhibitNumber: string;
  xfdf: string;
  editLog?: CaseDocumentEditLog[];
};
export type Activity = {
  id: string;
  createdOn: string;
  createdById: string;
  caseId: string | null;
  profileId: string | null;
  casePeopleId: string | null;
  caseTypeId: number | null;
  caseIssueId: number | null;
  adminIssueId: number | null;
  caseDocumentId: string | null;
  caseDocumentNoteId: string | null;
  targetResource:
    | 'user'
    | 'case'
    | 'casePeople'
    | 'caseType'
    | 'caseIssue'
    | 'adminIssue'
    | 'caseDocument';
  action: 'create' | 'update' | 'delete';
  organizationId: string;
  profile?: Profile;
  case?: Case;
  casePeople?: CasePeople;
  caseType?: CaseType;
  caseIssue?: CaseIssue;
  adminIssue?: AdminIssue;
  caseDocument?: CaseDocument;
  createdBy?: Profile;
  caseDocumentNote?: object;
};
export type PaginatedActivityResponse = {
  activity: Activity[];
  count: number;
};
export type PaginatedPayload = {
  skip: number;
  take: number;
  orderBy?: string;
  orderDir?: object;
  search: object;
};
export type UpdateOrganizationPayload = {
  name?: string;
  showSampleCase?: boolean;
};
export type CasePayload = {
  caseStatus: 'open' | 'closed';
  name: string;
  description?: string;
  caseTypeId?: number;
  caseUserIds?: string[];
};
export type CaseTypePayload = {
  name: string;
  description: string | null;
};
export type CreateCasePeoplePayload = {
  peopleRole: 'party' | 'witness';
  caseId: string;
  firstName: string;
  lastName: string;
  statementObtained: boolean;
  about?: string;
};
export type UpdateCasePeoplePayload = {
  peopleRole?: 'party' | 'witness';
  firstName?: string;
  lastName?: string;
  statementObtained?: boolean;
  about?: string;
};
export type CaseDocumentsWithCount = {
  count: number;
  documents: CaseDocument[];
};
export type CreateCaseDocumentPayload = {
  caseId: string;
  name: string;
  path: string;
  isExhibit: boolean;
  exhibitNumber?: string;
  xfdf?: string;
};
export type CaseDocumentPayload = {
  name: string;
  path: string;
  id: string;
};
export type CreateMultipleCaseDocumentsPayload = {
  caseId: string;
  documents: CaseDocumentPayload[];
};
export type CaseDocumentExport = {
  downloadUrl: string;
  originalPath: string;
};
export type CaseDocumentExportPayload = {
  caseId: string;
  documents: CaseDocument[];
  appendNotes: boolean;
};
export type CaseDocumentNote = {
  id: string;
  createdById: string;
  createdOn: string;
  updatedById: string;
  updatedOn: string;
  deleted: string;
  name: string;
  selectedText: string | null;
  editedSelectedText: string | null;
  annotation: string;
  documentId: string | null;
  document: CaseDocument | null;
  caseId: string;
  comment: string | null;
  date: string | null;
  people: CasePeople[];
  issues: CaseIssue[];
  pageNumber: number | null;
  topCoordinate: number | null;
};
export type CaseDocumentNotesWithCount = {
  count: number;
  notes: CaseDocumentNote[];
};
export type Highlight = {
  contents: string;
  height: number;
  id: string;
  pageNumber: number;
  quads: object[];
  width: number;
  strokeColor: object;
  x: number;
  y: number;
};
export type CreateCaseDocumentNotePayload = {
  id?: string;
  selectedText?: string;
  editedSelectedText?: string;
  annotation?: Highlight;
  documentId?: string;
  people?: string[];
  document?: CaseDocument;
  issues?: number[];
  pageNumber?: number;
  topCoordinate?: number;
  name: string;
  caseId: string;
  comment: string;
  date: string;
};
export type CreateCaseIssuePayload = {
  caseId: string;
  name: string;
  description: string;
};
export type UpdateCaseIssuePayload = {
  name: string;
  description: string;
};
export type CreateAdminIssuePayload = {
  name: string;
  description: string;
};
export type UpdateAdminIssuePayload = {
  name: string;
  description: string;
};
export type DocumentProcessedPayload = {
  orgId: string;
  caseId: string;
  path: string;
};
export const {
  useGetHelloQuery,
  useInviteUserMutation,
  useGetAllProfilesQuery,
  useGetUserProfilesQuery,
  useGetAdminProfilesQuery,
  useGetByEmailQuery,
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useRecordLastLoginMutation,
  useSetProfileActiveStatusMutation,
  useGetActivityMutation,
  useExportActivityQuery,
  useUpdateOrganizationMutation,
  useGenerateReadUrlQuery,
  useGenerateWriteUrlQuery,
  useGetCasesQuery,
  useCreateCaseMutation,
  useGetCaseQuery,
  useUpdateCaseMutation,
  useGetCaseTypesQuery,
  useCreateCaseTypeMutation,
  useDeleteCaseTypesMutation,
  useUpdateCaseTypeMutation,
  useCreateCasePeopleMutation,
  useDeleteCasePeopleMutation,
  useUpdateCasePeopleMutation,
  useExportCasePeopleQuery,
  useGetCasePeopleQuery,
  useGetAllCaseDocumentsQuery,
  useAddCaseDocumentMutation,
  useAddMultipleCaseDocumentsMutation,
  useUpdateCaseDocumentMutation,
  useExportCaseDocumentsMutation,
  useDeleteCaseDocumentsMutation,
  useExportCaseDocumentWithNotesQuery,
  useLogDocumentEditActivityQuery,
  useCheckDocumentEditLogMutation,
  useGetAllCaseNotesQuery,
  useGetAllCaseNotesForDocumentQuery,
  useAddCaseNoteMutation,
  useGetAllCaseNotesForTimelineQuery,
  useUpdateCaseNoteMutation,
  useDeleteCaseNotesMutation,
  useExportCaseNotesQuery,
  useExportAllCaseNotesQuery,
  useCreateCaseIssueMutation,
  useDeleteCaseIssuesMutation,
  useGetIssuesByCaseIdQuery,
  useUpdateCaseIssueMutation,
  useExportCaseIssuesQuery,
  useGetAdminIssuesQuery,
  useCreateAdminIssueMutation,
  useDeleteAdminIssuesMutation,
  useUpdateAdminIssueMutation,
  useCreateStripeCheckoutSessionQuery,
  useHandleIncomingEventsMutation,
  useCheckOrgSubscriptionQuery,
  useGetSeatsQuery,
  useGetCheckoutSessionInfoQuery,
  useUpdateSeatsMutation,
  useCreateUpdateBillingSessionQuery,
  useMarkDocumentProcessedMutation,
} = injectedRtkApi;
