import { TabView, TabPanel, TabViewTabChangeParams } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PageSubtitle from '../../shared/components/PageSubtitle';
import PageTitle from '../../shared/components/PageTitle';
// import AdminIssueTab from './components/AdminIssueTab';
import CaseTypeTab from './components/CaseTypeTab';

function AdminTemplatesPage() {
  // hook
  const { hash } = useLocation();
  const navigate = useNavigate();

  // state
  const [activeIndex, setActiveIndex] = useState(0);

  // methods
  /**
   * Navigates to the a tab while keeping url hash in sync.
   * We are using the URL as the source of truth and inferring
   * the active tab from the hash in the effect below.
   * @param e the event from Prime Tab view
   */
  const handleTabChange = (e: TabViewTabChangeParams) => {
    switch (e.index) {
      case 0:
        navigate('#case-types');
        break;
      case 1:
        navigate('#issues');
        break;
      default:
        return '#case-types';
    }
  };

  // effects
  //When the hash changes, update the active tab
  useEffect(() => {
    if (!hash) {
      navigate('#case-types');
      setActiveIndex(0);
    } else {
      switch (hash) {
        case '#case-types':
          setActiveIndex(0);
          break;
        case '#issues':
          setActiveIndex(1);
          break;
        default:
          setActiveIndex(0);
      }
    }
  }, [hash, navigate]);

  return (
    <div>
      <PageTitle text="Templates" />
      <PageSubtitle text="Speed up your document review by setting up default lists for case types and issues that you commonly encounter in your law practice." />
      <TabView
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        className="w-full"
      >
        <TabPanel header="Case Types">
          <CaseTypeTab />
        </TabPanel>
        {/* Removing for the time being per customer's request */}
        {/* <TabPanel header="Issues">
          <AdminIssueTab />
        </TabPanel> */}
      </TabView>
    </div>
  );
}

export default AdminTemplatesPage;
