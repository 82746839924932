//Libraries
import { useAuth0 } from '@auth0/auth0-react';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

//Components
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import Button from '../../shared/components/Button/Button';

//Helpers
import Spacer from '../../shared/components/Spacer';
import useProfile from '../../shared/hooks/useProfile';
import useToast from '../../shared/hooks/useToast';
import { useUpdateProfileMutation } from '../../shared/store/endpoints/profile';
import {
  useCheckOrgSubscriptionQuery,
  useLazyCreateStripeCheckoutSessionQuery,
  useLazyCreateUpdateBillingSessionQuery,
} from '../../shared/store/endpoints/stripe';
import { ProfileRoles } from '../../shared/types/profile-types';
import { translateProfileRoles } from '../../shared/utility-fns/profile-fns';
import ChangeRoleModal from './ChangeRoleModal';
import ProfileForm, {
  ProfileFormValues,
  setProfileFormValues,
} from './ProfileForm';
import ProfilePhoto from './ProfilePhoto/ProfilePhoto';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { useUpdateOrganizationMutation } from '../../shared/store/endpoints/organization';

export default function ProfilePage() {
  //Hooks
  const { user } = useAuth0();
  const { profile, isAdmin, isLoading: isProfileFetchLoading } = useProfile();
  const [updateProfile, { isLoading: isProfileUpdateLoading }] =
    useUpdateProfileMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();
  // State
  const [showModal, setShowModal] = useState(false);

  //Form state
  const form = useForm({
    mode: 'onChange',
    defaultValues: setProfileFormValues({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: user?.email,
      phoneNumber: profile?.phoneNumber || '',
      role: capitalize(profile?.permission.role as string),
    }),
  });

  const { handleSubmit } = form;

  async function handleSaveProfileChanges(data: ProfileFormValues) {
    if (!profile) {
      return;
    }
    const { firstName, lastName, phoneNumber } = data;
    try {
      await updateProfile({
        profileId: profile.id,
        updateProfilePayload: {
          firstName,
          lastName,
          phoneNumber,
        },
      }).unwrap();
      showSuccessToast('Profile updated successfully');
    } catch (error) {
      showErrorToast('Error updating profile');
    }
  }

  async function changeShowSampleCase(value: boolean) {
    if (!profile) {
      return;
    }
    try {
      await updateOrganization({
        orgId: profile.organization.id,
        updateOrganizationPayload: {
          showSampleCase: value,
        },
      }).unwrap();
      showSuccessToast('Profile updated successfully');
    } catch (error) {
      showErrorToast('Error updating profile');
    }
  }

  //Stripe checkout
  const [getUrl] = useLazyCreateStripeCheckoutSessionQuery();

  async function handleCheckout() {
    const url = await getUrl().unwrap();
    window.location.replace(url);
  }

  //Subscription check
  const { data: subscribed } = useCheckOrgSubscriptionQuery();

  const [showCancelModal, setShowCancelModal] = useState(false);

  //Update billing
  const [getUpdateBillingUrl] = useLazyCreateUpdateBillingSessionQuery();

  async function handleUpdateBilling() {
    const url = await getUpdateBillingUrl().unwrap();
    window.location.replace(url);
  }

  return (
    <div className="mt-4 flex flex-col items-center">
      <header className="flex w-[600px] flex-col gap-y-2">
        <h1 className="text-2xl font-bold">My Profile</h1>
        <p className="text-gray-500">
          Note: some settings can only be changed by the application admin.
        </p>
        <Divider />
      </header>
      {!subscribed && (
        <section className="border-border-gray-200 w-[600px] rounded-lg bg-gradient-to-tr from-primary-darker to-primary-base p-8 text-white shadow-xl">
          <h2 className="mb-8 text-3xl font-bold">Unlock your membership</h2>
          <p className="font-semibold text-blue-200"> • Unlimited cases</p>
          <p className="font-semibold text-blue-200"> • Unlimited documents</p>
          <p className="font-semibold text-blue-200">
            • $58/month • $25/month per user
          </p>

          <Spacer height="h-8" />
          <Button
            label="Upgrade now"
            onClick={() => handleCheckout()}
            className="p-button-success shadow-lg"
            icon="pi pi-unlock"
            fullWidth
          />
        </section>
      )}
      <main className="mt-8 flex w-[600px] gap-x-8 rounded-lg border border-gray-200 bg-gray-100 p-8 shadow-xl">
        {/* Left side */}
        <ProfilePhoto
          showErrorToast={showErrorToast}
          showSuccessToast={showSuccessToast}
        />
        {/* Right side */}
        <div className="flex w-2/3 flex-col gap-y-2">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(handleSaveProfileChanges)}>
              <ProfileForm />
            </form>
          </FormProvider>

          <h3 className="font-medium">Role</h3>
          <div className="flex items-center gap-x-4">
            <div className="max-w-max rounded-lg bg-orange-200 px-2 py-[2px] font-medium text-orange-800">
              {translateProfileRoles(profile?.permission.role as ProfileRoles)}
            </div>
            {isAdmin && (
              <Button
                label="Edit my role"
                onClick={() => setShowModal(true)}
                className="p-button-text p-button-secondary p-button-sm"
                icon="pi pi-pencil"
              />
            )}
          </div>
          <Spacer height="h-2" />
          {isAdmin && (
            <>
              <h3 className="font-medium">Membership</h3>
              <div className="flex items-center gap-x-2">
                <div
                  className={`max-w-max rounded-lg px-2 py-[2px] font-medium ${
                    subscribed
                      ? 'bg-green-200 text-green-900'
                      : 'bg-gray-300 text-gray-800'
                  }`}
                >
                  {subscribed ? 'Active' : 'Inactive'}
                </div>
                <Button
                  label="Cancel"
                  onClick={() => setShowCancelModal(true)}
                  className="p-button-text p-button-secondary p-button-sm"
                  icon="pi pi-trash"
                />
                <Button
                  label="Edit billing"
                  onClick={() => handleUpdateBilling()}
                  className="p-button-text p-button-secondary p-button-sm"
                  icon="pi pi-pencil"
                />
              </div>
              <Spacer height="h-4" />
            </>
          )}

          <h3 className="font-medium">Show Sample Case</h3>
          <div className="flex flex-col gap-x-2">
            <InputSwitch
              checked={profile?.organization?.showSampleCase}
              onChange={(e) => changeShowSampleCase(e.value)}
            />
          </div>
          <Spacer height="h-4" />
          <Button
            label="Save Changes"
            onClick={handleSubmit(handleSaveProfileChanges)}
            fullWidth
            loading={isProfileFetchLoading || isProfileUpdateLoading}
          />
        </div>
      </main>

      <Toast ref={toastRef} />
      <Spacer height="h-20" />
      <ChangeRoleModal
        showModal={showModal}
        setShowModal={setShowModal}
        profile={profile}
      />
      <Dialog
        header="Cancel membership"
        visible={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        style={{ width: '400px' }}
      >
        <p>
          Are you sure you want to cancel? If so reach out to{' '}
          <strong>support@documentelf.com</strong> and we'll get you taken care
          of. Thanks!
        </p>
        <Spacer height="h-4" />
      </Dialog>
    </div>
  );
}
