// Libraries
import { FormProvider, useForm } from 'react-hook-form';

// Endpoints
import { useCreateCaseTypeMutation } from '../../../../../../shared/store/endpoints/case-type-endpoints';

// Components
import FormModalFooter from '../../../../../../shared/components/form/FormModalFooter';
import { Dialog } from 'primereact/dialog';
import CaseTypeForm, {
  setCaseTypeFormValues,
  CaseTypeFormValues,
} from '../CaseTypeForm';
import useToast from '../../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface CreateCaseTypeModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function CreateCaseTypeModal({
  showModal,
  setShowModal,
}: CreateCaseTypeModalProps): JSX.Element {
  // hooks
  const [createCaseType, { isLoading }] = useCreateCaseTypeMutation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseTypeFormValues(),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // methods
  async function addNewIssue(data: CaseTypeFormValues) {
    try {
      await createCaseType({
        caseTypePayload: {
          ...data,
        },
      }).unwrap();
      showSuccessToast('Case type created successfully');
      closeModal();
    } catch (err) {
      showErrorToast('Error creating type, please try again');
    }
  }

  function closeModal() {
    reset();
    setShowModal(false);
  }

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="Add Case Type"
        visible={showModal}
        onHide={closeModal}
        footer={
          <FormModalFooter
            resetForm={reset}
            setShowModal={setShowModal}
            disabled={!isValid}
            onSubmit={handleSubmit(addNewIssue)}
            submitText="Submit"
            loading={isLoading}
          />
        }
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(addNewIssue)}>
            <CaseTypeForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
