// Libraries
import { useFormContext } from 'react-hook-form';

// Data
import { rules } from '../../../../../shared/helpers/validators/form-validators';

// Components
import FormInput from '../../../../../shared/components/form/FormInput';
import FormInputTextarea from '../../../../../shared/components/form/FormInputTextarea';

export interface CaseIssueFormValues {
  name: string;
  description: string;
}

export function setCaseIssueFormValues(
  setValues?: Partial<CaseIssueFormValues>,
) {
  let values: CaseIssueFormValues = {
    name: '',
    description: '',
  };
  if (setValues) {
    values = {
      ...values,
      ...setValues,
    };
  }

  return values;
}

export default function CaseIssueForm(): JSX.Element {
  // hooks
  const { control, formState } = useFormContext();

  // methods
  return (
    <>
      <FormInput
        label="Issue Name"
        name="name"
        control={control}
        rules={{ required: rules.required('Issue name is required') }}
        errors={formState.errors}
        autoFocus
      />
      <FormInputTextarea
        label="Issue Description"
        name="description"
        control={control}
        errors={formState.errors}
      />
    </>
  );
}
