import CaseStatusChip from '../../../../../shared/components/CaseStatusChip';
import { CaseStatus } from '../../../../../shared/types/case';
import { Case } from '../../../../../shared/store/endpoints';
import { Dropdown } from 'primereact/dropdown';

//Status for the dropdown
const statuses = [
  { label: 'Open', value: CaseStatus.open },
  { label: 'Closed', value: CaseStatus.closed },
];
//Apply status change to our filter state
const onStatusChange = (event: any, options: any) => {
  options.filterCallback(event.value);
};

export const statusFilterTemplate = (filterOptions: any) => {
  return (
    <Dropdown
      value={filterOptions.value}
      options={statuses}
      onChange={(e) => {
        onStatusChange(e, filterOptions);
      }}
      itemTemplate={statusItemTemplate}
      placeholder="Select"
      className="p-column-filter"
      valueTemplate={statusValueTemplate}
    />
  );
};

export const statusValueTemplate = (
  option: {
    label: string;
    value: CaseStatus;
  },
  props: any,
) => {
  return (
    <>
      {option ? (
        <CaseStatusChip status={option.value} />
      ) : (
        <span>{props.placeholder}</span>
      )}
    </>
  );
};

//Render in the table
export const statusBodyTemplate = ({ caseStatus }: Case) => {
  return (
    <div className="flex w-full pl-2">
      <CaseStatusChip status={caseStatus} />
    </div>
  );
};
//Render in the filter
export const statusItemTemplate = (option: any) => {
  return <CaseStatusChip status={option.value} />;
};
