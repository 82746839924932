import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FullPageLoading from '../../components/FullPageLoading';
import useProfile from '../../hooks/useProfile';

export default function RoleGuard({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const {
    isAdmin,
    isLoading: profileLoading,
    isUninitialized: profileUninitialized,
    isFetching: profileFetching,
    isSuccess: profileSuccess,
  } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profileSuccess && !isAdmin) {
      //TODO: Redirect to a page that says you don't have access
      navigate('/');
    }
  }, [navigate, isAdmin, profileSuccess]);

  return profileLoading || profileUninitialized || profileFetching ? (
    <FullPageLoading />
  ) : (
    <>{children}</>
  );
}
