//libraries
import { motion } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';

//utils
import { slideUpFromBottom } from '../../../shared/helpers/animations';

//components
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import Spacer from '../../../shared/components/Spacer';
import Button from '../../../shared/components/Button';
import BrandedHeader from '../../../shared/components/BrandedHeader';
import { useSearchParams } from 'react-router-dom';

export default function GetStartedPage(): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const isRedirectFromPasswordChange = searchParams.get('success');
  return (
    <PageTransitionWrapper>
      <main className="flex h-screen w-full items-center justify-center bg-gradient-to-bl from-cyan-500/10 to-blue-800/10">
        <motion.div className="flex w-3/4 flex-col items-center p-6 md:w-2/4 lg:w-[500px]">
          <span className="-mb-4 text-lg font-light">Welcome to</span>
          <BrandedHeader text="Document Elf" />
          <img
            src="/assets/doc-elf-graphic.png"
            alt="doc elf wizard"
            className="mt-6 w-[350px]"
          />
          <Spacer height="h-20" />
          <motion.div
            className="w-full"
            variants={slideUpFromBottom}
            initial="hidden"
            animate="visible"
          >
            <Button
              fullWidth
              label="Get started"
              onClick={() => {
                loginWithRedirect();
              }}
            />
            {isRedirectFromPasswordChange === 'true' && (
              <motion.div
                className="mt-6 text-center text-sm text-gray-500"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, type: 'spring' }}
              >
                <p>You can now login to Document Elf with your new password.</p>
                <p>
                  Make sure to use{' '}
                  <strong>the same email you received the invite at.</strong>
                </p>
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      </main>
    </PageTransitionWrapper>
  );
}
