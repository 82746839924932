//libraries
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';

//components
import PageTransitionWrapper from '../PageTransitionWrapper';
import documentElfLoading from './documentElfLoading.json';

export default function FullPageLoading() {
  return (
    <PageTransitionWrapper>
      <main className="relative flex h-screen w-full items-center justify-center bg-gradient-to-bl from-cyan-500/10 to-blue-800/10">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Lottie
            animationData={documentElfLoading}
            loop={true}
            className="w-80"
          />
        </motion.div>
      </main>
    </PageTransitionWrapper>
  );
}
