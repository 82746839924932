import { useGetIssuesByCaseIdQuery } from '../../../shared/store/endpoints/case-issues';
import CaseIssueTable from './components/CaseIssueTable';

interface CaseIssueTabProps {
  caseId: string;
}

export default function CaseIssueTab({
  caseId,
}: CaseIssueTabProps): JSX.Element {
  const { data: caseIssues } = useGetIssuesByCaseIdQuery({
    caseId: caseId,
  });

  return <CaseIssueTable caseIssues={caseIssues} />;
}
