import { Controller, UseFormWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import FormLabel from '../SharedFormComponents/FormLabel';
import FormError from '../SharedFormComponents/FormError';

const FormDropdown = ({
  control,
  errors,
  name,
  label,
  rules,
  inputRef,
  placeholder,
  options,
  showClear,
  watch,
  itemTemplate,
  valueTemplate,
}: {
  control: any;
  errors: any;
  name: string;
  label?: string;
  options: any;
  rules?: any;
  inputRef?: any;
  watch?: UseFormWatch<any>;
  placeholder?: string;
  showClear?: boolean;
  itemTemplate?: (value: any) => JSX.Element;
  valueTemplate?: (value: any) => JSX.Element;
}) => {
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (watch) {
      const watchSub = watch((value, { name: watchName, type }) => {
        if (watchName === name) {
          setFormValue(value[name]);
        }
      });
      return () => {
        watchSub.unsubscribe();
      };
    }
  }, [watch, setFormValue, name]);

  return (
    <div className="p-field">
      <span className={label ? 'p-float-label' : ''}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <>
                <Dropdown
                  id={name + '-input'}
                  className={classNames('w-full', {
                    'p-invalid': fieldState.error,
                  })}
                  {...field}
                  ref={inputRef}
                  options={options}
                  placeholder={placeholder}
                  showClear={showClear && formValue}
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                />
                {label && (
                  <FormLabel name={name} text={label} fieldState={fieldState} />
                )}
              </>
            );
          }}
        />
      </span>

      <FormError name={name} errors={errors} />
    </div>
  );
};
export default FormDropdown;
