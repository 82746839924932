import { useAuth0 } from '@auth0/auth0-react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { Menu } from 'primereact/menu';
import React, { useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';
import { MenuItem } from 'primereact/menuitem';
import useProfile from '../../../hooks/useProfile';
import './HeaderNavMenu.scss';

//Make sure these paths match the app router
const MENU_PATHS = [
  '/account-settings',
  '/user-management',
  '/billing',
  '/templates',
];

export default function HeaderNavMenu() {
  const { logout } = useAuth0();
  const { profile } = useProfile();

  const menu = useRef<Menu>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // The menu items list has more options if user is admin. Profile and Logout remain unchanged.
  const menuItems = useMemo((): MenuItem[] => {
    const showAdmin = profile && profile.permission.role === 'admin';
    let menuItemsPayload: MenuItem[] = [
      {
        label: 'Account Settings',
        icon: 'pi pi-fw pi-user',
        command: () => navigate('/account-settings'),
      },
    ];
    //User Management, Billing, Templates
    if (showAdmin) {
      menuItemsPayload = [
        ...menuItemsPayload,
        {
          label: 'User Management',
          icon: 'pi pi-fw pi-users',
          command: () => navigate('/user-management'),
        },
        // TODO: Add back when we work on the Billing page
        // {
        //   label: 'Billing',
        //   icon: 'pi pi-fw pi-credit-card',
        //   command: () => navigate('/billing'),
        // },
        {
          label: 'Templates',
          icon: 'pi pi-fw pi-cog',
          command: () => navigate('/templates'),
        },
        {
          label: 'Third Party Licenses',
          icon: 'pi pi-fw pi-info-circle',
          command: () => window.location.assign('/licenses.html'),
        }
      ];
    }

    menuItemsPayload.push({
      label: 'Logout',
      icon: 'pi pi-fw pi-sign-out',
      command: () => logout({ returnTo: window.location.origin }),
    });

    return menuItemsPayload;
  }, [logout, navigate, profile]);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    menu.current?.toggle(event);
  }

  const isActive = useMemo(
    () => MENU_PATHS.includes(location.pathname),
    [location.pathname],
  );

  return (
    <>
      <button
        onClick={openMenu}
        className={`relative mr-2 flex flex-none items-center gap-x-2 rounded-xl px-6 py-2 font-medium text-white ring-1 ring-transparent hover:bg-primary-darker/30 transition-colors${
          isActive &&
          'border-primary-light bg-primary-darker/50 font-semibold text-white ring-primary-light'
        }`}
      >
        <UserCircleIcon className="h-5 w-5" />
        {`${profile?.firstName} ${profile?.lastName}`}
        {isActive && (
          //The line that appears under the active nav item
          <motion.div
            className="absolute -bottom-3 right-0 left-0 h-1 w-full rounded-full bg-primary-light"
            // this layoutId must be the same as the layoutId in the HeaderNavLink component
            layoutId="activeNavLink"
          />
        )}
      </button>
      <Menu
        className="w-[190px] overflow-hidden rounded-bl-lg rounded-br-lg bg-primary-dark text-white"
        model={menuItems}
        popup
        ref={menu}
      />
    </>
  );
}
