// templates/component/DocumentUploadModal.tsx

// Libraries
import { Tag } from 'primereact/tag';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// hooks
import useStorage from '../../../../../shared/hooks/useStorage';

// Endpoints

// Components
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import Button from '../../../../../shared/components/Button';
import Modal from '../../../../../shared/components/Modal';
import { formatBytes } from '../../../../../shared/helpers/utils/strings';
import useProfile from '../../../../../shared/hooks/useProfile';
import { CaseDocumentPayload } from '../../../../../shared/store/endpoints';
import { useAddMultipleCaseDocumentsMutation } from '../../../../../shared/store/endpoints/case-documents';
import useToast from '../../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';
import { FILE_SIZE_LIMIT } from '../../../../../shared/constants/file-size';
import { Divider } from 'primereact/divider';

interface DocumentUploadModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export default function DocumentUploadModal({
  showModal,
  closeModal,
}: DocumentUploadModalProps): JSX.Element {
  // hooks
  const { uploadFile } = useStorage();
  const { caseId } = useParams();
  const { profile } = useProfile();
  const [addMultipleCaseDocuments] = useAddMultipleCaseDocumentsMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // state
  const [dragEntered, setDragEntered] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [tooLargeFiles, setTooLargeFiles] = useState<File[]>([]);

  // refs
  const inputRef = useRef<any>(null);

  // effects

  // methods
  async function handleUpload() {
    setUploading(true);
    const orgId = profile?.organizationId;
    const documents: CaseDocumentPayload[] = [];
    try {
      // upload multiple files
      for (let i = 0; i < files.length; i++) {
        const now = new Date().getTime();
        const file = files[i];
        const documentId = uuidv4();
        // this might need to change to a queue, depending on file sizes
        const path: string = await uploadFile(
          file,
          `documents/${orgId}/${caseId}/${documentId}/${now}`,
        );
        documents.push({
          name: file.name,
          path,
          id: documentId,
        });
      }
      await addMultipleCaseDocuments({
        createMultipleCaseDocumentsPayload: {
          caseId: caseId as string,
          documents,
        },
      }).unwrap();
      showSuccessToast(
        `${files.length === 1 ? 'File' : 'Files'} successfully uploaded`,
      );
      handleClose();
    } catch (err) {
      showErrorToast('Error uploading files, try again');
    }
  }

  function handleClose() {
    setUploading(false);
    setFiles([]);
    setTooLargeFiles([]);
    closeModal();
  }

  function handleDrop(e: any) {
    e.preventDefault();
    const items = e.dataTransfer.items;
    const filesArray = [];
    const tooLarge: File[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (!checkFileSize(file)) {
          tooLargeFiles.push(file);
          showFileSizeError();
        } else {
          filesArray.push(file);
        }
      }
    }
    setFiles([...files, ...filesArray]);
    setTooLargeFiles([...tooLargeFiles, ...tooLarge]);
    setDragEntered(false);
  }

  function handleInputChange(e: any) {
    const uploadedFiles = e.target.files;
    const filesArray = [];
    const tooLarge: File[] = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      if (!checkFileSize(file)) {
        tooLargeFiles.push(file);
        showFileSizeError();
      } else {
        filesArray.push(file);
      }
    }
    setFiles([...files, ...filesArray]);
    setTooLargeFiles([...tooLargeFiles, ...tooLarge]);
  }

  function handleDragOver(e: any) {
    e.preventDefault();
    setDragEntered(true);
  }

  function handleDragExit(e: any) {
    e.preventDefault();
    setDragEntered(false);
  }

  function handleRemoveFile(file: File, index: number) {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  function handleRemoveLargeFile(file: File, index: number) {
    const newFiles = [...tooLargeFiles];
    newFiles.splice(index, 1);
    setTooLargeFiles(newFiles);
  }

  function checkFileSize(file: File) {
    const sizeInMb = file.size / 1024 / 1024; // gets size in MB
    if (sizeInMb > FILE_SIZE_LIMIT) {
      return false;
    }
    return true;
  }

  function showFileSizeError() {
    showErrorToast(`File size must be less than ${FILE_SIZE_LIMIT} MB`);
  }

  // templates
  function itemTemplate(
    file: any,
    {
      onRemove,
      index,
    }: { onRemove: (file: File, index: number) => void; index: number },
  ) {
    return (
      <div className="flex w-full items-center gap-4" key={`file-${index}`}>
        <div className="flex grow items-center">
          <i className="pi pi-file" style={{ fontSize: '2rem' }}></i>
          <span className="ml-4 flex flex-col text-left">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={formatBytes(file.size)}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onRemove(file, index)}
        />
      </div>
    );
  }

  return (
    <>
      <Modal showModal={showModal} onHide={handleClose}>
        <div
          className={classNames('', { 'opacity-50': dragEntered })}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragExit}
        >
          {files.length === 0 ? (
            <div className="rounded-lg border-4 border-dashed border-gray-300 p-8">
              <div className="flex flex-col items-center justify-center gap-4">
                <span className="text-xl">
                  Drag your files here to start uploading
                </span>
                <span>or</span>
                <Button
                  label="Browse"
                  onClick={() => inputRef.current?.click()}
                />
                <span>Max file size is {FILE_SIZE_LIMIT}MB</span>
                <span>*Supports: .jpg, .png, .tiff, and PDF</span>
              </div>
              <input
                type="file"
                name="upload"
                className="hidden"
                ref={inputRef}
                multiple
                onChange={handleInputChange}
                accept="image/jpeg, image/png, application/pdf, image/tiff"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-4 p-4">
              <span className="text-lg">Files to upload:</span>
              {files.map((file: File, index: number) =>
                itemTemplate(file, { onRemove: handleRemoveFile, index }),
              )}
            </div>
          )}
          {tooLargeFiles.length > 0 && (
            <>
              <Divider />
              <div className="flex flex-col gap-4 p-4">
                <span className="text-lg">
                  The following files are too large to upload:
                </span>
                {tooLargeFiles.map((file: File, index: number) =>
                  itemTemplate(file, {
                    onRemove: handleRemoveLargeFile,
                    index,
                  }),
                )}
              </div>
            </>
          )}
        </div>
        <div className="flex w-full justify-center gap-6 pt-8">
          <Button
            label="Cancel"
            onClick={handleClose}
            className="p-button-outlined"
            disabled={uploading}
          />
          {files.length > 0 && (
            <Button
              label="Upload document(s)"
              onClick={handleUpload}
              loading={uploading}
            />
          )}
        </div>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
}
