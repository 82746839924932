import { useMemo } from 'react';
import { useGetCasePeopleQuery } from '../store/endpoints/case-people';

const usePeopleOptions = (caseId: string) => {
  const { data: peopleData } = useGetCasePeopleQuery({ caseId });

  const peopleOptions = useMemo(() => {
    return (
      peopleData?.map((person) => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person.id,
      })) || []
    );
  }, [peopleData]);

  return {
    peopleOptions,
  };
};

export default usePeopleOptions;
