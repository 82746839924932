//libraries
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

//endpoints
import { CreateProfilePayload } from '../../../shared/store/endpoints';
import { useCreateProfileMutation } from '../../../shared/store/endpoints/profile';

//components
import Button from '../../../shared/components/Button';
import BrandedHeader from '../../../shared/components/BrandedHeader';
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import Spacer from '../../../shared/components/Spacer';
import FormInput from '../../../shared/components/form/FormInput';

interface FormValues {
  organizationName: string;
  firstName: string;
  lastName: string;
}

export default function RegisterPage(): JSX.Element {
  //hooks
  const navigate = useNavigate();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      organizationName: '',
      firstName: '',
      lastName: '',
    },
  });
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = form;
  const { user, logout } = useAuth0();
  const [createProfile, { isLoading }] = useCreateProfileMutation();

  //methods
  async function handleContinue(formData: FormValues): Promise<void> {
    try {
      if (isValid && user?.email) {
        const { organizationName, firstName, lastName } = formData;
        const data: CreateProfilePayload = {
          email: user.email,
          firstName,
          lastName,
          organizationName,
        };
        await createProfile({
          createProfilePayload: data,
        }).unwrap();
        navigate('/');
      }
    } catch (error) {
      console.error({ error });
    }
  }

  return (
    <PageTransitionWrapper>
      <main className="relative flex h-screen w-full flex-col items-center justify-center bg-gradient-to-bl from-cyan-500/10 to-blue-800/10">
        <BrandedHeader text="Welcome to Document Elf" />
        <p className="text-lg font-medium text-gray-800">
          Let's get started with your profile
        </p>
        <Spacer height="h-8" />
        <motion.section
          className="w-[600px] rounded-xl bg-white/90 p-8 shadow-2xl"
          initial={{ y: 10 }}
          animate={{
            y: 0,
            transition: { delay: 0.2, type: 'spring' },
          }}
        >
          <form onSubmit={handleSubmit(handleContinue)}>
            <FormInput
              label="Organization Name"
              name="organizationName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Organization name is required',
                },
              }}
              errors={errors}
            />
            <FormInput
              label="First name"
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'First name is required',
                },
              }}
              errors={errors}
            />
            <FormInput
              label="Last name"
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Last name is required',
                },
              }}
              errors={errors}
            />
            <Spacer height="h-4" />
            <Button
              label="Continue"
              fullWidth
              onClick={handleSubmit(handleContinue)}
              type="submit"
              disabled={!isValid}
              loading={isLoading}
            />
          </form>
        </motion.section>
        <button
          className="mt-8 text-sm text-gray-500 hover:text-gray-800"
          onClick={() => logout()}
        >
          Not working? Logout
        </button>
      </main>
    </PageTransitionWrapper>
  );
}
