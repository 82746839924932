import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { Controller } from 'react-hook-form';
import FormError from '../SharedFormComponents/FormError';
import FormLabel from '../SharedFormComponents/FormLabel';

const FormInputTextArea = ({
  control,
  errors,
  name,
  label,
  rules,
  type,
  inputRef,
  rows,
  cols,
  readOnly = false,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  rows?: number;
  cols?: number;
  readOnly?: boolean;
}) => {
  return (
    <div className="p-field">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <>
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <InputTextarea
                id={name + '-input'}
                className={classNames('w-full', {
                  'p-invalid': fieldState.error,
                })}
                {...field}
                autoResize
                ref={inputRef}
                readOnly={readOnly}
              />
            </>
          );
        }}
      />

      <FormError name={name} errors={errors} />
    </div>
  );
};
export default FormInputTextArea;
