import { FC } from 'react';

interface PageTitleProps {
  text?: string;
}

const PageTitle: FC<PageTitleProps> = ({ text }) => (
  <h2 className="mb-6 text-3xl font-bold text-slate-600">{text}</h2>
);

export default PageTitle;
