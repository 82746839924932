import { useState } from 'react';
import Button from '../../../../../../shared/components/Button';
import CreateCaseTypeModal from '../CreateCaseTypeModal';

export default function CreateCaseTypeButton(): JSX.Element {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Button
        label="Add Case Type"
        icon="pi pi-plus"
        onClick={() => setShowCreateModal(true)}
      />
      <CreateCaseTypeModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      />
    </>
  );
}
