import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../shared/components/Button';
import Spacer from '../../../shared/components/Spacer';
import useProfile from '../../../shared/hooks/useProfile';
import creditCardAnimation from '../../../shared/lottie/credit-card-animation.json';
import confettiAnimation from '../../../shared/lottie/confetti.json';
import confettiTwoAnimation from '../../../shared/lottie/confetti-two.json';

const UpdatedBillingPage = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  return result === 'success' ? (
    <div className="flex flex-col items-center justify-center">
      <div className={`relative z-10 mx-auto mb-4 w-[200px]`}>
        <Lottie
          animationData={confettiAnimation}
          className="absolute top-0 right-0 left-0 w-full"
          loop={false}
        />
        <Lottie
          animationData={confettiTwoAnimation}
          className="absolute top-0 right-0 left-0 w-full"
          loop={false}
        />
        <motion.div
          initial={{ y: 0, scale: 1 }}
          animate={{ y: [0, -50, 0], scale: [1, 1.5, 1] }}
          transition={{ type: 'spring' }}
        >
          <Lottie
            animationData={creditCardAnimation}
            className="absolute inset-0 z-50 h-80"
            loop={false}
          />
        </motion.div>
      </div>

      <motion.main
        className="relative z-0 mx-auto mt-[11rem] w-[600px] rounded-xl bg-gradient-to-tr from-primary-darker to-primary-base p-8 text-center text-white shadow-3xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'spring' }}
      >
        <Spacer height="h-4" />
        <h1 className="text-4xl font-bold">Your new card is saved</h1>
        <Spacer height="h-8" />
        <p className="text-left">
          {profile?.firstName}, your billing information has been updated.
          Please feel free to reach out to help@documentelf.com is you have any
          questions about billing. Thanks! 😁
        </p>
        <Spacer height="h-12" />
        <Button
          label="Go to my dashboard"
          className="p-button-success"
          fullWidth
          onClick={() => navigate('/')}
        />
      </motion.main>
    </div>
  ) : (
    <div className="mx-auto mt-10 w-[600px] rounded-xl bg-gray-100 p-8">
      <h1 className="text-3xl font-semibold text-gray-700">
        Payment update cancelled
      </h1>
      <Spacer height="h-4" />
      <p className="text-gray-600">
        No sweat, nothing has changed. Your billing information is still the
        same. If you have any questions about billing, please feel free to reach
        out to help@documentelf.com
      </p>
      <Spacer height="h-8" />
      <Button
        label="Go to my dashboard"
        fullWidth
        onClick={() => navigate('/')}
      />
    </div>
  );
};

export default UpdatedBillingPage;
