import { Toast } from 'primereact/toast';
import { useRef } from 'react';

/**
 * Hook to show toast messages
 *
 * **USAGE**
 *
 * Setup:
 * bring toastRef into the component:
 * const { toastRef } = useToast();
 *
 * add Toast component from Prime to render, make sure it's outside of any other components:
 * <Toast ref={toastRef} />
 *
 * Showing Toasts:
 * include the toast you want to show:
 * const { toastRef, showSuccessToast } = useToast();
 *
 * When you want to display the toast:
 * showSuccessToast('Success Message');
 */
export default function useToast() {
  const toastRef = useRef<Toast>(null);

  function showErrorToast(message: string) {
    if (toastRef.current) {
      toastRef.current.show({
        severity: 'error',
        summary: message,
      });
    }
  }

  function showSuccessToast(message: string) {
    if (toastRef.current) {
      toastRef.current.show({
        severity: 'success',
        summary: message,
      });
    }
  }

  return {
    showErrorToast,
    showSuccessToast,
    toastRef,
  };
}
