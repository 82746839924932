// Libraries
import { useState } from 'react';

// Components
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import Button from '../../../../../shared/components/Button';
import { Badge, BadgeSeverityType } from 'primereact/badge';

// Constants
import { DOCUMENT_TYPE } from '../../../../../shared/constants/document-type';
import { REVIEW_STATUS_ARRAY } from '../../../../../shared/constants/review-status';

// Types
import {
  CaseDocument,
  CaseDocumentsWithCount,
} from '../../../../../shared/store/endpoints';
import { LazyFilterParams } from '../../../../../shared/types/lazy-filter-params';
import { ReviewStatus } from '../../../../../shared/types/review-status.enum';
import {
  tableDateTemplate,
  tableRangeFilterTemplate,
} from '../../../../../shared/components/table-templates/table-date-template';
import DocumentExportButton from '../DocumentExportButton';
import { useDeleteCaseDocumentsMutation } from '../../../../../shared/store/endpoints/case-documents';
import TableDeleteButton from '../../../../../shared/components/TableDeleteButton/TableDeleteButton';
import useToast from '../../../../../shared/hooks/useToast';
import { isWithinInterval, sub } from 'date-fns';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { tableFilterSearchElement } from '../../../../../shared/components/table-templates/searchFilterElement';
import { Tooltip } from 'primereact/tooltip';
import useProfile from '../../../../../shared/hooks/useProfile';

interface CaseDocumentsTableProps {
  data: CaseDocumentsWithCount | undefined;
  isLoading: boolean;
  lazyParams: LazyFilterParams;
  setLazyParams: (params: LazyFilterParams) => void;
  viewDocument: (doc: CaseDocument) => void;
  editDocument: (doc: CaseDocument) => void;
  uploadDocument: () => void;
}

export default function CaseDocumentsTable({
  data,
  isLoading,
  lazyParams,
  setLazyParams,
  viewDocument,
  editDocument,
  uploadDocument,
}: CaseDocumentsTableProps): JSX.Element {
  // hooks
  const [deleteDocuments, { isLoading: isDeleting }] =
    useDeleteCaseDocumentsMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();
  const { profile } = useProfile();

  // state
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<CaseDocument[]>(
    [],
  );

  // refs

  // methods
  function onPage(event: any) {
    setLazyParams(event);
  }

  function onSort(event: any) {
    setLazyParams(event);
  }

  function onFilter(event: any) {
    event['first'] = 0;
    setLazyParams(event);
  }

  async function onSelectionChange(event: any) {
    // select checkbox or click row
    if (event.type === 'checkbox') {
      setSelectedDocuments(event.value);
    } else {
      const value = event.value[0];
      viewDocument(value);
    }
  }

  function onSelectAllChange(event: any) {
    const selectAll = event.checked;
    if (selectAll) {
      if (data?.documents) {
        setSelectAll(true);
        setSelectedDocuments(data.documents);
      }
    } else {
      setSelectAll(false);
      setSelectedDocuments([]);
    }
  }

  // templates
  function statusFilterTemplate(options: any) {
    return (
      <Dropdown
        value={options.value}
        options={REVIEW_STATUS_ARRAY}
        onChange={(e) => options.filterCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  }

  function reviewStatusTemplate(rowData: CaseDocument) {
    let status = '';
    switch (rowData.reviewStatus) {
      case ReviewStatus.processing:
        status = 'Processing';
        break;
      case ReviewStatus.failed:
        status = 'Failed';
        break;
      case ReviewStatus.todo:
        status = 'To Do';
        break;
      case ReviewStatus.inProgress:
        status = 'In Progress';
        break;
      case ReviewStatus.complete:
        status = 'Complete';
        break;
      default:
        status = '';
    }
    return (
      <div className="flex">
        {status}
        {rowData.reviewStatus === ReviewStatus.processing && (
          <div className="ml-2">
            <ProgressSpinner className="!h-6 !w-6" strokeWidth="6" />
          </div>
        )}
      </div>
    );
  }

  function documentTypeFilterTemplate(options: any) {
    return (
      <Dropdown
        value={options.value}
        options={DOCUMENT_TYPE}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select a Type"
        showClear
        className="p-column-filter"
      />
    );
  }

  async function handleDeleteDocuments() {
    const caseDocumentIds = selectedDocuments.map((doc) => doc.id);
    try {
      await deleteDocuments({
        caseDocumentIds,
      });
      setSelectedDocuments([]);
      showSuccessToast('Documents successfully deleted');
    } catch (err) {
      showErrorToast(
        'There was an error deleting the documents, please try again',
      );
    }
  }

  function tableHeader() {
    return (
      <div className="flex w-full justify-end">
        <div className="flex gap-4">
          <TableDeleteButton
            targetPluralText="documents"
            deleteFunction={handleDeleteDocuments}
            selectedItems={selectedDocuments}
            loading={isDeleting}
            message="Deleting the selected document(s) will result in the permanent deletion of the uploaded file and will make all associated notes inaccessible. Are you sure you want to continue?"
          />
          <DocumentExportButton selectedDocuments={selectedDocuments} />
          <Button
            label="Add document(s)"
            icon="pi pi-plus"
            onClick={() => uploadDocument()}
          />
        </div>
      </div>
    );
  }

  function statusItemTemplate(option: any) {
    return (
      <span className={`customer-badge status-${option}`}>{option.label}</span>
    );
  }

  function actionBodyTemplate(rowData: CaseDocument) {
    return (
      <Button
        label="Edit"
        onClick={() => editDocument(rowData)}
        className="p-button-outlined p-button-sm p-button-info"
      />
    );
  }

  function activityTemplate({ editLog }: CaseDocument) {
    const filteredEditLog = editLog?.filter(
      (log) => log.profileId !== profile?.id,
    );
    let severity: BadgeSeverityType = 'info';
    if (filteredEditLog) {
      if (filteredEditLog.length > 0) {
        severity = 'success';

        const hasImmediateActivity = filteredEditLog.some((log) => {
          return isWithinInterval(new Date(log.lastActiveDate), {
            start: sub(new Date(), { seconds: 15 }),
            end: new Date(),
          });
        });

        if (hasImmediateActivity) {
          severity = 'success';
        }
      }
    }

    return (
      <>
        {filteredEditLog && filteredEditLog.length > 0 && (
          <>
            <Tooltip target=".active-users-tooltip-target" />
            <i
              className="pi pi-user p-text-secondary p-overlay-badge active-users-tooltip-target mr-4"
              style={{ fontSize: '1.5rem' }}
              data-pr-tooltip={
                filteredEditLog?.length > 0
                  ? ` ${filteredEditLog?.length} active user ${
                      filteredEditLog?.length > 1 ? 's' : ''
                    }`
                  : 'No active users'
              }
            >
              <Badge
                value={filteredEditLog?.length}
                severity={severity}
              ></Badge>
            </i>
          </>
        )}
      </>
    );
  }

  return isLoading ? (
    <Skeleton width="full" height="30rem" />
  ) : (
    <div className="app-datatable">
      <DataTable
        value={data?.documents}
        lazy
        filterDisplay="menu"
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={data?.count}
        header={tableHeader}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onFilter={onFilter}
        filters={lazyParams.filters}
        loading={isLoading}
        selection={selectedDocuments}
        onSelectionChange={onSelectionChange}
        selectAll={selectAll}
        onSelectAllChange={onSelectAllChange}
        className="p-datatable-data"
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        <Column field="editLog" body={activityTemplate} />
        <Column
          key="updatedOn"
          field="updatedOn"
          header="Last Modified"
          dataType="date"
          showFilterMatchModes={false}
          sortable
          filter
          body={(caseDocument) => tableDateTemplate(caseDocument.updatedOn)}
          filterElement={tableRangeFilterTemplate}
        />
        <Column
          field="name"
          header="Name"
          showFilterMatchModes={false}
          sortable
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search By Name')
          }
        />
        <Column
          field="documentType"
          header="Document Type"
          showFilterMatchModes={false}
          sortable
          filter
          filterElement={documentTypeFilterTemplate}
        />
        <Column
          field="reviewStatus"
          header="Review Status"
          showFilterMatchModes={false}
          filterElement={statusFilterTemplate}
          body={reviewStatusTemplate}
          sortable
          filter
        />
        <Column header="Action" body={actionBodyTemplate} />
      </DataTable>
      <Toast ref={toastRef} />
    </div>
  );
}
