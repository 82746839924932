// Data
import { ProfileRolesEnum } from '../../../../shared/types/profile-types';
import { translateProfileRoles } from '../../../../shared/utility-fns/profile-fns';
import { rules } from '../../../../shared/helpers/validators/form-validators';

// Libraries
import { useFormContext } from 'react-hook-form';

// Components
import { SelectItemOptionsType } from 'primereact/selectitem';
import FormSelectButton from '../../../../shared/components/form/FormSelectButton';
import FormInput from '../../../../shared/components/form/FormInput';
import FormSelect from '../../../../shared/components/form/FormSelect';

export interface UserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  emailConfirm: string;
  role: 'admin' | 'caseUser' | null;
  active: boolean;
}

export function setUserFormValues(setValue?: Partial<UserFormValues>) {
  let value: UserFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirm: '',
    role: null,
    active: true,
  };

  if (setValue) {
    value = {
      ...value,
      ...setValue,
    };
  }
  return value;
}

interface UserFormProps {
  editMode?: boolean;
}

const activeStatusOptions: SelectItemOptionsType = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export default function UserForm({
  editMode = false,
}: UserFormProps): JSX.Element {
  // hooks
  const { control, formState, getValues, trigger } = useFormContext();

  // data
  const roleSelectOptions = [
    { label: translateProfileRoles('admin'), value: ProfileRolesEnum.admin },
    {
      label: translateProfileRoles('caseUser'),
      value: ProfileRolesEnum.caseUser,
    },
  ];

  // methods
  function detectIfMatching() {
    const confirmValue = getValues().emailConfirm;
    if (confirmValue && confirmValue.length > 0) {
      trigger('emailConfirm');
    }
  }

  return (
    <>
      <FormInput
        label="First Name"
        name="firstName"
        control={control}
        rules={{ required: rules.required('First name is required') }}
        errors={formState.errors}
      />
      <FormInput
        label="Last Name"
        name="lastName"
        control={control}
        rules={{ required: rules.required('Last name is required') }}
        errors={formState.errors}
      />
      <FormInput
        label="Email"
        name="email"
        control={control}
        rules={{
          ...rules.required('Email is required'),
          ...rules.pattern.email(),
        }}
        errors={formState.errors}
        onBlur={detectIfMatching}
      />
      {!editMode && (
        <FormInput
          label="Re-Enter Email"
          name="emailConfirm"
          control={control}
          rules={{
            required: rules.required('Must confirm email'),
            pattern: rules.pattern.email(),
            validate: (value: string) => {
              return value === getValues().email || 'Emails must match';
            },
          }}
          errors={formState.errors}
        />
      )}
      <FormSelect
        label="User Role"
        name="role"
        control={control}
        rules={{ required: rules.required('Must select a user role') }}
        errors={formState.errors}
        options={roleSelectOptions}
      />
      {editMode && (
        <FormSelectButton
          label="Active Status"
          errors={formState.errors}
          name="active"
          options={activeStatusOptions}
          control={control}
        />
      )}
    </>
  );
}
