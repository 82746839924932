import { SelectItemOptionsType } from 'primereact/selectitem';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import Button from '../../../../../shared/components/Button';
import FormInput from '../../../../../shared/components/form/FormInput';
import FormSelect from '../../../../../shared/components/form/FormSelect';
import Modal from '../../../../../shared/components/Modal';
import { DOCUMENT_TYPE } from '../../../../../shared/constants/document-type';
import { requiredRules } from '../../../../../shared/helpers/utils/form-utils';
import useToast from '../../../../../shared/hooks/useToast';
import { CaseDocument } from '../../../../../shared/store/endpoints';
import { useUpdateCaseDocumentMutation } from '../../../../../shared/store/endpoints/case-documents';

interface EditCaseDocumentPropertiesModalProps {
  caseDocument: CaseDocument;
  showModal: boolean;
  onHide: () => void;
}

interface CaseDocumentFormValues {
  name: string;
  documentType: string;
  isExhibit: boolean;
  exhibitNumber: string;
  reviewStatus: 'processing' | 'failed' | 'todo' | 'inProgress' | 'complete';
}

export default function EditCaseDocumentPropertiesModal({
  caseDocument,
  showModal,
  onHide,
}: EditCaseDocumentPropertiesModalProps): JSX.Element {
  // hooks
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: caseDocument.name || '',
      documentType: caseDocument.documentType || '',
      isExhibit: caseDocument.isExhibit || false,
      exhibitNumber: caseDocument.exhibitNumber || '',
      reviewStatus: caseDocument.reviewStatus || '',
    },
  });

  const [updateCase, { isLoading }] = useUpdateCaseDocumentMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();
  // state
  const isExhibitOptions: SelectItemOptionsType = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];
  const reviewStatusOptions: SelectItemOptionsType = [
    {
      label: 'Processing',
      value: 'processing',
    },
    {
      label: 'To do',
      value: 'todo',
    },
    {
      label: 'In progress',
      value: 'inProgress',
    },
    {
      label: 'Complete',
      value: 'complete',
    },
  ];

  const documentTypeOptions: SelectItemOptionsType = DOCUMENT_TYPE.map(
    (type) => ({ label: type, value: type }),
  );

  // methods
  async function submitForm(data: CaseDocumentFormValues) {
    const payload = {
      ...caseDocument,
      ...data,
    };
    try {
      await updateCase({
        caseDocumentId: caseDocument.id,
        caseDocument: payload,
      });
      showSuccessToast('Document successfully updated');
      onHide();
    } catch (err) {
      showErrorToast('Error editing document, please try again');
    }
  }

  return (
    <>
      <Modal showModal={showModal} onHide={onHide}>
        <form onSubmit={handleSubmit(submitForm)}>
          <FormInput
            label="Document Name"
            name="name"
            control={control}
            rules={requiredRules('Document name is required')}
            errors={errors}
          />
          <FormSelect
            label="Document Type"
            name="documentType"
            options={documentTypeOptions}
            control={control}
            errors={errors}
          />
          <div className="flex gap-4">
            <FormSelect
              label="Exhibit"
              name="isExhibit"
              control={control}
              options={isExhibitOptions}
              errors={errors}
            />
            <FormInput
              label="Exhibit No."
              name="exhibitNumber"
              control={control}
              errors={errors}
            />
          </div>
          <FormSelect
            label="Review Status"
            name="reviewStatus"
            control={control}
            options={reviewStatusOptions}
            errors={errors}
          />
          <div className="flex w-full justify-center gap-4">
            <Button
              label="Cancel"
              onClick={onHide}
              type="reset"
              className="p-button-outlined"
              disabled={isLoading}
            />
            <Button label="Save changes" type="submit" loading={isLoading} />
          </div>
        </form>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
}
