//Libraries
import { Dialog } from 'primereact/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CasePayload,
  useGetCasesQuery,
} from '../../../../shared/store/endpoints';
import { useCreateCaseMutation } from '../../../../shared/store/endpoints/cases';

//Components
import Button from '../../../../shared/components/Button';
import CaseForm, { setCaseFormValues, CaseFormValues } from '../CaseForm';
import useToast from '../../../../shared/hooks/useToast';
import { Toast } from 'primereact/toast';

interface CreateCaseModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export default function CreateCaseModal({
  showModal,
  setShowModal,
}: CreateCaseModalProps) {
  const [createCase, { isLoading }] = useCreateCaseMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();
  const { data: cases } = useGetCasesQuery();

  const form = useForm({
    mode: 'onChange',
    defaultValues: setCaseFormValues(),
  });
  const { reset, handleSubmit } = form;

  function closeModal() {
    reset();
    setShowModal(false);
  }

  async function submitNewCase(data: CaseFormValues) {
    const {
      name,
      description,
      type: caseTypeId,
      caseUserIds,
      caseStatus,
    } = data;

    const casePayload: CasePayload = {
      name,
      description: description || '',
      caseTypeId: caseTypeId || 0,
      caseUserIds,
      caseStatus: caseStatus || 'open',
    };

    if (cases?.find((c) => c.name.toLowerCase() === name.toLowerCase())) {
      showErrorToast(
        'Case name already exists.  Please choose a different name',
      );
      return;
    }

    try {
      await createCase({ casePayload }).unwrap();
      showSuccessToast('Case successfully created');
      closeModal();
    } catch (err) {
      showErrorToast('Error creating case, please try again');
    }
  }

  const footer = (
    <div>
      <Button
        className="p-button-outlined"
        label="Cancel"
        onClick={() => setShowModal(false)}
        disabled={isLoading}
      />
      <Button
        label="Create new case"
        onClick={handleSubmit(submitNewCase)}
        disabled={!form.formState.isValid || isLoading}
      />
    </div>
  );

  return (
    <>
      <Dialog
        className="w-[600px]"
        header="New Case"
        visible={showModal}
        onHide={closeModal}
        footer={footer}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submitNewCase)}>
            <CaseForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
