import React, { forwardRef } from 'react';

interface DividerProps {
  label: string;
  className?: string;
  ref?: any;
}
const DividerWithLabel: React.FC<DividerProps> = forwardRef(
  ({ label, className }, ref) => {
    return (
      <div
        className={`relative flex w-full items-center justify-between ${className} `}
      >
        <div className="w-full border-t border-gray-300" />
        <span className="px-2 text-sm text-gray-500">{label}</span>
        <div className="w-full border-t border-gray-300" />
      </div>
    );
  },
);

export default DividerWithLabel;
