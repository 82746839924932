import { Dropdown } from 'primereact/dropdown';
import { ProfileStatusString } from '../../../../../shared/types/profile-types';
import { FilterOptions } from '../../../../../shared/types/table-types';

export function profileTableStatusFilterElement(
  options: FilterOptions<boolean>,
) {
  const dropdownOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];
  return (
    <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(event) => options.filterCallback(event.value, options.index)}
      placeholder="Select a Status"
      className="p-column-filter"
    />
  );
}

export function profileTableStatusTemplate(
  active: boolean | ProfileStatusString,
) {
  const value =
    typeof active === 'boolean' ? active : active === 'active' ? true : false;
  return (
    <div className="flex gap-2">
      <i className={`pi ${value ? 'pi-check-circle' : 'pi-times-circle'}`} />
      <span>{value ? 'Active' : 'Inactive'}</span>
    </div>
  );
}
