import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['CaseNotes', 'CaseDocumentNotes', 'CaseNotesTimeline'],
  endpoints: {
    getAllCaseNotes: {
      providesTags: ['CaseNotes'],
    },
    getAllCaseNotesForTimeline: {
      providesTags: ['CaseNotesTimeline'],
    },
    addCaseNote: {
      invalidatesTags: ['CaseDocumentNotes', 'CaseNotes', 'CaseNotesTimeline'],
    },
    updateCaseNote: {
      invalidatesTags: ['CaseDocumentNotes', 'CaseNotes', 'CaseNotesTimeline'],
    },
    getAllCaseNotesForDocument: {
      providesTags: ['CaseDocumentNotes'],
    },
    deleteCaseNotes: {
      invalidatesTags: ['CaseDocumentNotes', 'CaseNotes', 'CaseNotesTimeline'],
    },
  },
});

export const {
  useGetAllCaseNotesQuery,
  useGetAllCaseNotesForTimelineQuery,
  useAddCaseNoteMutation,
  useUpdateCaseNoteMutation,
  useGetAllCaseNotesForDocumentQuery,
  useDeleteCaseNotesMutation,
} = enhancedApi;
