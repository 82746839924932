// Endpoints
import { Case } from '../../../../shared/store/endpoints';

// Components
import Button from '../../../../shared/components/Button';
import PageTitle from '../../../../shared/components/PageTitle';
import UpdateCaseModal from '../../../CaseDashboardPage/components/UpdateCaseModal';
import { useState } from 'react';
import TrueFalseChip from '../../../../shared/components/TrueFalseChip';

interface CaseDetailHeaderProps {
  caseData: Case | undefined;
}

export default function CaseDetailHeader({
  caseData,
}: CaseDetailHeaderProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="flex items-start justify-between">
      <div>
        <div className="flex items-start gap-4 ">
          <PageTitle text={caseData?.name} />
          <TrueFalseChip
            value={caseData?.caseStatus === 'open'}
            label={caseData?.caseStatus as string}
            className="mt-1"
          />
        </div>
        <h3 className="max-w-3xl whitespace-pre-line text-gray-600">
          {caseData?.description}
        </h3>
      </div>
      <Button
        label="Edit Case"
        className="p-button-outlined"
        icon="pi pi-pencil"
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <UpdateCaseModal
          caseToEdit={caseData}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}
