import { Chip } from 'primereact/chip';
import {
  MultiSelect,
  MultiSelectPanelFooterTemplateType,
} from 'primereact/multiselect';
import { SelectItem, SelectItemOptionsType } from 'primereact/selectitem';
import { FC } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFormContext,
} from 'react-hook-form';
import FormError from '../SharedFormComponents/FormError';
import FormLabel from '../SharedFormComponents/FormLabel';

/**
 * NOTE: this multi select must be used within a form-provider component because of
 * the use of FormContext. Otherwise this component will not function correctly.
 */

interface FormMultiSelectProps {
  control: Control;
  errors: FieldErrorsImpl;
  name: string;
  label: string;
  options: SelectItemOptionsType;
  rules?: any;
  placeholder?: string;
  showClear?: boolean;
  className?: string;
  filter?: boolean;
  filterBy?: string;
  panelFooterTemplate?: MultiSelectPanelFooterTemplateType;
}

const FormMultiSelect: FC<FormMultiSelectProps> = ({
  control,
  errors,
  name,
  label,
  rules,
  placeholder,
  options,
  filterBy,
  showClear,
  className,
  panelFooterTemplate,
}) => {
  const { setValue: setFormValue, getValues: getFormValues } = useFormContext();

  const itemTemplate = (option: SelectItem) => {
    return (
      <div className="country-item">
        <p className="font-medium">{option.label}</p>
      </div>
    );
  };

  const handleRemove = (option: SelectItem) => {
    const values = getFormValues(name);
    const set = new Set(values);
    set.delete(option.value);
    setFormValue(name, Array.from(set));
  };

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ fieldState }) => {
          return (
            <div className="flex flex-col gap-2">
              <FormLabel name={name} text={label} fieldState={fieldState} />
              <MultiSelect
                value={getFormValues(name)}
                options={options}
                onChange={(e) => setFormValue(name, e.value)}
                optionLabel="label"
                placeholder={placeholder}
                filter
                filterBy={filterBy}
                itemTemplate={itemTemplate}
                selectedItemTemplate={() => (
                  <div className="p-multiselect-token bg-none"></div>
                )}
                panelFooterTemplate={panelFooterTemplate}
                showClear={showClear}
              />
              <div className="flex flex-wrap gap-2">
                {getFormValues(name).reduce((acc: [], item: any) => {
                  const option = options.find(
                    (option) => option.value === item,
                  );
                  if (option) {
                    return [
                      ...acc,
                      <Chip
                        label={option.label}
                        key={option.value}
                        removable
                        onRemove={(e) => handleRemove(option)}
                      />,
                    ];
                  }
                  return acc;
                }, [])}
              </div>
            </div>
          );
        }}
      />
      <FormError name={name} errors={errors} />
    </div>
  );
};

export default FormMultiSelect;
