import classNames from 'classnames';
import { ControllerFieldState } from 'react-hook-form';

interface FormLabelProps {
  text: string;
  name: string;
  fieldState: ControllerFieldState;
  rules?: any;
}

export default function FormLabel({
  text,
  name,
  fieldState,
  rules,
}: FormLabelProps): JSX.Element {
  return (
    <div className="flex items-center gap-x-2">
      <label
        htmlFor={name + '-input'}
        className={classNames('ml-2 mb-2 block font-medium', {
          'p-error': fieldState.error,
        })}
      >
        {text}
      </label>
      {rules?.required && (
        <small className="mb-2 rounded-xl border bg-gray-100 px-2 text-xs text-gray-500">
          Required
        </small>
      )}
    </div>
  );
}
