import { motion } from 'framer-motion';
import Button from '../Button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useState } from 'react';

interface TableDeleteButtonProps {
  selectedItems: any[];
  // This property is the thing you are deleting plural, so "issues", "people", "cases", etc
  targetPluralText: string;
  deleteFunction: () => void;
  loading: boolean;
  message?: string;
}

export default function TableDeleteButton({
  selectedItems,
  targetPluralText,
  deleteFunction,
  loading,
  message,
}: TableDeleteButtonProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      {selectedItems.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Button
            label={`Delete selected ${targetPluralText}`}
            onClick={() => setIsVisible(true)}
            icon="pi pi-trash"
            className="p-button-danger"
            loading={loading}
          />
        </motion.div>
      )}
      <ConfirmDialog
        visible={isVisible}
        onHide={() => setIsVisible(false)}
        message={
          message ||
          `${selectedItems.length} ${
            selectedItems.length === 1 ? 'item' : 'items'
          } selected for deletion.`
        }
        header={`Delete selected ${targetPluralText}?`}
        acceptIcon="pi pi-trash"
        acceptLabel="Delete"
        rejectLabel="Cancel"
        className="w-[556px]"
        accept={deleteFunction}
      />
    </>
  );
}
