// Libraries
import { useFormContext } from 'react-hook-form';

// Data
import { rules } from '../../../../../../shared/helpers/validators/form-validators';

// Components
import FormInput from '../../../../../../shared/components/form/FormInput';
import FormInputTextarea from '../../../../../../shared/components/form/FormInputTextarea';

export interface CaseTypeFormValues {
  name: string;
  description: string;
}

export function setCaseTypeFormValues(setValues?: Partial<CaseTypeFormValues>) {
  let values: CaseTypeFormValues = {
    name: '',
    description: '',
  };
  if (setValues) {
    values = {
      ...values,
      ...setValues,
    };
  }

  return values;
}

export default function CaseTypeForm(): JSX.Element {
  // hooks
  const { control, formState } = useFormContext();

  // methods
  return (
    <>
      <FormInput
        label="Case Type Name"
        name="name"
        control={control}
        rules={{ required: rules.required('Case type name is required') }}
        errors={formState.errors}
      />
      <FormInputTextarea
        label="Case Type Description"
        name="description"
        control={control}
        errors={formState.errors}
      />
    </>
  );
}
