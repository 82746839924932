// Libraries
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

// Endpoints
import {
  CaseDocument,
  CaseDocumentExport,
  useExportCaseDocumentsMutation,
} from '../../../../../shared/store/endpoints';

// Components
import Button from '../../../../../shared/components/Button';

// Hooks
import useStorage from '../../../../../shared/hooks/useStorage';
import axios from 'axios';

interface DocumentExportButtonProps {
  selectedDocuments: CaseDocument[];
}

export default function DocumentExportButton({
  selectedDocuments,
}: DocumentExportButtonProps): JSX.Element {
  // hooks
  const [exportDocUrls, { isLoading }] = useExportCaseDocumentsMutation();
  const { caseId } = useParams() as { caseId: string };
  const { downloadFile, saveBlob } = useStorage();

  // state
  const menuItems = [
    {
      label: 'Selected Documents',
      icon: 'pi pi-fw pi-download',
      command: () => exportDocuments(),
    },
    {
      label: 'Selected with notes',
      icon: 'pi pi-fw pi-download',
      command: () => exportDocumentsWithNotes(),
    },
  ];

  // refs
  const menuRef = useRef<Menu>(null);

  // methods

  // exports all selected documents from S3 as PDFs
  async function exportDocuments() {
    const res = await exportDocUrls({
      caseDocumentExportPayload: {
        caseId,
        documents: selectedDocuments,
        appendNotes: false,
      },
    });
    if ((res as { data: CaseDocumentExport[] }).data) {
      const { data } = res as { data: CaseDocumentExport[] };
      for (const exportObj of data) {
        downloadFile(exportObj.downloadUrl, exportObj.originalPath);
      }
    }
  }

  // exports all selected documents with notes from s3 as PDFs
  async function exportDocumentsWithNotes() {
    // iterating through docs on FE because we don't want to try and merge all the docs together on the BE yet.
    for (const doc of selectedDocuments) {
      // this could be abstracted into a hook, but since we are only using it here, it's fine
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/case-documents/export-with-notes/${caseId}/${doc.id}`,
        {
          params: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          responseType: 'blob',
        },
      );
      if (res.data) {
        const { data } = res as any;
        const filename =
          res.headers['content-disposition'].split('filename=')[1];
        saveBlob(data, filename);
      }
    }
  }

  return (
    <>
      <Button
        className="p-button-outlined"
        label="Export"
        icon="pi pi-chevron-down"
        iconPos="right"
        onClick={(e) => menuRef.current?.toggle(e)}
        loading={isLoading}
        disabled={selectedDocuments.length === 0}
      />
      <Menu ref={menuRef} model={menuItems} popup />
    </>
  );
}
