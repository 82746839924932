import { Dialog } from 'primereact/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from '../../../../../shared/components/Button';
import { CreateCasePeoplePayload } from '../../../../../shared/store/endpoints';
import { useCreateCasePeopleMutation } from '../../../../../shared/store/endpoints/case-people';
import PeopleForm, {
  PeopleFormValues,
  setPeopleFormValues,
} from '../PeopleForm';
import { Toast } from 'primereact/toast';
import useToast from '../../../../../shared/hooks/useToast';

interface CreatePeopleModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export default function CreatePeopleModal({
  showModal,
  setShowModal,
}: CreatePeopleModalProps) {
  // state
  const { caseId } = useParams();
  const [createCasePeople, { isLoading }] = useCreateCasePeopleMutation();
  const { toastRef, showSuccessToast, showErrorToast } = useToast();

  // hooks
  const form = useForm({
    mode: 'onChange',
    defaultValues: setPeopleFormValues(),
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;

  // templates
  const footer = (
    <div>
      <Button
        className="p-button-outlined"
        label="Cancel"
        onClick={() => {
          reset();
          setShowModal(false);
        }}
        disabled={isLoading}
      />
      <Button
        label="Create new person"
        onClick={handleSubmit(submitNewPerson)}
        disabled={!isValid}
        loading={isLoading}
      />
    </div>
  );

  //methods
  function closeModal() {
    reset();
    setShowModal(false);
  }

  async function submitNewPerson(data: PeopleFormValues) {
    const { firstName, lastName, peopleRole, statementObtained, about } = data;
    const createCasePeoplePayload: CreateCasePeoplePayload = {
      caseId: caseId as string,
      firstName,
      lastName,
      peopleRole,
      statementObtained,
      about,
    };

    try {
      await createCasePeople({ createCasePeoplePayload }).unwrap();
      showSuccessToast('Person successfully created');
      closeModal();
    } catch (err) {
      showErrorToast('Error creating person, please try again');
    }
  }

  return (
    <>
      <Dialog
        style={{ width: '50vw' }}
        header="New Person"
        visible={showModal}
        onHide={closeModal}
        footer={footer}
        appendTo="self"
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submitNewPerson)}>
            <PeopleForm />
          </form>
        </FormProvider>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
}
