import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['CasePeople', 'CaseNotes', 'CaseDocumentNotes'],
  endpoints: {
    getCasePeople: {
      providesTags: ['CasePeople'],
    },
    createCasePeople: {
      invalidatesTags: ['CasePeople'],
    },
    deleteCasePeople: {
      invalidatesTags: ['CasePeople', 'CaseNotes', 'CaseDocumentNotes'],
    },
    updateCasePeople: {
      invalidatesTags: ['CasePeople', 'CaseNotes', 'CaseDocumentNotes'],
    },
  },
});

export const {
  useGetCasePeopleQuery,
  useCreateCasePeopleMutation,
  useDeleteCasePeopleMutation,
  useUpdateCasePeopleMutation,
} = enhancedApi;
