import { useMemo } from 'react';
import { useGetIssuesByCaseIdQuery } from '../store/endpoints/case-issues';

const useIssuesOptions = (caseId: string) => {
  const { data: issuesData } = useGetIssuesByCaseIdQuery({
    caseId,
  });

  const issuesOptions = useMemo(() => {
    return (
      issuesData?.map((issue) => ({
        label: `${issue.name}`,
        value: issue.id,
      })) || []
    );
  }, [issuesData]);

  return { issuesOptions };
};

export default useIssuesOptions;
