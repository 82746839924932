import { endpoints } from '../endpoints';

export const enhancedApi = endpoints.enhanceEndpoints({
  addTagTypes: ['Issues'],
  endpoints: {
    createCaseIssue: {
      invalidatesTags: ['Issues'],
    },
    getIssuesByCaseId: {
      providesTags: ['Issues'],
    },
    updateCaseIssue: {
      invalidatesTags: ['Issues'],
    },
    deleteCaseIssues: {
      invalidatesTags: ['Issues'],
    },
  },
});

export const {
  useCreateCaseIssueMutation,
  useGetIssuesByCaseIdQuery,
  useUpdateCaseIssueMutation,
  useDeleteCaseIssuesMutation,
} = enhancedApi;
