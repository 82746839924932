// templates/component/DocumentsTab.tsx

// Libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStorage from '../../../shared/hooks/useStorage';

// Endpoints
import {
  useCheckDocumentEditLogMutation,
  useGetAllCaseDocumentsQuery,
} from '../../../shared/store/endpoints/case-documents';

// Components
import Spacer from '../../../shared/components/Spacer';
import CaseDocumentsTable from './components/CaseDocumentsTable';
import DocumentUploadModal from './components/DocumentUploadModal';
import EditCaseDocumentPropertiesModal from './components/EditCaseDocumentPropertiesModal';

// Types
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import { CaseDocument } from '../../../shared/store/endpoints';
import {
  LazyFilterFilters,
  LazyFilterParams,
} from '../../../shared/types/lazy-filter-params';
import { ReviewStatus } from '../../../shared/types/review-status.enum';
import PdfModal from '../_components/PdfModal';

export default function DocumentsTab(): JSX.Element {
  // hooks
  const { caseId } = useParams();
  const { getReadUrl } = useStorage();
  const [checkEditLog] = useCheckDocumentEditLogMutation();

  // filtering
  // changing these state params will trigger a re-fetch
  const [lazyParams, setLazyParams] = useState<LazyFilterParams>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: undefined,
    sortOrder: undefined,
    filters: {
      name: { value: '', matchMode: 'contains' },
      reviewStatus: { value: null, matchMode: 'contains' },
      documentType: { value: null, matchMode: 'contains' },
      updatedOn: { value: null, matchMode: 'contains' },
    },
  });

  const [skip, setSkip] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // gets and refetches data for the table
  const { data, isLoading } = useGetAllCaseDocumentsQuery(
    {
      caseId: caseId as string,
      skip: lazyParams.first,
      take: lazyParams.rows,
      search: sanitizeSearchFromFilter(lazyParams.filters).search,
      searchBy: sanitizeSearchFromFilter(lazyParams.filters).searchBy,
      orderBy: lazyParams.sortField || 'updatedOn',
      orderDir: lazyParams.sortOrder === 1 ? 'asc' : 'desc',
    },
    { skip: !caseId || skip, pollingInterval: isProcessing ? 15000 : 0 },
  );

  useEffect(() => {
    // cannot write as a useMemo because it depends on data.documents which is a problem because of hoisting.
    let processing = false;
    if (data?.documents) {
      processing = !!data.documents.find(
        (document) => document.reviewStatus === ReviewStatus.processing,
      );
    }
    setIsProcessing(processing);
  }, [data?.documents]);

  // converts filters to something we can use for searching the API
  function sanitizeSearchFromFilter(filters: LazyFilterFilters) {
    // we want to skip search if updated on isn't a full range
    if (
      filters.updatedOn.value?.length &&
      filters.updatedOn.value[1] === null
    ) {
      skip === false && setSkip(true);
      return { search: '', searchBy: '' };
    } else {
      skip === true && setSkip(false);
    }
    const activeFilters = Object.entries(filters).filter(
      ([key, value]: [string, any]) => value?.value?.length > 0,
    );
    const search = [];
    const searchBy = [];
    for (const [key, value] of activeFilters) {
      if (key === 'updatedOn') {
        search.push(value.value[0]);
        searchBy.push('updatedOnStart');
        search.push(value.value[1]);
        searchBy.push('updatedOnEnd');
      } else {
        search.push(value.value);
        searchBy.push(key);
      }
    }
    return {
      search: search.length ? search.join(',') : '',
      searchBy: searchBy.length ? searchBy.join(',') : '',
    };
  }

  // state
  const [currentDoc, setCurrentDoc] = useState<CaseDocument>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  // methods
  async function triggerViewDocument(doc: CaseDocument) {
    if (doc.reviewStatus === ReviewStatus.processing) {
      confirmDialog({
        header: 'Document is still processing',
        message:
          'Looks like this document is still processing.  Please try again when it is finished.',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ok',
        rejectClassName: '!hidden', // hide reject button since it's not needed
      });
      return;
    }
    const userCount = await checkEditLog({ caseDocumentId: doc.id }).unwrap();
    if (userCount > 0) {
      const singular = userCount === 1;
      confirmDialog({
        header: 'Concurrent active users',
        message: `There ${singular ? 'is' : 'are'} ${userCount} other ${
          singular ? 'user' : 'users'
        } already editing this document right now. Are you sure you wish to continue?`,
        accept: () => openDocument(doc),
        acceptLabel: 'Open',
        rejectLabel: 'Cancel',
      });
    } else {
      openDocument(doc);
    }
  }

  async function openDocument(doc: CaseDocument) {
    const url = await getReadUrl(doc.path);
    setCurrentDoc(doc);
    setFileUrl(url);
    setShowViewModal(true);
  }

  function editDocument(doc: CaseDocument) {
    setCurrentDoc(doc);
    setShowEditModal(true);
  }

  return (
    <PageTransitionWrapper>
      <Spacer height="h-4" />
      <CaseDocumentsTable
        data={data}
        isLoading={isLoading}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        viewDocument={triggerViewDocument}
        editDocument={editDocument}
        uploadDocument={() => setShowUploadModal(true)}
      />
      {currentDoc && (
        <EditCaseDocumentPropertiesModal
          caseDocument={currentDoc}
          showModal={showEditModal}
          onHide={() => {
            setShowEditModal(false);
            setCurrentDoc(undefined);
          }}
        />
      )}
      {fileUrl && (
        <PdfModal
          showModal={showViewModal}
          closeModal={() => {
            setShowViewModal(false);
            setFileUrl(undefined);
          }}
          url={fileUrl}
          document={currentDoc as CaseDocument}
          caseId={caseId as string}
        />
      )}
      <DocumentUploadModal
        showModal={showUploadModal}
        closeModal={() => setShowUploadModal(false)}
      />
      <ConfirmDialog />
    </PageTransitionWrapper>
  );
}
