// Libraries

import { Toast } from 'primereact/toast';
import { useParams } from 'react-router';
import Modal from '../../../../../shared/components/Modal';
import useToast from '../../../../../shared/hooks/useToast';
import { CaseDocumentNote } from '../../../../../shared/store/endpoints';
import {
  useAddCaseNoteMutation,
  useUpdateCaseNoteMutation,
} from '../../../../../shared/store/endpoints/case-documents-notes';
import { useGetCaseQuery } from '../../../../../shared/store/endpoints/cases';
import CreateNote, {
  CreateNoteFormValues,
} from '../../../_components/CreateNote';

interface CreateCaseNoteModalProps {
  note?: CaseDocumentNote;
  showModal: boolean;
  onHide: () => void;
  numberOfCaseNotes: number | undefined;
}

export default function CreateCaseNoteModal({
  note,
  showModal,
  onHide,
  numberOfCaseNotes,
}: CreateCaseNoteModalProps): JSX.Element {
  // hooks
  const { caseId } = useParams();
  const { data: caseData } = useGetCaseQuery({
    caseId: caseId as string,
  });
  const [addNote, { isLoading: addLoading }] = useAddCaseNoteMutation();
  const [updateNote, { isLoading: updateLoading }] =
    useUpdateCaseNoteMutation();
  const { showErrorToast, showSuccessToast, toastRef } = useToast();

  // methods
  async function submitForm(data: CreateNoteFormValues) {
    const { name, comment, date, people, issues } = data;
    const payload = {
      name,
      comment,
      date,
      people,
      issues,
      caseId: caseId as string,
    };
    if (note?.id) {
      try {
        await updateNote({
          caseNoteId: note.id,
          createCaseDocumentNotePayload: {
            id: note.id,
            ...payload,
          },
        }).unwrap();
        showSuccessToast('Note updated successfully');
        onHide();
      } catch (err) {
        showErrorToast('Error updating note, please try again');
      }
    } else {
      try {
        await addNote({
          createCaseDocumentNotePayload: payload,
        }).unwrap();
        showSuccessToast('Note added successfully');
        onHide();
      } catch (err) {
        showErrorToast('Error adding note, please try again');
      }
    }
  }

  return (
    <>
      <Modal
        showModal={showModal}
        onHide={onHide}
        headerText="Case note"
        closeOnEscape={false}
      >
        <CreateNote
          note={note}
          placeholderName={caseData?.name}
          numberOfCaseNotes={numberOfCaseNotes}
          submitNote={submitForm}
          handleCancel={onHide}
          loading={addLoading || updateLoading}
          hideParentModal={onHide}
        />
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
}
