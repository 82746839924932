// Libraries
import { FilterMatchMode } from 'primereact/api';
import { useMemo, useState } from 'react';

// Endpoints
import { Profile } from '../../../../shared/store/endpoints';

// Components
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import Button from '../../../../shared/components/Button';
import { tableFilterSearchElement } from '../../../../shared/components/table-templates/searchFilterElement';
import { profileTableRoleFilterElement } from './template-functions/profileTableRoleTemplates';
import {
  profileTableStatusFilterElement,
  profileTableStatusTemplate,
} from './template-functions/profileTableStatusTemplates';

// Types
import {
  ProfileRoles,
  ProfileStatusString,
} from '../../../../shared/types/profile-types';
import { FilterState } from '../../../../shared/types/table-types';
import { translateProfileRoles } from '../../../../shared/utility-fns/profile-fns';
import EditUserModal from '../EditUserModal';

interface ProfileData extends Profile {
  name: string;
  role: ProfileRoles | null;
  status: ProfileStatusString;
  originalData: Profile;
}

interface ProfileTableProps {
  profiles: Profile[] | undefined;
  isLoading: boolean;
}

export default function ProfileTable({
  profiles,
  isLoading,
}: ProfileTableProps): JSX.Element {
  // state
  const [filters] = useState<FilterState>({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [profileToEdit, setProfileToEdit] = useState<Profile | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const tableData = useMemo(() => {
    return (
      profiles?.map((profile) => {
        return {
          ...profile,
          name: `${profile.firstName} ${profile.lastName}`,
          role: profile.permission.role || null,
          status: profile.active ? 'active' : 'inactive',
          originalData: profile,
        };
      }) || []
    );
  }, [profiles]);

  // methods

  function openEditModal(profile: Profile) {
    setProfileToEdit(profile);
    setShowEditModal(true);
  }

  const profilesBodyTemplate = (profile: ProfileData, props: any) => {
    return (
      <div className="flex flex-none items-center gap-x-4" key={profile.id}>
        <img
          alt={profile.firstName}
          src={profile.profilePhotoPath || '/assets/default-avatar.png'}
          className="inline-block rounded-xl shadow-xl"
          width="56"
          height="56"
        />
        <div className="w-4/5 truncate text-lg font-semibold">
          {profile.firstName} {profile.lastName}
        </div>
      </div>
    );
  };

  return (
    <div className="app-datatable">
      <DataTable
        value={tableData}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sortField="name"
        filters={filters as DataTableFilterMeta}
        filterDisplay="menu"
        globalFilterFields={['name', 'email']}
        className="p-datatable-data"
      >
        <Column
          key="name"
          field="name"
          header="Name"
          sortable
          filter
          body={profilesBodyTemplate}
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search name')
          }
          showFilterMatchModes={false}
        />
        <Column
          key="email"
          field="email"
          header="Email"
          sortable
          filter
          filterElement={(options) =>
            tableFilterSearchElement(options, 'Search email')
          }
          showFilterMatchModes={false}
        />
        <Column
          key="role"
          field="role"
          header="Role"
          body={({ role }) => <>{translateProfileRoles(role)}</>}
          sortable
          filter
          filterElement={profileTableRoleFilterElement}
          showFilterMatchModes={false}
        />
        <Column key="caseCount" field="_count.cases" header="Cases" sortable />
        <Column
          key="status"
          field="status"
          header="Status"
          sortable
          // TODO: determine active status from properties on the profile
          body={(profile: ProfileData) =>
            profileTableStatusTemplate(profile.active)
          }
          filter
          filterElement={profileTableStatusFilterElement}
          showFilterMatchModes={false}
        />
        <Column
          key="edit"
          field="edit"
          header=""
          body={(profile: ProfileData) => (
            <Button
              label="Edit"
              className="p-button-outlined"
              onClick={() => openEditModal(profile.originalData)}
            />
          )}
        />
      </DataTable>
      {profileToEdit && showEditModal && (
        <EditUserModal
          profile={profileToEdit}
          showModal={showEditModal}
          setShowModal={setShowEditModal}
        />
      )}
    </div>
  );
}
