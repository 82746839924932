import { Highlight } from '../../../../../../shared/types/pdf-editor-types';

export const DISABLED_TOOLS = [
  'AnnotationCreateArc',
  'AnnotationCreateArc2',
  'AnnotationCreateArc3',
  'AnnotationCreateArc4',
  'AnnotationCreateArcMeasurement',
  'AnnotationCreateArcMeasurement2',
  'AnnotationCreateArcMeasurement3',
  'AnnotationCreateArcMeasurement4',
  'AnnotationCreateAreaMeasurement',
  'AnnotationCreateAreaMeasurement2',
  'AnnotationCreateAreaMeasurement3',
  'AnnotationCreateAreaMeasurement4',
  'AnnotationCreateArrow',
  'AnnotationCreateArrow2',
  'AnnotationCreateArrow3',
  'AnnotationCreateArrow4',
  'AnnotationCreateCallout',
  'AnnotationCreateCallout2',
  'AnnotationCreateCallout3',
  'AnnotationCreateCallout4',
  'AnnotationCreateChangeViewTool',
  'AnnotationCreateChangeViewTool2',
  'AnnotationCreateChangeViewTool3',
  'AnnotationCreateChangeViewTool4',
  'CheckBoxFormFieldCreateTool',
  'AnnotationCreateCloudyRectangularAreaMeasurement',
  'AnnotationCreateCloudyRectangularAreaMeasurement2',
  'AnnotationCreateCloudyRectangularAreaMeasurement3',
  'AnnotationCreateCloudyRectangularAreaMeasurement4',
  'ComboBoxFormFieldCreateTool',
  'ComboBoxFormFieldCreateTool2',
  'ComboBoxFormFieldCreateTool3',
  'ComboBoxFormFieldCreateTool4',
  'ContentEditTool',
  'AnnotationCreateCountMeasurement',
  'AnnotationCreateCountMeasurement2',
  'AnnotationCreateCountMeasurement3',
  'AnnotationCreateCountMeasurement4',
  'CropPage',
  'AnnotationCreateDateFreeText2',
  'AnnotationCreateDateFreeText3',
  'AnnotationCreateDateFreeText4',
  'AnnotationCreateDateFreeText',
  'AnnotationCreateDistanceMeasurement',
  'AnnotationCreateDistanceMeasurement2',
  'AnnotationCreateDistanceMeasurement3',
  'AnnotationCreateDistanceMeasurement4',
  'AnnotationEdit',
  'AnnotationCreateEllipse',
  'AnnotationCreateEllipse2',
  'AnnotationCreateEllipse3',
  'AnnotationCreateEllipse4',
  'AnnotationCreateEllipseMeasurement',
  'AnnotationCreateEllipseMeasurement2',
  'AnnotationCreateEllipseMeasurement3',
  'AnnotationCreateEllipseMeasurement4',
  'AnnotationEraserTool',
  'AnnotationCreateFileAttachment',
  'AnnotationCreateCheckStamp',
  'AnnotationCreateCrossStamp',
  'AnnotationCreateDotStamp',
  'AnnotationCreateFreeHand',
  'AnnotationCreateFreeHand2',
  'AnnotationCreateFreeHand3',
  'AnnotationCreateFreeHand4',
  'AnnotationCreateFreeHandHighlight',
  'AnnotationCreateFreeHandHighlight2',
  'AnnotationCreateFreeHandHighlight3',
  'AnnotationCreateFreeHandHighlight4',
  'AnnotationCreateFreeText',
  'AnnotationCreateFreeText2',
  'AnnotationCreateFreeText3',
  'AnnotationCreateFreeText4',
  'AnnotationCreateLine',
  'AnnotationCreateLine2',
  'AnnotationCreateLine3',
  'AnnotationCreateLine4',
  'ListBoxFormFieldCreateTool',
  'ListBoxFormFieldCreateTool2',
  'ListBoxFormFieldCreateTool3',
  'ListBoxFormFieldCreateTool4',
  'MarqueeZoomTool',
  'Pan',
  'AnnotationCreatePerimeterMeasurement',
  'AnnotationCreatePerimeterMeasurement2',
  'AnnotationCreatePerimeterMeasurement3',
  'AnnotationCreatePerimeterMeasurement4',
  'AnnotationCreatePolygon',
  'AnnotationCreatePolygon2',
  'AnnotationCreatePolygon3',
  'AnnotationCreatePolygon4',
  'AnnotationCreatePolygonCloud',
  'AnnotationCreatePolygonCloud2',
  'AnnotationCreatePolygonCloud3',
  'AnnotationCreatePolygonCloud4',
  'AnnotationCreatePolyline',
  'AnnotationCreatePolyline2',
  'AnnotationCreatePolyline3',
  'AnnotationCreatePolyline4',
  'RadioButtonFormFieldCreateTool',
  'AnnotationCreateRectangle',
  'AnnotationCreateRectangle2',
  'AnnotationCreateRectangle3',
  'AnnotationCreateRectangle4',
  'AnnotationCreateRectangularAreaMeasurement',
  'AnnotationCreateRectangularAreaMeasurement2',
  'AnnotationCreateRectangularAreaMeasurement3',
  'AnnotationCreateRectangularAreaMeasurement4',
  'AnnotationCreateRedaction',
  'AnnotationCreateRedaction2',
  'AnnotationCreateRedaction3',
  'AnnotationCreateRedaction4',
  'AnnotationCreateRubberStamp',
  'AnnotationCreateSignature',
  'SignatureFormFieldCreateTool',
  'SignatureFormFieldCreateTool2',
  'SignatureFormFieldCreateTool3',
  'SignatureFormFieldCreateTool4',
  'AnnotationCreateTextSquiggly',
  'AnnotationCreateTextSquiggly2',
  'AnnotationCreateTextSquiggly3',
  'AnnotationCreateTextSquiggly4',
  'AnnotationCreateStamp',
  'AnnotationCreateSticky',
  'AnnotationCreateSticky2',
  'AnnotationCreateSticky3',
  'AnnotationCreateSticky4',
  'AnnotationCreateTextStrikeout',
  'AnnotationCreateTextStrikeout2',
  'AnnotationCreateTextStrikeout3',
  'AnnotationCreateTextStrikeout4',
  'TextFormFieldCreateTool',
  'TextFormFieldCreateTool2',
  'TextFormFieldCreateTool3',
  'TextFormFieldCreateTool4',
  'TextSelect',
  'AnnotationCreateTextUnderline',
  'AnnotationCreateTextUnderline2',
  'AnnotationCreateTextUnderline3',
  'AnnotationCreateTextUnderline4',
];

export const DISABLED_ELEMENTS = [
  'ribbons',
  'toggleNotesButton',
  'annotationCommentButton',
  'linkButton',
  'annotationStyleEditButton',
  'annotationPopup',
];

export function convertHighlightToAnnotation(
  parsed: Highlight,
  instanceRef: any,
) {
  if (!instanceRef.current) return;
  const { Annotations } = instanceRef.current;
  const highlight = new Annotations.TextHighlightAnnotation();
  highlight.Id = parsed.id;
  highlight.PageNumber = parsed.pageNumber;
  highlight.X = parsed.x;
  highlight.Y = parsed.y;
  highlight.Width = parsed.width;
  highlight.Height = parsed.height;
  highlight.StrokeColor = new Annotations.Color(249, 249, 0, 0.5); // parsed.strokeColor;
  highlight.Quads = parsed.quads;
  highlight.setContents(parsed.contents);
  return highlight;
}

export function convertAnnotationToHighlight(annotation: any): Highlight {
  return {
    id: annotation.Id,
    x: annotation.X,
    y: annotation.Y,
    width: annotation.Width,
    height: annotation.Height,
    pageNumber: annotation.PageNumber,
    strokeColor: annotation.StrokeColor,
    quads: annotation.Quads,
    contents: annotation.getContents(),
  };
}
