// Libraries
import { Paginator } from 'primereact/paginator';

// Components
import Button from '../../../../../../shared/components/Button';
import CreateNote, { CreateNoteFormValues } from '../../../CreateNote';

// Types
import { CaseDocumentNote } from '../../../../../../shared/store/endpoints';
import { PdfEditorNote } from '../../../../../../shared/types/pdf-editor-types';

// styles
import { useMemo } from 'react';
import './NotesSidebar.scss';

interface NotesSidebarProps {
  noteAction: 'add' | 'edit' | undefined;
  currentNote: CaseDocumentNote | PdfEditorNote | undefined;
  notes: CaseDocumentNote[] | undefined;
  count: number;
  page: number;
  setPage: (page: number) => void;
  documentId: string;
  caseId: string;
  handleCancel: () => void;
  handleDelete: () => void;
  selectNote: (
    note?: CaseDocumentNote | PdfEditorNote | undefined,
    action?: 'add' | 'edit' | undefined,
  ) => void;
  handleSubmit: (
    data: CreateNoteFormValues & { editedSelectedText: string },
  ) => void;
  loading: boolean;
  documentName: string;
}

export default function NotesSidebar({
  noteAction,
  currentNote,
  notes,
  page,
  setPage,
  count,
  handleCancel,
  handleDelete,
  selectNote,
  handleSubmit,
  loading,
  documentName,
}: NotesSidebarProps): JSX.Element {
  function addBlankNote() {
    // adding date of previous note as "default" date for new note per client request - DOCELF-191
    if (notes && notes.length > 0) {
      const date = notes[notes.length - 1].date;
      selectNote({ date } as any, 'add');
    } else {
      selectNote(undefined, 'add');
    }
  }

  const placeholderNoteName = useMemo(() => {
    //Replace spaces with underscores and remove file extension
    if (documentName) {
      return documentName.replace(/\.[^/.]+$/, '');
    }
  }, [documentName]);

  return (
    <div className="max-w-1/3 flex h-full max-h-full min-w-[450px] flex-col overflow-auto lg:w-screen">
      {noteAction === 'add' || noteAction === 'edit' ? (
        <>
          <div className="py-2">
            <Button
              className="p-button-rounded p-button-text"
              icon="pi pi-arrow-left"
              label="Back"
              onClick={handleCancel}
            />
          </div>
          <div className="h-full px-4 pb-4 overflow-scroll">
            <CreateNote
              placeholderName={placeholderNoteName}
              numberOfCaseNotes={count}
              note={currentNote}
              handleCancel={handleDelete}
              submitNote={handleSubmit}
              cancelCopy={noteAction === 'add' ? 'Cancel' : 'Delete'}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col h-full gap-4 p-4">
          <span className="text-lg font-bold">Notes</span>
          <div className="flex flex-col overflow-scroll">
            {notes && notes.length > 0 ? (
              notes.map((note, index) => (
                <div
                  className="flex gap-4 p-2 rounded-md cursor-pointer hover:bg-gray-200"
                  key={note.id}
                  onClick={() => selectNote(note, 'edit')}
                >
                  <div className="flex items-center justify-center w-6 h-6 text-sm bg-gray-200 rounded-full">
                    {index + 1}
                  </div>
                  <div className="flex items-center w-full border-b-2 border-gray-200">
                    <span>{note.name}</span>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col gap-8">
                <span className="italic">
                  Highlight some text, or click "Add Note", to add your first
                  note to this document.
                </span>
              </div>
            )}
          </div>
          {notes && count > 10 && (
            <div>
              <Paginator
                rows={10}
                totalRecords={count}
                first={page}
                onPageChange={(e) => setPage(e.first)}
                className="sidebar-paginator !p-2"
              />
            </div>
          )}
          <div className="flex justify-center pt-2 mb-8">
            <Button
              label="Add Note"
              icon="pi pi-plus"
              onClick={() => addBlankNote()}
            />
          </div>
        </div>
      )}
    </div>
  );
}
